import StraddleTable from './StraddleTable'
import PropTypes from 'prop-types'

import './App.css'

class StraddleComboTable extends StraddleTable {
  constructor(props) {
    super(props)

    if (props.pctr) {
      this.dataStore = props.pctr.dataStore
    } else {
      this.dataStore = props.dataStore
    }

    this.height = props.height
    this.type = props.type

    this.messagename = props.messagename

    this.ldMap = {}
    this.dataStore.ldList.forEach(ld => {
      this.ldMap[ld.back] = ld
    })

    this.updateListeners = {}
    this.secParams = {}
    this.valuationIDs = {}
    for (let back in this.ldMap) {
      this.valuationIDs[back] = 'N/A'
      this.secParams[back] = {}
      this.updateConfig(back, this.ldMap[back].getConfig())
      this.updateListeners[back] = data => this.update(data, back)
    }


    this.state = 
      { defaultColDef: { resizable: true
                       , sortable: true
                       , cellStyle: this.right
                       }
      , columnDefs: []
      , filter: (props.filter === 'through-market') ? this.comboThroughMarketFilter : this.comboDefaultRowFilter
      }

    this.state.columnDefs.push({ width: 190
                               , headerName: 'Display'
                               , field: 'display'
                               , cellStyle: this.marketStatusStyle('')
                               })

    this.state.columnDefs.push({ width: 80
                               , headerName: 'Expiry'
                               , field: 'expiration'
                               , filter: 'agNumberColumnFilter'
                               , cellStyle: this.expiryStyle
                               })

    this.state.columnDefs.push({ width: 70
                               , headerName: 'vtexp'
                               , field: 'vtexp'
                               , valueFormatter: this.renderFixed(4)
                               })

    this.state.columnDefs.push({ width: 70
                               , headerName: 'Delta'
                               , field: 'delta'
                               , valueFormatter: this.renderFixed()
                               })

    this.state.columnDefs.push({ width: 70
                               , headerName: 'Gamma'
                               , field: 'gamma'
                               , valueFormatter: this.renderFixed()
                               })

    this.state.columnDefs.push({ width: 70
                               , headerName: 'Vega'
                               , field: 'vega'
                               , valueFormatter: this.renderFixed()
                               })

    this.state.columnDefs.push({ width: 70
                               , headerName: 'Credit'
                               , field: 'credit'
                               , valueGetter: this.getCredit('')
                               , valueFormatter: this.renderFixed(2, true)
                               , cellStyle: this.creditStyle()
                               })
    this.state.columnDefs.push({ width: 70
                               , headerName: 'oBQ'
                               , field: 'ownBidVolume'
                               , valueFormatter: this.strippedNumber(0, true)
                               , cellStyle: {textAlign: 'right'}
                               })

    this.state.columnDefs.push({ width: 70
                               , headerName: 'oBPx'
                               , field: 'ownBidPx'
                               , valueFormatter: this.strippedNumber(2, true, 10)
                               , cellStyle: this.quoteStyle('', 'Bid', false)
                               })

    this.state.columnDefs.push({ width: 90
                               , headerName: 'mBQ'
                               , field: 'marketBidVolume'
                               , valueFormatter: this.strippedNumber(0, true)
                               , cellStyle: {textAlign: 'right'}
                               })

    this.state.columnDefs.push({ width: 90
                               , headerName: 'mBPx'
                               , field: 'marketBidPx'
                               , valueFormatter: this.strippedNumber(2, true, 10)
                               , cellStyle: this.quoteStyle('', 'Bid', true)
                               })

    this.state.columnDefs.push({ width: 90
                               , headerName: 'Theo'
                               , field: 'adjTheo'
                               , valueFormatter: this.renderFixed()
                               })

    this.state.columnDefs.push({ width: 90
                               , headerName: 'mAPx'
                               , field: 'marketAskPx'
                               , valueFormatter: this.strippedNumber(2, true, 10)
                               , cellStyle: this.quoteStyle('', 'Ask', true)
                               })

    this.state.columnDefs.push({ width: 90
                               , headerName: 'mAQ'
                               , field: 'marketAskVolume'
                               , valueFormatter: this.strippedNumber(0, true)
                               , cellStyle: {textAlign: 'left'}
                               })

    this.state.columnDefs.push({ width: 70
                               , headerName: 'oAPx'
                               , field: 'ownAskPx'
                               , valueFormatter: this.strippedNumber(2, true, 10)
                               , cellStyle: this.quoteStyle('', 'Ask', false)
                               })

    this.state.columnDefs.push({ width: 70
                               , headerName: 'oAQ'
                               , field: 'ownAskVolume'
                               , valueFormatter: this.strippedNumber(0, true)
                               , cellStyle: {textAlign: 'left'}
                               })

    this.state.columnDefs.push({ width: 70
                               , headerName: 'B.Err'
                               , field: 'derivedValuationBuyError'
                               , valueFormatter: this.renderFixed(3, true)
                               , cellStyle: this.errStyle('derivedValuationBuyError')
                               })
    this.state.columnDefs.push({ width: 70
                               , headerName: 'S.Err'
                               , field: 'derivedValuationSellError'
                               , valueFormatter: this.renderFixed(3, true)
                               , cellStyle: this.errStyle('derivedValuationSellError')
                               })

    this.state.columnDefs.push({ width: 50
                               , headerName: 'Alpha'
                               , field: 'priceAlpha'
                               , valueFormatter: this.renderFixed(3, true)
                               , cellStyle: this.adjustmentStyle('priceAlpha')
                               })

    this.state.columnDefs.push({ width: 100
                               , headerName: 'mVol'
                               , field: 'marketTradedVolume'
                               , valueFormatter: this.renderFixed(0, true)
                               , cellStyle: {textAlign: 'left'}
                               })

    this.state.columnDefs.push({ width: 80
                               , headerName: 'Enabled'
                               , valueGetter: this.renderEnabled('') 
                               , cellStyle: this.enableStyle('adjustFlatten')
                               })

    this.state.columnDefs.push({ width: 70
                               , headerName: 'APC'
                               , field: 'adjustmentPerContract'
                               , valueFormatter: this.exponentialFormatter('adjustmentPerContract', 1)
                               })

    this.state.columnDefs.push({ width: 80
                               , headerName: 'Adj'
                               , field: 'adjustment'
                               , valueFormatter: this.renderFixed()
                               , cellStyle: this.adjustmentStyle('adjustment')
                               })

    this.state.columnDefs.push({ width: 80
                               , headerName: 'AdjCap %'
                               , field: 'adjFractionOfCap'
                               , cellStyle: this.adjFracOfCapStyle('')
                               , valueFormatter: this.percentFormatter
                               })

    if (this.type === 'futures') {
      this.state.columnDefs.push({ width: 80
                                 , headerName: 'Pos'
                                 , field: 'position'
                                 , cellStyle: this.left
                                 })
      this.state.columnDefs.push({ width: 90
                                 , headerName: 'settle'
                                 , field: 'marketSettlementPx'
                                 , valueFormatter: this.renderFixed(2, true)
                                 , cellStyle: this.left
                                 })
    }

    this.state.columnDefs.push({ width: 1000
                               , headerName: 'Legs'
                               , field: 'legs'
                               , cellStyle: { textAlign: 'left' }
                               })

    this.gengrid()
    this.redraw([ 'marketBidPx', 'marketAskPx', 'derivedValuationBuyError' 
                , 'derivedValuationSellError', 'adjustment'])
  }

  update = (message, back, batchUpdate = true) => {
    if (message !== undefined) {
      this.valuationIDs[back] = message.valuationId
      let valuationID = ''
      for (let bback in this.valuationIDs) {
        if (valuationID !== '') valuationID += ', '
        if (bback && bback !== 'stand-alone') valuationID += bback + ':'
        valuationID += this.valuationIDs[bback]
      }
      this.setState({valuationId: valuationID})
      
      let rowData = message.rows
      
      let secParams = {}
      for (let index in rowData) {
        let key = rowData[index].display
        secParams[key] = rowData[index]
      }

      this.minExpiry = this.maxExpiry = undefined
      for (let index in rowData) {
        rowData[index].back = back
        let expiry = rowData[index].expiration

        if (this.minExpiry === undefined) {
          this.minExpiry = this.maxExpiry = expiry
        } else {
          if (expiry < this.minExpiry) this.minExpiry = expiry
          if (expiry > this.maxExpiry) this.maxExpiry = expiry
        }
      }

      let adds = []
      let updates = []
      if (this.api !== undefined && !this.api.isDestroyed()) {
        for (let id in secParams) {
          if (id in this.secParams[back]) {
            let updateItem = secParams[id]
            updates.push(updateItem)
          } else {
            let newItem = secParams[id]
            adds.push(newItem)
          }
          this.secParams[back][id] = secParams[id]
        }
      }

      for (let back in this.ldMap) {
        this.updateConfig(back, this.ldMap[back].getConfig())
      }

      if (this.api !== undefined && !this.api.isDestroyed()) {
        if (batchUpdate) {
          this.api.applyTransactionAsync({
            add: adds
            , update: updates
          })
        }
        else {
          this.api.applyTransaction({
            add: adds
            , update: updates
          })
        }
      }
    }
  }

  componentWillUnmount() {
    for (let back in this.ldMap) {
      this.ldMap[back].removeListener(this.messagename, this.updateListeners[back])
    }

    super.componentWillUnmount()
  }

  getRowId = (params) => {
    return params.data.display
  }

  onGridReady = (params) => {
    this.api = params.api

    let sortModel = [{colId: 'expiration', sort: 'asc', sortIndex: 0} , {colId: 'display', sort: 'asc', sortIndex: 1}]
    this.api.applyColumnState({state: sortModel})

    for (let back in this.ldMap) {
      this.updateListeners[back](this.ldMap[back][this.messagename])
    }

    for (let back in this.ldMap) {
      this.ldMap[back].addListener(this.messagename, this.updateListeners[back])
    }
  }

  isExternalFilterPresent = () => {
    return true
  }

  quickFilterOnInput = (event) => {
    this.api.setQuickFilter(event.target.value)
  }
}

StraddleComboTable.propTypes = 
  { filter: PropTypes.string
  , dataStore: PropTypes.object
  }

export default StraddleComboTable
