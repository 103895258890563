import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import PropTypes from 'prop-types'

import ServiceStatus from './ServiceStatus'
import {StraddleView} from './StraddleView'
import {ExecutionTable} from './ExecutionTable'
import {TradesTable} from './TradesTable'
import {AlertTable} from './AlertTable'
import {ParametersView} from './ParametersView'
import {ChartView, genSyncer} from './PNLChart'
import {TradesAnalysis} from './TradesAnalysis'
import BrokerMarkets from './BrokerMarkets'
import {JobTable} from './JobTable'

import {getCookies} from './util.jsx'
import 'whatwg-fetch'

import './StraddleTable.css'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import 'react-tabs/style/react-tabs.css'

import './App.css'

class ProductController extends Component {
  constructor(props) {
    super(props)

    this.app = props.app
    this.dataStore = props.dataStore
    this.back = props.back
    this.tradingModes = props.tradingModes

    this.listeners = {}
    this.dataStore.ldList.forEach(ld => {
      if (ld.back === this.back) this.listenerData = ld
    })

    this.listenerData.pctr = this

    this.mode = {}
    this.mode['Options'] = 'full'
    this.mode['Through Market Options'] = 'full'

    this.allContracts = {}
    this.addListener('heartbeat-filter', this.onNewHeartbeatFilter)
    this.listenerData.addListener('config', this.onConfig)

    this.state = {config: this.listenerData.config}
  }

  onNewHeartbeatFilter = data => {
    let filter = data.ip
    this.listenerData.heartbeatFilter = filter
  }

  onConfig = config => {
    this.setState({config: config})
  }

  componentWillUnmount() {
    this.listenerData.pctr = null
  }

  getConfig() {
    return this.state.config
  }

  getListeners = name => {
    if (!this.listeners.hasOwnProperty(name)) {
      this.listeners[name] = []
    }

    return this.listeners[name]
  }

  addListener = (name, listener) => {
    this.getListeners(name).push(listener)
  }

  removeListener = (name, listener) => {
    let notListener = (value) => {
      return value !== listener
    }
    this.listeners[name] = this.getListeners(name).filter(notListener)
  }

  GET = route => {
    return this.listenerData.GET(route)
  }

  POST = (route, obj) => {
    return this.listenerData.POST(route, obj)
  }

  render() {
    let cookies = getCookies()
    let kurtosis = cookies.kurtosis
    let brokerMarkets = cookies.brokerMarkets

    let sync = genSyncer()

    if (kurtosis) {
      return (
        <div className="App">
          <header className="App-header">
            <Tabs className="top-tabs">
              <TabList>
                <Tab>Service Status</Tab>
                <Tab>Straddle</Tab>
                <Tab>Parameters</Tab>
                <Tab>Executions</Tab>
                <Tab>Trades</Tab>
                <Tab>Alerts</Tab>
                <Tab>Charts</Tab>
                {brokerMarkets && <Tab>Broker</Tab>}
                <Tab>Trades Analysis</Tab>
                <Tab>Jobs</Tab>
              </TabList>

              <TabPanel>
                <ServiceStatus pctr={this} throughMarket={true} tradingModes={this.tradingModes}/>
              </TabPanel>

              <TabPanel>
                <StraddleView dataStore={this.dataStore} back={this.back}/>
              </TabPanel>

              <TabPanel>
                <ParametersView dataStore={this.dataStore} back={this.back}/>
              </TabPanel>

              <TabPanel>
                <ExecutionTable pctr={this} messagename='executions'/>
              </TabPanel>

              <TabPanel>
                <TradesTable pctr={this} messagename='strategy-order-descriptions'/>
              </TabPanel>

              <TabPanel>
                <AlertTable pctr={this} messagename='alerts'/>
              </TabPanel>

              <TabPanel>
                <ChartView dataStore={this.dataStore} back={this.back} sync={sync}/>
              </TabPanel>

              { brokerMarkets &&
              <TabPanel>
                <BrokerMarkets pctr={this} messagename='broker-markets'/>
              </TabPanel>
              }

              <TabPanel>
                <TradesAnalysis url={this.getConfig().tradesAnalysisURL}/>
              </TabPanel>

              <TabPanel>
                <JobTable pctr={this} messagename='jobs'/>
              </TabPanel>
            </Tabs>
          </header>
        </div>
      )
    } else {
      return (
        <div className="ProductController">
          <header className="App-header">
            <Tabs>
              <TabList>
                <Tab>Service Status</Tab>
                <Tab>Executions</Tab>
                <Tab>Alerts</Tab>
              </TabList>

              <TabPanel>
                <ServiceStatus pctr={this} throughMarket={false}/>
              </TabPanel>

              <TabPanel>
                <ExecutionTable pctr={this} messagename='executions'/>
              </TabPanel>

              <TabPanel>
                <AlertTable pctr={this} messagename='alerts'/>
              </TabPanel>
            </Tabs>
          </header>
        </div>
      )
    }
  }
}

ProductController.propTypes =
  { app: PropTypes.object
  , back: PropTypes.string
  , listenerData: PropTypes.object
  , dataStore: PropTypes.object
  , tradingModes: PropTypes.array
  }

export default ProductController
