import React from 'react'
import {UpdatedTable} from './UpdatedTable'

import './App.css'

class FitValidationTable extends UpdatedTable {

  constructor(props) {
    super(props)

    let failureStyle = (inverted = false) => {
      return function(params) {
        let failure = (params.value && !inverted) || (!params.value && inverted)
        if (!failure) return { textAlign: 'center', backgroundColor: '' }
        return { textAlign: 'center', backgroundColor:'red' }
      }
    }

    let defaultColWidth = 75
    this.state =
      { defaultColDef: { resizable: true
                       , sortable: false
                       , cellStyle: this.right
                       , width: defaultColWidth
                       }
      , columnDefs: [ { width: 160
                      , headerName: 'Start Time'
                      , field: 'startTime'
                      , valueFormatter: this.timeFormatter('startTime')
                      }
                    , { width: 80
                      , headerName: 'Back'
                      , field: 'back'
                      , valueFormatter: this.backFormatter('back')
                      }
                    , { headerName: 'Duration (msec)'
                      , field: 'duration'
                      , valueFormatter: this.fixedFormatter('duration', 3)
                      , width: 110
                      }
                    , { width: defaultColWidth
                      , headerName: 'Valid'
                      , field: 'valid'
                      , cellStyle: failureStyle('true')
                      }
                    , { headerName: 'Pnl Check Breached'
                      , field: 'crossedPnlBreached'
                      , width: 130
                      , cellStyle: failureStyle()
                      }
                    , { width: defaultColWidth
                      , headerName: 'Option Pnl'
                      , field: 'crossedOptionPnl'
                      , valueFormatter: this.currencyFormatter('crossedOptionPnl')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Future Pnl'
                      , field: 'crossedFuturePnl'
                      , valueFormatter: this.currencyFormatter('crossedFuturePnl')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Roll Pnl'
                      , field: 'crossedRollPnl'
                      , valueFormatter: this.currencyFormatter('crossedRollPnl')
                      }
                    , { headerName: 'Large Base Correction'
                      , field: 'basePriceCorrectionTooLarge'
                      , width: 130
                      , cellStyle: failureStyle()
                      }
                    , { headerName: 'Large Uncertainties'
                      , field: 'uncertaintiesTooLarge'
                      , width: 120
                      , cellStyle: failureStyle()
                      }
                    , { headerName: 'Mkt Illiquid'
                      , field: 'marketIlliquid'
                      , width: 100
                      , cellStyle: failureStyle()
                      }
                    , { headerName: 'Spread Arb'
                      , field: 'spreadArbString'
                      , width: 150
                      }

                    , { headerName: 'Fly Arb'
                      , field: 'flyArbString'
                      , width: 150
                      }

                    ]
      }
    this.gengrid()
  }

  getRowId = (params) => {
    return params.data.back
  }

  onGridReadyDerived = () => {
    this.api.sizeColumnsToFit()
  }

  render() {
    let gridStyle = { width: this.totalWidth() }

    return (
      <div className="guif-div">
        <div className="ag-theme-balham-dark"
             style={gridStyle}>
          {this.grid}
        </div>
      </div>
    )
  }
}

export default FitValidationTable
