import React from 'react'
import PropTypes from 'prop-types'
import { AgGridReact } from 'ag-grid-react'
import moment from 'moment'

import MuteRenderer from '../MuteRenderer'

import ParameterQuickSet from '../ParameterQuickSet'
import ServiceInstrumentation from '../ServiceInstrumentation'
import AuxMarketTable from '../AuxMarketTable'
import FitValidationTable from '../FitValidationTable'
import ScenarioTable from '../ScenarioTable'
import SlippageTable from '../SlippageTable'
import StraddleOptionTable from '../StraddleOptionTable'
import StraddleComboTable from '../StraddleComboTable'
import PnlStatisticsTable from '../PnlStatisticsTable'
import {FilteredJobTable} from '../JobTable'

import TradingMode from '../TradingMode'
import {getCookies} from '../util.jsx'

import '../App.css'

class ServiceStatus extends TradingMode {
  constructor(props) {
    super(props)

    this.dataStore = props.dataStore
    this.ldList = this.dataStore.ldList
    this.ldMap = {}
    this.serviceStatusTableListeners = {}

    let initialRowData = [{'serviceName':'Hello', 'strategy':'World'}]
    this.ldList.forEach(ld => {
      initialRowData[ld.back] = {}
      this.serviceStatusTableListeners[ld.back] = rowData => this.update(rowData, ld.back)
      ld.addListener('service-status-table', this.serviceStatusTableListeners[ld.back])
      ld.addListener('config', this.onBackConfig)
      ld.addListener('heartbeat-filter', this.onNewHeartbeatFilter)
      this.ldMap[ld.back] = ld
    })


    let left   = {textAlign: 'left', borderLeft: '1px solid #555555'}
    let right  = {textAlign: 'right', borderLeft: '1px solid #555555'}
    let center = {textAlign: 'center', borderLeft: '1px solid #555555'}

    let merge = function(style) {
      // setting border-left here is a hack to get the appearance of vertical 
      // grid lines.  If you end up extending functionality here and this
      // is squatting on your intended styling, look at writing custom
      // css for .ag-cell-no-focus and .ag-cell-focus
      let styleDefault = {borderLeft: '1px solid #555555'}
      return Object.assign(style, styleDefault)
    }

    let statusStyle = function(params) {
      if (params.data.status === 'OK')    return merge({backgroundColor: 'green'})
      if (params.data.status === 'WARN')  return merge({backgroundColor: 'orange'})
      if (params.data.status === 'ALERT') return merge({backgroundColor: 'red'})

      if (params.data.lastUpdated === undefined) return merge({backgroundColor: 'red'})
      return merge({backgroundColor:''})
    }

    let runningStyle = function(params) {
      if (params.data.lastUpdated === undefined) return merge({backgroundColor: 'red'})

      let now = new Date()
      let lastUpdated = new Date(params.data.lastUpdated)
      let elapsedTime = now - lastUpdated
      if (elapsedTime > 30 * 1000) {
        return merge({backgroundColor: 'red'})
      } else {
        return merge({backgroundColor: 'green'})
      }
    }

    let lastUpdatedStyle = function(params) {
      if (params.data.lastUpdated === undefined) 
        return merge({textAlign: 'right', backgroundColor: 'red'})
      return merge({textAlign: 'right', backgroundColor:''})
    }

    let timeRenderer = function(params) {
      let time = params.data[params.timeKey]
      if (time === undefined) return time

      // Need to write these out with styling so that the numbers don't bounce around as
      // the text changes (monospaced fonts are ugly, just put some divs/padding in place)
      return moment(time).format('YYYY/MM/DD hh:mm:ss')
    }

    let serviceNameRenderer = function(params) {
      let space = '\u2007'
      if (params.data.groupOpen === undefined) {
        if (params.data.serviceGroup) return space + space + space + params.data.serviceName
        else                          return space + params.data.serviceName
      } else {
        if (params.data.groupOpen === true) {
          let down = '\u25BD'
          return down + space + params.data.serviceName
        } else {
          let side = '\u25B7'
          return side + space + params.data.serviceName
        }
      }
    }

    //
    // Component state
    //
    this.state = 
      { defaultColDef: { resizable: true
                       , sortable:  false
                       }
      , columnDefs: [ { width: 140
                      , headerName: 'Service Name'
                      , field: 'serviceName'
                      , cellStyle: left
                      , cellRenderer: serviceNameRenderer
                      }
                    , { width: 80
                      , headerName: 'Strategy'
                      , field: 'strategy'
                      }
                    , { width: 80
                      , headerName: 'Run'
                      , field: 'running'
                      , cellStyle: runningStyle
                      }
                    , { width: 80
                      , headerName: 'Status'
                      , field: 'status'
                      , cellStyle: statusStyle
                      }
                    , { width: 625
                      , headerName: 'Details'
                      , field: 'details'
                      , cellStyle: left
                      }
                    , { width: 160
                      , headerName: 'Last Updated'
                      , field: 'lastUpdated'
                      , cellStyle: lastUpdatedStyle
                      , cellRenderer: timeRenderer
                      , cellRendererParams: { timeKey: 'lastUpdated' }
                      }
                    , { width: 80
                      , headerName: 'ID'
                      , field: 'serviceID'
                      , cellStyle: right
                      }
                    , { width: 70
                      , headerName: 'Mute'
                      , field: 'mute'
                      , cellStyle: center
                      , cellRenderer: MuteRenderer
                      , cellRendererParams: { muteComponent: this.ldMap
                                            , appField: 'mute'
                                            , idField: 'serviceID'
                                            , multiGui: true
                                            }
                      }
                    , { field: 'sortID'
                      , hide: true
                      , sortable: true
                      }
                    ]
      , filter: this.groupFilter
      , muteButtons: {}
      , soundEnabled: this.soundEnabled()
      , heartbeatsEnabled: this.heartbeatsEnabled()
      , executionAlertsEnabled: this.executionAlertsEnabled()
      , pnlAlertsEnabled: this.pnlAlertsEnabled()
      , pnlEWMADiffThreshold: this.pnlEWMADiffThreshold()
      , haveHeartbeats: this.haveHeartbeats()
      , heartbeatUser: this.heartbeatUser()
      }

    this.cookies = getCookies()
    this.user = this.cookies.user
    this.rowData = initialRowData
    this.rowGroups = {}
    this.gengrid()
    this.redraw()
  }

  componentWillUnmount = () => {
    this.dataStore.ldList.forEach(ld => {
      ld.removeListener('service-status-table', this.serviceStatusTableListeners[ld.back])
      ld.removeListener('config', this.onBackConfig)
      ld.removeListener('heartbeat-filter', this.onNewHeartbeatFilter)
    })
  }

  soundEnabled = () => {
    let enabled = true
    this.dataStore.ldList.forEach(ld => enabled = enabled && ld.soundEnabled)

    return enabled
  }

  heartbeatsEnabled = () => {
    let enabled = true
    this.dataStore.ldList.forEach(ld => enabled = enabled && ld.heartbeatsEnabled)

    return enabled
  }

  executionAlertsEnabled = () => {
    let enabled = true
    this.dataStore.ldList.forEach(ld => enabled = enabled && ld.executionAlertsEnabled)

    return enabled
  }

  pnlAlertsEnabled = () => {
    let enabled = true
    this.dataStore.ldList.forEach(ld => enabled = enabled && ld.pnlAlertsEnabled)

    return enabled
  }

  pnlEWMADiffThreshold = () => {
    let thresh = ''
    this.dataStore.ldList.forEach(ld => {
      if (ld.pnlEWMADiffThreshold && ld.pnlEWMADDiffThreshold !== '') {
        thresh = ld.pnlEWMADiffThreshold
      }
    })
    return thresh
  }

  haveHeartbeats = () => {
    let have = true
    this.dataStore.ldList.forEach(ld => {
      let ldHave = !ld.clientIP ? true : (ld.clientIP && ld.heartbeatFilter === ld.clientIP)
      have = have && ldHave
    })

    return have
  }

  heartbeatUser = () => {
    let huser = undefined
    this.dataStore.ldList.forEach(ld => {
      if (ld.heartbeatUser)
      {
        if (!huser) huser = ld.heartbeatUser
        if (huser && huser !== ld.heartbeatUser) huser = 'multi'
      }
    })

    if (!huser) huser = 'none'
    return huser
  }

  isExternalFilterPresent = () => true

  groupFilter = params => {
    if (params.data.groupRow === true) return true

    let groupKey = params.data.groupKey
    if (groupKey) {
      if (this.rowGroups[groupKey] && this.rowGroups[groupKey].groupOpen === false) return false
    }

    return true
  }
  
  getRowId = (params) => {
    // Extract data field
    const data = params.data
  
    if (data.groupRow) {
      return `${data.groupKey}`
    }

    if (data.serviceName && data.serviceID) {
      return `${data.serviceName}${data.serviceID}`
    }
  
    // Fallback if the expected fields are not present
    console.warn('Missing serviceName or serviceID for row:', data)
    return data.id || Math.random().toString(36).substring(2, 9)
  }

  onGridReady = params => {
    this.api = params.api

    let sortModel = [{colId: 'sortID', sort: 'asc'}]
    this.api.applyColumnState({state: sortModel})
    this.ldList.forEach(ld => {
      this.update(ld['service-status-table'], ld.back, false)
    })
  }

  onBackConfig = () => {
    this.setState({ haveHeartbeats: this.haveHeartbeats()
                  , heartbeatUser: this.heartbeatUser()
                  })
  }

  update = (rowData, back, batchUpdate = true) => {
    if (!rowData)               { console.log('update - No rowdata'); return }
    if (!this.api)              { console.log('update - API is null'); return }

    let adds = []
    let updates = []

    let statusVal = {'OK': 1, 'WARN': 2, 'ALERT': 3}

    rowData.forEach(row => {
      if (rowData.groupRow) {
        console.error('Group row in update', row)
      }

      let key = this.getRowId({data: row})
      row.back = back
      row.sortID = row.serviceGroup + row.serviceID + row.serviceName
      row.groupKey = row.serviceGroup
      if (key in this.rowData[back]) {
        updates.push(row)
      } else {
        adds.push(row)
      }

      this.rowData[back][key] = row

      // Add group row if needed. We do not need to update group rows, becuase they are
      // managed by ag-grid
      const groupKey = row.groupKey
      if (!this.rowGroups[groupKey]) {
        let groupRow = { serviceName: row.serviceGroup
                        , groupKey: groupKey
                        , sortID: groupKey + '0'
                        , groupOpen: false
                        , groupRow: true
                        , subRows: {}
                        }
        this.rowGroups[groupKey] = groupRow
        adds.push(this.rowGroups[groupKey])
        console.log('Added group row for key=' + groupKey, this.rowGroups[groupKey])
      }

      let groupRow = this.rowGroups[groupKey]
      groupRow.subRows[key] = row
      groupRow.lastUpdated = row.lastUpdated
      groupRow.details = undefined
      groupRow.status = row.status

      let detailMap = {}
      for (let key in groupRow.subRows) {
        let row = groupRow.subRows[key]
        if (row.lastUpdated === undefined) {
          groupRow.lastUpdated = undefined
        }
        else if(row.lastUpdated < groupRow.lastUpdated) {
          groupRow.lastUpdated = row.lastUpdated
        }

        if (row.status && groupRow.status) {
          if (statusVal[row.status] > statusVal[groupRow.status]) {
            groupRow.status = row.status
          }
        } else {
          groupRow.status = undefined
        }

        if (row.details) {
          let parts = row.details.split(';')
          parts.forEach(part => {
            if (!detailMap[part]) detailMap[part] = part
          })
        }
      }

      for (let detail in detailMap) {
        if (groupRow.details === undefined) {
          groupRow.details = detail
        }
        else {
          groupRow.details += '; ' + detail
        }
      }
    })

    if (this.api !== undefined && !this.api.isDestroyed()) {
      if (batchUpdate) {
        this.api.applyTransactionAsync({
          add: adds
          , update: updates
        })
      }
      else {
        this.api.applyTransaction({
          add: adds
          , update: updates
        })
      }
    }
  }

  gengrid = () => {
    this.grid = (
      <AgGridReact defaultColDef={this.state.defaultColDef}
                   columnDefs={this.state.columnDefs}
                   suppressRowClickSelection={true}
                   suppressCellSelection={true}
                   getRowId={this.getRowId}
                   onGridReady={this.onGridReady}
                   onCellClicked={this.onCellClicked}
                   isExternalFilterPresent={this.isExternalFilterPresent}
                   asyncTransactionWaitMillis={500}
                   domLayout="autoHeight"
                   doesExternalFilterPass={this.state.filter}>
      </AgGridReact>
    )
  }

  onCellClicked = params => {
    if (params.colDef.headerName === 'Service Name') return this.serviceCellClicked(params)
    return undefined
  }

  serviceCellClicked = params => {
    if (params.data.groupOpen !== undefined) {
      if (params.event.offsetX < 25) {
        let current = params.data.groupOpen === true
        let groupKey = params.data.groupKey

        this.rowGroups[groupKey].groupOpen = !current

        this.api.onFilterChanged()
        this.api.refreshCells({force: true})
      }
    } else {
      // else, not a group
      if (params.data.instrumentation && params.data.serviceName !== 'SP') {
        let serviceID = params.data.serviceID
        let x = params.event.clientX
        let y = params.event.clientY

        let menu = document.createElement('div')
        menu.style.width = '110px'
        menu.style.backgroundColor = 'white'

        // bring up the menu under the current pointer position.  Offset just
        // a bit so mouse is in menu at start -- leaving the menu closes it
        menu.style.position = 'absolute'
        menu.style.left = (x - 10) + 'px'
        menu.style.top = (y - 10) + 'px'

        // Make the menu disappear if user leaves
        menu.addEventListener('mouseleave', function() {
          menu.parentNode.removeChild(menu)
        })

        let label = document.createElement('div')
        label.innerHTML = 'ServiceID ' + serviceID
        label.style.fontSize = '12px'
        label.style.textAlign = 'center'
        menu.appendChild(label)

        let sendControlService = action => {
          return () => {
            this.ldMap[params.data.back].POST('/control/service', {serviceID: serviceID, action: action})

            menu.parentNode.removeChild(menu)
          }
        }

        {
          let button = document.createElement('button')
          button.style.width = '100%'
          button.innerHTML = 'Start'
          button.onclick = sendControlService('start')
          menu.appendChild(button)
        }

        {
          let button = document.createElement('button')
          button.style.width = '100%'
          button.innerHTML = 'Stop'
          button.onclick = sendControlService('stop')
          menu.appendChild(button)
        }

        {
          let button = document.createElement('button')
          button.style.width = '100%'
          button.innerHTML = 'Pause'
          button.onclick = sendControlService('pause')
          menu.appendChild(button)
        }

        {
          let button = document.createElement('button')
          button.style.width = '100%'
          button.innerHTML = 'Resume'
          button.onclick = sendControlService('resume')
          menu.appendChild(button)
        }

        document.body.appendChild(menu)
      }
    }
  }

  onEnableSound = params => {
    this.dataStore.ldList.forEach(ld => {
      ld.soundEnabled = params.target.checked
    })
    this.dataStore.appWide.soundEnabled = params.target.checked
    this.setState({'soundEnabled': this.soundEnabled()})
  }

  onEnableHeartbeats = params => {
    this.dataStore.ldList.forEach(ld => {
      ld.heartbeatsEnabled = params.target.checked
    })
    this.setState({'heartbeatsEnabled': this.heartbeatsEnabled()})
  }

  onEnableExecutionAlerts = (params) => {
    this.dataStore.ldList.forEach(ld => {
      ld.executionAlertsEnabled = params.target.checked
    })
    this.setState({'executionAlertsEnabled' : this.executionAlertsEnabled()})
  }

  onEnablePnlAlerts = (params) => {
    this.dataStore.ldList.forEach(ld => {
      ld.pnlAlertsEnabled = params.target.checked
    })
    this.setState({'pnlAlertsEnabled' : this.pnlAlertsEnabled()})
  }

  onPnlEWMADiffThreshold = (params) => {
    this.dataStore.ldList.forEach(ld => {
      ld.pnlEWMADiffThreshold = params.target.value
    })
    this.setState({'pnlEWMADiffThreshold' : this.pnlEWMADiffThreshold()})
  }

  onTakeHeartbeatFilter = () => {
    this.dataStore.ldList.forEach(ld => {
      if (ld.heartbeatUser === this.user) {
        ld.POST('/control/heartbeatFilter', {clientIP: '', heartbeatUser: ''})
      } else {
        ld.POST('/control/heartbeatFilter', {clientIP: ld.clientIP, heartbeatUser: this.user})
      }
    })
  }

  onNewHeartbeatFilter = () => {
    this.setState({ haveHeartbeats: this.haveHeartbeats()
                  , heartbeatUser: this.heartbeatUser()
                  })
  }

  alertFilterOnInput = event => {
    this.dataStore.appWide.alertFilter = event.target.value
    this.dataStore.ldList.forEach(ld => {
      ld.alertFilter = this.dataStore.appWide.alertFilter
    })
  }

  onStartAll = () => {
    this.commandAll('start')
  }

  onStopAll = () => {
    this.commandAll('stop')
  }

  commandAll = action => {
    for (let back in this.rowData) {
      let ld = this.rowData[back]
      for (let key in ld) {
        let row = ld[key]
        if (row.instrumentation && row.serviceName !== 'SP') {
          this.ldMap[back].POST('/control/service', {serviceID: row.serviceID, action: action})
        }
      }
    }
  }

  redraw = () => {
    // 'service-status-table' events will cause the grid to be redrawn.  But *also* redraw
    // every second -- this is so we can keep the columns up-to-date in the event the
    // backend goes down.  This also keep backgrounds up to date after bulk updates
    // to the ag-grid.
    if (this.api !== undefined && !this.api.isDestroyed()) {
      this.api.refreshCells({force:true, columns: ['running', 'status', 'lastUpdated']})
    }
    setTimeout(this.redraw, 1000)
  }

  render() {
    let totalWidth = 2 // pixel on each side
    this.state.columnDefs.forEach(col => {if (col.width) totalWidth += col.width})
    if (totalWidth > window.innerWidth - 50) totalWidth = window.innerWidth - 50

    let gridStyle = { width: totalWidth + 'px' }
    let hbfBackground = this.state.haveHeartbeats ? 'green' : 'red'

    return (
      <div className='service-status' style={{marginBottom: '20px', width: '100%'}}>
        <div className="ag-theme-balham-dark"
             style={gridStyle}>
          {this.grid}
        </div>

        <div style={{ fontSize: '14px', textAlign: 'left'
                    , minWidth: '900px', marginTop: '10px', display: 'inline-block'}}>
          <div style={{display: 'inline-block'}}>
            Send Heartbeats <input type="checkbox"
                                   defaultChecked={this.state.heartbeatsEnabled}
                                   onChange={this.onEnableHeartbeats}/>
          </div>

          <button onClick={this.onTakeHeartbeatFilter}
                  style={{marginLeft: '5px', background: hbfBackground, width: '80px'}}>
            {this.state.heartbeatUser}
          </button>

          <div style={{display: 'inline-block', width: '30px'}}>
          </div>

          <div style={{display: 'inline-block'}}>
            Enable sound <input type="checkbox"
                                defaultChecked={this.state.soundEnabled}
                                onChange={this.onEnableSound}/>
          </div>

          <div style={{display: 'inline-block', width: '30px'}}>
          </div>

          <button onClick={this.onStartAll}>Start All</button>

          <div style={{display: 'inline-block', width: '10px'}}>
          </div>

          <button onClick={this.onStopAll}>Stop All</button>

          <input className="guif-input" type="text" size="40" placeholder="ignore alert filter"
                 style={{marginLeft: '30px'}}
                 defaultValue={this.dataStore.appWide.alertFilter}
                 onChange={this.alertFilterOnInput}>
          </input>
        </div>

        <div style={{ fontSize: '14px', textAlign: 'left'
                    , minWidth: '900px', marginTop: '10px', display: 'inline-block'}}>
          <div style={{display: 'inline-block'}}>
            Execution Alerts <input type="checkbox"
                                    defaultChecked={this.state.executionAlertsEnabled}
                                    onChange={this.onEnableExecutionAlerts}/>
          </div>

          <div style={{display: 'inline-block', width: '20px'}}>
          </div>

          <div style={{display: 'inline-block'}}>
            PnL Alerts <input type="checkbox"
                              defaultChecked={this.state.pnlAlertsEnabled}
                              onChange={this.onEnablePnlAlerts}/>
          </div>

          <div style={{display: 'inline-block', width: '20px'}}>
          </div>

          <div style={{display: 'inline-block'}}>
            EWMA diff threshold <input type="text"
                                       size="5"
                                       onChange={this.onPnlEWMADiffThreshold}
                                       defaultValue={this.state.pnlEWMADiffThreshold}/>
          </div>
        </div>

        {this.tradingModes &&
        <div style={{padding: '10px', marginTop: '15px', border: '1px solid #555555'}}>
          <div style={{fontSize: '14px', marginBottom: '10px'}}>Trading Modes</div>
          {this.tradingModes.map(mode => this.renderTradingMode(mode))}
        </div>
        }

        <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
          <ParameterQuickSet dataStore={this.dataStore} messagename='parameters'/>
        </div>

        <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
          <ServiceInstrumentation dataStore={this.dataStore} messagename='service-status-table'/>
        </div>

        { this.cookies.auxMarketTable &&
        <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
          <AuxMarketTable dataStore={this.dataStore} messagename='aux-market-info'/>
        </div>
        }

        { this.cookies.pnlStatisticsTable &&
        <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
          <PnlStatisticsTable dataStore={this.dataStore} messagename='pnl-statistics'/>
        </div>
        }

        { this.cookies.fitValidationTable &&
        <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
          <FitValidationTable dataStore={this.dataStore} messagename='fit-validation'/>
        </div>
        }

        { this.cookies.jobsTab &&
        <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
          <FilteredJobTable dataStore={this.dataStore} messagename='jobs' gridOnly={true}
                            autoHeight={true} extraFilters={[{col: 'status', val: 'FAILED|LATE'}]}/>
        </div>
        }

        { this.cookies.riskTab &&
        <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
          <ScenarioTable dataStore={this.dataStore} messagename='scenarios'/>
        </div>
        }

        { this.cookies.slippageTable &&
        <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
          <SlippageTable dataStore={this.dataStore} messagename='slippage'/>
        </div>
        }

        { this.cookies.straddleTab &&
        <div className="straddle-view">
          <StraddleOptionTable dataStore={this.dataStore}
                               back=''
                               height='300px'
                               title='Through Market Options'
                               filter='through-market'/>
        </div>
        }

        { this.cookies.straddleTab &&
        <div className="straddle-view">
          <StraddleComboTable dataStore={this.dataStore}
                              back=''
                              type='combos'
                              height='300px'
                              messagename='straddle-combos-sec-params'
                              title='Through Market Combos'
                              filter='through-market'/>
        </div>
        }
      </div>
    )
  }
}

ServiceStatus.propTypes = 
  { dataStore: PropTypes.object
  }

export default ServiceStatus
