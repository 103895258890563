import React from 'react'
import {UpdatedTable, FilteredUpdatedTable} from './UpdatedTable'

import './App.css'

class ExecutionTable extends UpdatedTable {
  constructor(props) {
    super(props)
    
    this.state = 
      { defaultColDef: { resizable: true
                       , sortable: true
                       , cellStyle: this.right
                       }
      , columnDefs: [ { width: 50
                      , headerName: 'Back'
                      , field: 'back'
                      , valueFormatter: this.backFormatter('back')
                      }
                    , { width: 120
                      , headerName: 'Service Name'
                      , field: 'serviceName'
                      , cellStyle: this.left
                      }
                    , { width: 80
                      , headerName: 'ID'
                      , field: 'serviceID'
                      }
                    , { width: 160
                      , headerName: 'Time'
                      , field: 'time'
                      , valueFormatter: this.timeFormatter('time')
                      }
                    , { width:80
                      , headerName: 'Account'
                      , field: 'account'
                      }
                    , { width:160
                      , headerName: 'Symbol'
                      , field: 'symbol'
                      }
                    , { width: 80
                      , headerName: 'Seqnum'
                      , field: 'seqnum'
                      }
                    , { width: 80
                      , headerName: 'Price'
                      , field: 'price'
                      , valueFormatter: this.strippedNumber('price')
                      }
                    , { width: 80
                      , headerName: 'Volume'
                      , field: 'volume'
                      }
                    , { width: 160
                      , headerName: 'ExecRef'
                      , field: 'execRef'
                      }
                    , { width: 80
                      , headerName: 'IsDropCopy'
                      , field: 'isDropCopy'
                      }
                    ]
      }

    this.gengridFixedHeight()
  }

  getRowId = (params) => {
    return params.data.serviceID + ' ' 
      + params.data.account + ' ' 
      + params.data.symbol + ' ' 
      + params.data.execRef
  }

  onGridReadyDerived = () => {
    let sortModel = [{colId: 'time', sort: 'desc'}]
    this.api.applyColumnState({state: sortModel})
  }

  render() {
    if (this.api) this.api.onFilterChanged()
    let gridStyle = { width: this.totalWidth(true)
                    , height: 'calc(100vh - 100px)'
                    }

    return (
      <div className="guif-div">
        <div className="ag-theme-balham-dark"
             style={gridStyle}>
          {this.grid}
        </div>
      </div>
    )
  }
}

class FilteredExecutionTable extends FilteredUpdatedTable {
  renderUpdatedTable(dataStore, backFilter, messagename) {
    return (
      <ExecutionTable dataStore={dataStore}
                      backFilter={backFilter}
                      messagename={messagename}/>
    )
  }
}

export {ExecutionTable, FilteredExecutionTable}
