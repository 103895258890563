import React from 'react'
import {UpdatedTable, FilteredUpdatedTable} from './UpdatedTable'
import chroma from 'chroma-js'

import './App.css'

class TradesTable extends UpdatedTable {
  constructor(props) {
    super(props)

    let scaleRedToGreen = chroma.scale(['red', '#282c34', 'green']).domain([-1.0, 0.0, 1.0]).mode('lrgb')

    let pnlStyle = function(key) {
      return function(params) {
        if (params.data[key] === undefined) return {textAlign: 'right', 'backgroundColor': ''}

        const pnl = params.data[key]
        const scaledPnl = pnl / 500.0
        if (scaledPnl < 0.1) return {textAlign: 'right', 'backgroundColor': ''}
        return {textAlign: 'right', backgroundColor: scaleRedToGreen(scaledPnl)}
      }
    }

    let enabledStyle = function(key) {
      return function(params) {
        if (params.data[key] === undefined) return {textAlign: 'center', 'backgroundColor': ''}

        const isOwnEnabled = params.data[key]
        if (isOwnEnabled) return {textAlign: 'center', 'backgroundColor': ''}
        return {textAlign: 'center', 'backgroundColor': '#4a4a4a'}
      }
    }

    let sideStyle = function(key) {
      return function(params) {
        let sideSign = 0
        const side = params.data[key]
        if (side !== undefined) {
          switch (side) {
          case 1:
            sideSign = 1
            break
          case 2:
            sideSign = -1
            break
          default:
            sideSign = 0
            break
          }
        }

        const sideScale = (params.data.category === 0) ? 0.2 * sideSign : 1.0 * sideSign
        if (sideScale === 0) return {textAlign: 'center', 'backgroundColor': ''}
        return {textAlign: 'center', backgroundColor: scaleRedToGreen(sideScale)}
      }
    }

    let categoryGetter = function(params) {
      if (params.data.category !== undefined) {
        return (params.data.category === 0) ? '' : 'X'
      }
      return ''
    }

    let tradeTypeGetter = function(params) {
      let tradeTypeDesc = ''
      let tradeType = params.data.tradeType
      if (tradeType !== undefined) {
        switch (tradeType) {
        case 0:
          tradeTypeDesc = 'Q'
          break
        case 1:
          tradeTypeDesc = 'H'
          break
        case 2:
          tradeTypeDesc = 'FQ'
          break
        case 3:
          tradeTypeDesc = 'FH'
          break
        default:
          tradeTypeDesc = ''
          break
        }
      }
      return tradeTypeDesc
    }

    let tradeSideGetter = function(params) {
      let tradeSideDesc = ''
      let side = params.data.side
      if (side !== undefined) {
        switch (side) {
        case 1:
          tradeSideDesc = 'B'
          break
        case 2:
          tradeSideDesc = 'S'
          break
        default:
          tradeSideDesc = ''
          break
        }
      }

      return tradeSideDesc
    }

    let volumeGetter = function(params) {
      let volume = params.data.volume

      let tradeSideDesc = ''
      let tradeSide = params.data.side
      if (tradeSide !== undefined) {
        switch (tradeSide) {
        case 1:
          tradeSideDesc = '+'
          break
        case 2:
          tradeSideDesc = '-'
          break
        default:
          tradeSideDesc = ''
          break
        }
      }

      return tradeSideDesc + volume
    }

    let marketVolumeGetter = function(params) {
      let marketVolume = ' '
      let side = params.data.side
      let type = params.data.tradeType
      let price = params.data.price

      // If buy quote or sell hit, use bid. Counter for ask.
      if (((side === 1 && type === 0) || (side === 2 && type === 1)) && params.data.marketBidPrice === price) {
        marketVolume = params.data.marketBidVolume
      }
      else if (((side === 2 && type === 0) || (side === 1 && type === 1)) && params.data.marketAskPrice === price) {
        marketVolume = params.data.marketAskVolume
      }

      return marketVolume
    }

    let adjustmentGetter = function(params) {
      let unadjustedTheo = params.data.unadjustedTheo
      let adjustedTheo = params.data.adjustedTheo

      return adjustedTheo - unadjustedTheo
    }

    let volCreditGetter = function(params) {
      let credit = params.data.credit
      let vega = params.data.vega
      let kVolFactor = 0.01

      if (vega === 0) return ''

      return credit / Math.abs(vega) / kVolFactor
    }

    let cor1Getter = function(params) {
      let side = params.data.side

      let sideSign = 0
      if (side === 1) sideSign = 1
      if (side === 2) sideSign = -1

      if (sideSign === 0) return ''
      return params.data.cor1
    }

    let tradedDeltaGetter = function(params) {
      let side = params.data.side
      let volume = params.data.volume
      let delta = params.data.delta

      let sideSign = 0
      if (side === 1) sideSign = 1
      if (side === 2) sideSign = -1

      if (sideSign === 0) return ''
      return sideSign * volume * delta
    }

    let tradedVegaGetter = function(params) {
      let side = params.data.side
      let volume = params.data.volume
      let vega = params.data.vega
      let contractSize = params.data.contractSize
      let kVolFactor = 0.01

      let sideSign = 0
      if (side === 1) sideSign = 1
      if (side === 2) sideSign = -1

      if (sideSign === 0) return ''
      return sideSign * volume * vega * contractSize * kVolFactor
    }

    this.state = 
      { defaultColDef: { resizable: true
                       , sortable: true
                       , cellStyle: this.right
                       , width: 80
                       }
      , columnDefs: [ { width: 50
                      , headerName: 'Back'
                      , field: 'back'
                      , valueFormatter: this.backFormatter('back')
                      }
                    , { width: 160
                      , headerName: 'Time'
                      , field: 'time'
                      , valueFormatter: this.timeFormatter('time')
                      }
                    , { width: 50
                      , headerName: 'Own'
                      , valueGetter: categoryGetter
                      }
                    , { width: 160
                      , headerName: 'Symbol'
                      , field: 'symbol'
                      , cellStyle: this.left
                      }
                    , { width: 45
                      , headerName: 'Type'
                      , valueGetter: tradeTypeGetter
                      , cellStyle: enabledStyle('isOwnEnabled')
                      }
                    , { width: 45
                      , headerName: 'Side'
                      , valueGetter: tradeSideGetter
                      , cellStyle: sideStyle('side')
                      }
                    , { width: 80
                      , headerName: 'Volume'
                      , valueGetter: volumeGetter
                      }
                    , { width: 85
                      , headerName: 'Price'
                      , field: 'price'
                      , valueFormatter: this.strippedNumber('price')
                      }
                    , { width: 60
                      , headerName: 'Pnl'
                      , field: 'pnl'
                      , valueFormatter: this.currencyFormatter('pnl')
                      , cellStyle: pnlStyle('pnl')
                      }
                    , { width: 70
                      , headerName: 'Credit'
                      , field: 'credit'
                      , valueFormatter: this.strippedNumber('credit', true, 3, 3)
                      }
                    , { width: 70
                      , headerName: 'Adjustment'
                      , valueGetter: adjustmentGetter
                      , valueFormatter: this.signedFixedFormatter(adjustmentGetter, 3)
                      }
                    , { width: 75
                      , headerName: 'BaseTheo'
                      , field: 'driverTheo'
                      , valueFormatter: this.fixedFormatter('driverTheo', 2)
                      }
                    , { width: 70
                      , headerName: 'mVolume'
                      , valueGetter: marketVolumeGetter
                      }
                    , { width: 60
                      , headerName: 'Cor1'
                      , valueGetter: cor1Getter
                      , valueFormatter: this.signedFixedFormatter(cor1Getter, 2, true)
                      }
                    , { width: 70
                      , headerName: 'VolCredit'
                      , valueGetter: volCreditGetter
                      , valueFormatter: this.signedFixedFormatter(volCreditGetter, 2, true)
                      }
                    , { width: 85
                      , headerName: 'TradedDelta'
                      , valueGetter: tradedDeltaGetter
                      , valueFormatter: this.signedFixedFormatter(tradedDeltaGetter, 2, true)
                      }
                    , { width: 85
                      , headerName: 'TradedVega'
                      , valueGetter: tradedVegaGetter
                      , valueFormatter: this.signedFixedFormatter(tradedVegaGetter, 0, true)
                      }
                    , { headerName: 'ReceiveTime'
                      , field: 'receiveTime'
                      , hide: true
                      }
                    , { headerName: 'TradeId'
                      , field: 'tradeId'
                      , hide: true
                      }
                    ]
      }

    this.gengridFixedHeight()
  }

  getRowId = (params) => {
    return params.data.time + ' ' 
      + params.data.tradeId + ' ' 
      + params.data.receiveTime + ' ' 
      + params.data.symbol + ' ' 
      + params.data.side + ' ' 
      + params.data.price
  }

  // enum class TradeCategory   { MARKET = 0, FILLED_HIT, FILLED_QUOTE, MISSED_HIT };
  // enum class TradeSide       {  UNKNOWN,   BUY ,  SELL,   NONE,   NUM_TRADESIDE };
  // enum class LiquidationSide {  NONE,   BUY ,  SELL,   BOTH  };
  getRowStyle = (params) => {
    if (params.data.category !== 0) {
      if (!params.data.isOption) {
        if (params.data.side === 1) {
          return {backgroundColor: '#2C5786'}
        } else {
          return {backgroundColor: '#865a2c'}
        }
      } else if (params.data.side === 1) {
        return {backgroundColor: '#445e45'}
      } else {
        return {backgroundColor: '#5e4444'}
      }
    } else if (params.data.isLegFill) {
      return {backgroundColor: '#6a6b6b'}
    } else if (params.data.liquidationSide === 1 || params.data.liquidationSide === 2 || params.data.liquidationSide === 3) {
      return {backgroundColor: '#ab3f79'}
    } else {
      return {backgroundColor: '#262c2e'}
    }
  }

  onGridReadyDerived = () => {
    let sortModel = [{colId: 'tradeId', sort: 'desc'}]
    this.api.applyColumnState({state: sortModel})
  }

  render() {
    let gridStyle = { width: this.totalWidth(true)
                    , height: 'calc(100vh - 100px)'
                    }
    if (this.api) this.api.onFilterChanged()

    return (
      <div className="guif-div">
        <div className="ag-theme-balham-dark"
             style={gridStyle}>
          {this.grid}
        </div>
      </div>
    )
  }
}

class FilteredTradesTable extends FilteredUpdatedTable {
  renderUpdatedTable(dataStore, backFilter, messagename) {
    return (
      <TradesTable dataStore={dataStore}
                   backFilter={backFilter}
                   messagename={messagename}/>
    )
  }
}

export {TradesTable, FilteredTradesTable}
