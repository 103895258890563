import React from 'react'
import {UpdatedTable} from './UpdatedTable'

import './App.css'

class PnlStatisticsTable extends UpdatedTable {
  constructor(props) {
    super(props)

    let defaultColWidth = 70
    this.state = 
      { defaultColDef: { resizable: true
                       , sortable: true
                       , width: defaultColWidth
                       , cellStyle: this.right
                       }
      , columnDefs: [ { width: 50
                      , headerName: 'Back'
                      , field: 'back'
                      , valueFormatter: this.backFormatter('back')
                      }
                    , { width:80
                      , headerName: 'EWMA Pnl'
                      , field: 'mEwmapnl'
                      , valueFormatter: this.currencyFormatter('mEwmapnl')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Mom'
                      , field: 'mMom'
                      , valueFormatter: this.percentFormatter('mMom')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Lockin'
                      , field: 'mLockin'
                      , valueFormatter: this.percentFormatter('mLockin')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Pnl'
                      , field: 'mTotalpnl'
                      , valueFormatter: this.currencyFormatter('mTotalpnl')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Pnl.o'
                      , field: 'mOvernightpnl'
                      , valueFormatter: this.currencyFormatter('mOvernightpnl')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Pnl.i'
                      , field: 'mInstantpnl'
                      , valueFormatter: this.currencyFormatter('mInstantpnl')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Pnl.i %'
                      , field: 'mInstantprofitshare'
                      , valueFormatter: this.percentFormatter('mInstantprofitshare')
                      }
                    , { width:90
                      , headerName: 'M Pnl.i'
                      , field: 'mMarketinstantpnl'
                      , valueFormatter: this.currencyFormatter('mMarketinstantpnl')
                      }
                    , { width: defaultColWidth
                      , headerName: 'TPnl.i'
                      , field: 'mTrailingprofit'
                      , valueFormatter: this.currencyFormatter('mTrailingprofit')
                      }
                    , { width: defaultColWidth
                      , headerName: 'TPnl.i %'
                      , field: 'mTrailinginstantprofitshare'
                      , valueFormatter: this.percentFormatter('mTrailinginstantprofitshare')
                      }
                    , { width:90
                      , headerName: 'M TPnl.i'
                      , field: 'mMarkettrailingprofit'
                      , valueFormatter: this.currencyFormatter('mMarkettrailingprofit')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Gross'
                      , field: 'mGrosspnl'
                      , valueFormatter: this.currencyFormatter('mGrosspnl')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Fees'
                      , field: 'mIntradayfees'
                      , valueFormatter: this.currencyFormatter('mIntradayfees')
                      }
                    , { width: 140
                      , headerName: 'Time'
                      , field: 'time'
                      , valueFormatter: this.timeFormatter('time')
                      }
                    ]
      }

    this.gengrid()
  }

  getRowId = (params) => {
    return params.data.back
  }

  render() {
    let gridStyle = { width: this.totalWidth() }

    return (
      <div className="guif-div">
        <div className="ag-theme-balham-dark"
             style={gridStyle}>
          {this.grid}
        </div>
      </div>
    )
  }
}

export default PnlStatisticsTable
