import moment from 'moment'

import {select, pointer} from 'd3-selection'
import {scaleLinear} from 'd3-scale'
import {line} from 'd3-shape'
import {extent, bisector} from 'd3-array'
import {axisLeft, axisRight, axisTop, axisBottom} from 'd3-axis'
import {timeFormat} from 'd3-time-format'
import {zoom} from 'd3-zoom'
import {drag} from 'd3-drag'

let drawTooltip = function(g, value) {
  // drawTooltip lifted wholesale from https://observablehq.com/@d3/line-chart-with-tooltip
  // where it's called 'callout'.  Just reformated a bit to make our linter happy
  if (!value) return g.style('display', 'none')

  g
  .style('display', null)
  .style('pointer-events', 'none')
  .style('font', '10px sans-serif')

  const path = g.selectAll('path')
  .data([null])
  .join('path')
  .attr('fill', 'white')
  .attr('stroke', 'black')

  const text = g.selectAll('text')
  .data([null])
  .join('text')
  .call(text => text
  .selectAll('tspan')
  .data((value + '').split(/\n/))
  .join('tspan')
  .attr('x', 0)
  .attr('y', (d, i) => `${i * 1.1}em`)
  .style('font-weight', 'bold')
  .text(d => d))

  const bbox = text.node().getBBox()
  const y = bbox.y
  const w = bbox.width
  const h = bbox.height

  text.attr('transform', `translate(${-w / 2},${15 - y})`)
  path.attr('d', `M${-w / 2 - 10},5H-5l5,-5l5,5H${w / 2 + 10}v${h + 20}h-${w + 20}z`)

  return null
}

let makeMultiscale = function(ts, getx) {
  let scales = []
  const maxCount = 1000
  const maxScale = ts.length < maxCount ? 1 : Math.floor(ts.length / maxCount)

  for (let scale = 1; scale <= maxScale; scale *= 3) {
    let tss = []
    let currentTs = []
    let currentCount = 0

    for (let i = 0; i < ts.length; i += scale) {
      currentTs.push(ts[i])
      currentCount += 1
      if (currentCount >= maxCount) {
        tss.push(currentTs)
        currentTs = [ts[i]]
        currentCount = 0
      }
    }

    if (currentCount > 1) tss.push(currentTs)
    scales.push(tss)
  }

  // put the lowest resolution first, so we can walk through differnt
  // scales and take the lowest resolution with enough points
  scales.reverse()

  let overlap = function(x0, x1, xx0, xx1) {
    let os = Math.max(x0, xx0)
    let oe = Math.min(x1, xx1)
    if (oe < os) return 0
    return (oe - os) / (xx1 - xx0)
  }

  let getSeries = function(xmin, xmax, minpoints = 1001) {
    // Give a set of traces that cover xmin-xmax with at least minpoints points (or
    // just the whole timeseries, if it doesn't have that many).  This isn't exact,
    // you're going to get *about* minpoints, sometimes more, sometimes less (scales
    // jump by 3x, so if one scale is minpoints-1, the next will be 3x minpoints.  And
    // some of the counted points will be in ts segments not fully contained in
    // (xmin, xmax).  So ... about.

    let os = []

    for (let i in scales) {
      os = []
      let count = 0

      for (let j in scales[i]) {
        let series = scales[i][j]
        let ol = overlap(xmin, xmax, getx(series[0]), getx(series[series.length - 1]))
        if (ol > 0) {
          os.push(series)
          count += series.length * ol
        }
      }

      if (count > minpoints) return os

    }

    return os
  }

  return { getOriginalSeries: function() {return ts}
         , getSeries: getSeries
         }
}

class TimeSeriesPlot {
  constructor(myRef, sync) {
    this.myRef = myRef
    this.sync = sync
    this.dy = 0
    this.margin = {top: 20, right: 30, bottom:30, left: 80}
    this.xtime = true
  }

  setSync(sync) {
    this.sync = sync
  }

  setXTime(val) {
    this.xtime = val
  }

  update = (tss, xext, yext, dext, labels, colors, title, xlabel, xfixed, yfixed) => {
    if (this.disableUpdates) return
    this.ready = false

    //
    // Size of drawing area
    this.width = window.innerWidth * 0.7 - this.margin.left - this.margin.right
    this.height = this.width / 3

    //
    // xrange
    if ((tss.length > 0) &&
        (!this.sync.xrange || !this.sync.xrange[0] || !this.sync.xrange[1])) {
      this.sync.xrange = extent(tss[0], xext[0])
      this.yrange = null  // reset y range if x range indicates new data
    }

    //
    // yrange
    if (!this.yrange) {
      this.yrange = []
      for (let i in yext) {
        if (tss[i] && tss[i].length > 0) {
          this.yrange = this.yrange.concat(extent(tss[i], yext[i]))
        }
      }

      this.yrange = this.yrange.length > 1 ? extent(this.yrange) : [0, 1]
      let range = this.yrange[1] - this.yrange[0]
      this.yrange[0] -= 0.1 * range
      this.yrange[1] += 0.1 * range
    }

    select(this.myRef.current).selectAll('*').remove()

    this.xscale = scaleLinear()
    .domain(this.sync.xrange)
    .range([0, this.width])

    this.yscale = scaleLinear()
    .domain(this.yrange)
    .range([this.height, 0])

    this.chart = select(this.myRef.current)
    .append('svg')
    .attr('width', this.width + this.margin.left + this.margin.right)
    .attr('height', this.height + this.margin.top + this.margin.bottom)
    .attr('margin', 'auto')
    .attr('display', 'block')

    let darea = this.chart
    .append('g')
    .attr('transform', 'translate(' + this.margin.left + ','
          + this.margin.top + ')')

    // Grid lines extending across chart
    this.yGrid = axisLeft(this.yscale).ticks(5).tickSizeOuter(0).tickSizeInner(-this.width).tickFormat('')
    this.gyGrid = darea.append('g')
    .attr('color', '#555555')
    .call(this.yGrid)

    this.xGrid = axisBottom(this.xscale).ticks(5).tickSizeInner(this.height).tickFormat('')
    this.gxGrid = darea.append('g')
    .attr('color', '#555555')
    .call(this.xGrid)

    // x and y axis (bottom and left) with tick marks and labels
    this.yAxis = axisLeft(this.yscale).ticks(5).tickSizeOuter(0).tickSizeInner(5)
    this.gyAxis = darea.append('g')
    .style('font-size', '14px')
    .call(this.yAxis)

    if (this.xtime) {
      let xFormat = '%H:%M:%S'
      this.xAxis = axisBottom(this.xscale)
      .ticks(5)
      .tickFormat(timeFormat(xFormat))
      .tickSizeOuter(0)
      .tickSizeInner(-5)
    } else {
      this.xAxis = axisBottom(this.xscale).ticks(5).tickSizeOuter(0).tickSizeInner(5)
    }

    this.gxAxis = darea.append('g')
    .style('font-size', '14px')
    .attr('transform', 'translate(0,' + this.height + ')')
    .call(this.xAxis)

    // x and y axis (top and right) with no tick marks -- just completes the box
    darea.append('g')
    .attr('transform', 'translate(' + this.width + ',0)')
    .call(axisRight(this.yscale).ticks(0).tickSizeOuter(0))

    darea.append('g')
    .call(axisTop(this.xscale).ticks(0).tickSizeOuter(0))

    // Now that axes and plot box are drawn, clip anything outside of main plotting area
    darea.append('defs').append('svg:clipPath')
    .attr('id', 'clip')
    .append('svg:rect')
    .attr('width', this.width)
    .attr('height', this.height)
    .attr('x', 0)
    .attr('y', 0)

    // Now that axes and plot box are drawn, clip anything outside of main plotting area
    darea.append('defs').append('svg:clipPath')
    .attr('id', 'clip')
    .append('svg:rect')
    .attr('width', this.width)
    .attr('height', this.height)
    .attr('x', 0)
    .attr('y', 0)

    let clippedArea = darea.append('g')
    .attr('clip-path', 'url(#clip)')

    for (let i in yext) {
      if (tss[i]) {

        tss[i] = tss[i].filter(el => {
          return !isNaN(yext[i](el))
        })
        tss[i] = makeMultiscale(tss[i], xext[i])
      }
    }

    this.traceInfo = { yextractors: yext
                     , xextractors: xext
                     , dextractors: dext
                     , tss: tss
                     , labels: labels
                     , colors: colors
                     , clippedArea: clippedArea
                     , xlabel: xlabel
                     , xfixed: xfixed
                     , yfixed: yfixed
                     }
    this.trace = []
    this.tooltip = darea.append('g')

    this.attachZoomAndDragHandlers(darea, yext)

    if (title) {
      this.chart.append('text')
      .attr('x', this.margin.left + 200)
      .attr('y', this.margin.top + 30)
      .attr('fill', 'white')
      .style('font-size', '14px')
      .text(title)
    }

    this.ready = true
    this.updateTransform()
    this.updateTooltip()
  }

  buildTraces = (xmin, xmax) => {
    this.traceInfo.clippedArea.selectAll('*').remove()
    this.trace = []

    for (let i in this.traceInfo.yextractors) {
      if (this.traceInfo.tss[i]) {
        let seriesList = this.traceInfo.tss[i].getSeries(xmin, xmax)

        for (let j in seriesList) {
          let series = seriesList[j]
          if (series.length > 0) {
            let traceLine = line()
            .x(d => this.xscale(this.traceInfo.xextractors[i](d)))
            .y(d => this.yscale(this.traceInfo.yextractors[i](d)))

            let color = this.traceInfo.colors[i]

            let trace = this.traceInfo.clippedArea.append('path')
            .datum(series)
            .style('vector-effect', 'non-scaling-stroke')
            .attr('fill', 'none')
            .attr('stroke', color)
            .attr('stroke-width', 1.5)
            .attr('stroke-linecap', 'round')
            .attr('stroke-linejoin', 'round')
            .attr('d', traceLine)

            this.trace.push(trace)

            if (this.traceInfo.dextractors && this.traceInfo.dextractors.length > 0) {
              let node = this.traceInfo.clippedArea.selectAll('dot').data(series)
              .enter().append('g')

              node.append('circle')
              .attr('cx', d => this.xscale(this.traceInfo.xextractors[i](d)))
              .attr('cy', d => this.yscale(this.traceInfo.yextractors[i](d)))
              .attr('r', 5)
              .attr('stroke', color)

              node.append('text')
              .attr('dx', d => this.xscale(this.traceInfo.xextractors[i](d)) + 10)
              .attr('dy', d => this.yscale(this.traceInfo.yextractors[i](d)) + 10)
              .text(d => this.traceInfo.dextractors[i](d))
              .attr('font-family', 'sans-serif')
              .attr('font-size', '10px')
              .attr('fill', color)
            }


          }
        }
      }
    }
  }

  updateTransform = () => {
    let transString = 'translate (' + this.sync.dx + ' ' + this.dy + ') '
    transString += 'scale (' + this.sync.scale + ' 1)'

    this.trace.forEach(trace => {
      trace.attr('transform', transString)
    })

    let x0 = -this.sync.dx / this.sync.scale
    let x1 = x0 + this.width / this.sync.scale
    let xx0 = x0 / this.width * (this.sync.xrange[1] - this.sync.xrange[0]) + this.sync.xrange[0]
    let xx1 = x1 / this.width * (this.sync.xrange[1] - this.sync.xrange[0]) + this.sync.xrange[0]
    this.xscale = this.xscale.domain([xx0, xx1])
    this.gxAxis.call(this.xAxis.scale(this.xscale))
    this.gxGrid.call(this.xGrid.scale(this.xscale))

    let y0 = this.dy
    let y1 = y0 + this.height
    let yy0 = y0 / this.height * (this.yrange[1] - this.yrange[0]) + this.yrange[0]
    let yy1 = y1 / this.height * (this.yrange[1] - this.yrange[0]) + this.yrange[0]
    this.yscale = this.yscale.domain([yy0, yy1])
    this.gyAxis.call(this.yAxis.scale(this.yscale))
    this.gyGrid.call(this.yGrid.scale(this.yscale))

    this.buildTraces(xx0, xx1)
  }

  attachZoomAndDragHandlers = (darea, yext) => {
    let This = this

    this.scale = this.sync.scale
    this.chart.call(drag()
    .on('start', () => {
      this.disableUpdates = true
    })
    .on('end', () => {
      this.disableUpdates = false
    })
    .on('drag', (event) => {
      this.sync.dx += event.dx
      this.dy += event.dy

      this.sync.updateTransform()
      this.updateTooltip()
    }))

    this.chart.call(zoom().on('zoom', (event) => {
      let trans = event.transform

      let x0 = -this.sync.dx / this.sync.scale
      let x1 = x0 + this.width / this.sync.scale
      let center = (x1 + x0) / 2.0

      let xoff1 = center * this.sync.scale
      this.sync.scale = trans.k * this.scale
      let xoff2 = center * this.sync.scale

      this.sync.dx += (xoff1 - xoff2)

      this.sync.updateTransform()

      this.updateTooltip()
    }))

    this.chart
    .on('mousemove', function(event) {
      let [xd, yd] = pointer(event)  // device coordinates
      let xw = This.xscale.invert(xd)  // world coordinate
      let yw = This.yscale.invert(yd)  // world coordinate

      This.xdMouse = xd
      This.ydMouse = yd
      This.xwMouse = xw
      This.ywMouse = yw

      This.updateTooltip()
    })
    .on('mouseenter', function() {
      This.sync.mouseNotActive()
      This.mouseActive = true
      This.updateTooltip()
    })
    .on('mouseleave', function() {
      This.mouseActive = false
      This.clearTooltip()
    })

    if (yext.length > 1) {
      for (let i in yext) {
        let color = this.traceInfo.colors[i]

        this.chart.append('text')
        .attr('x', this.margin.left + 10)
        .attr('y', this.margin.top + 30 + i * 15)
        .attr('fill', color)
        .style('font-size', '14px')
        .text(this.traceInfo.labels[i])
      }
    }
  }

  clearTooltip = () => {
    this.tooltip
    .call(drawTooltip, null)
  }

  updateTooltip = () => {
    if (!this.mouseActive || !this.ready) {
      this.clearTooltip()
      return
    }

    let message = ''
    let xdd = this.xdMouse
    let ydd = this.ydMouse


    for (let i in this.traceInfo.tss) {
      let ts = this.traceInfo.tss[i]
      if (ts) {
        ts = ts.getOriginalSeries()
        if (ts.length > 0) {
          let timeBisect = bisector(d => { return this.traceInfo.xextractors[i](d)})
          let index = timeBisect.left(ts, this.xwMouse)
          if (index === ts.length) index = ts.length - 1

          if (i === '0' && ts[index]) {

            xdd = this.xscale(this.traceInfo.xextractors[i](ts[index]))
            ydd = this.yscale(this.traceInfo.yextractors[i](ts[index]))

            if (this.xtime) {
              let time = new Date(ts[index].time)
              message += moment(time).format('YYYY/MM/DD hh:mm:ss')
            } else {
              if (this.traceInfo.dextractors && this.traceInfo.dextractors.length > 0) {
                message += this.traceInfo.dextractors[i](ts[index]) + '\n'
              }
              let xlabel = this.traceInfo.xlabel ? this.traceInfo.xlabel : 'x'
              let xval = this.traceInfo.xextractors[i](ts[index])
              if (this.traceInfo.xfixed) {
                xval = xval.toFixed(this.traceInfo.xfixed)
              } else {
                xval = xval.toPrecision(3)
              }
              message += xlabel + ': ' + xval
            }
          }


          let val = this.traceInfo.yextractors[i](ts[index])
          if (typeof(val) === 'number') {
            if (this.traceInfo.yfixed) {
              val = val.toFixed(this.traceInfo.yfixed)
            } else {
              if (Math.abs(val) > 100) val = val.toFixed(0)
              else                     val = val.toPrecision(3)
            }
          } else {
            console.log('typeof val = ' + typeof(val))
          }
          message += '\n' + this.traceInfo.labels[i] + ': ' + val
        }
      }
    }

    this.tooltip
    .attr('transform', `translate(${xdd},${ydd})`)
    .call(drawTooltip, message)
  }

  keydown = (component, e) => {
    if (this.mouseActive) {
      if (e.key === 't') {
        this.yrange = [this.yscale.invert(this.height), this.ywMouse]
        this.dy = 0
        component.update()

        let yw = this.yscale.invert(this.ydMouse)  // world coordinate
        this.ywMouse = yw
      }

      if (e.key === 'b') {
        this.yrange = [this.ywMouse, this.yscale.invert(0)]
        this.dy = 0
        component.update()

        let yw = this.yscale.invert(this.ydMouse)  // world coordinate
        this.ywMouse = yw
      }

      if (e.key === 'f') {
        this.yrange = null
        this.dy = 0
        component.update()

        let yw = this.yscale.invert(this.ydMouse)  // world coordinate
        this.ywMouse = yw
      }

    }
  }

  clearYRange = () => {
    this.yrange = null
    this.dy = 0
  }


}

export default TimeSeriesPlot
