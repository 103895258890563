import React from 'react'
import MuteRenderer from './MuteRenderer'
import {UpdatedTable} from './UpdatedTable'

import './App.css'

class AuxMarketTable extends UpdatedTable {
  constructor(props) {
    super(props)

    let This = this

    this.ldMap = {}
    this.dataStore.ldList.forEach(ld => {
      this.ldMap[ld.back] = ld
    })

    let positionAdj = function(params) {
      if (params.data.symbol === 'Total') return undefined
      let position = params.data.position
      let adjustmentPerContract = params.data.adjustPerContract

      return position * adjustmentPerContract
    }

    let adjustTheo = function(params) {
      let theo = params.data.theo
      if (!theo) return undefined

      let oAdj = params.data.hcAdjustment
      let posAdj = positionAdj(params)

      return theo + oAdj + posAdj
    }

    let expiriesGetter = function(params) {
      if (params.data.optionExpiries) return params.data.optionExpiries
      else                            return 'zzzz total'
    }

    let expiriesFormatter = function(params) {
      if (params.value === 'zzzz total') return ''
      else                               return params.value
    }

    let calculateCredit = function(params, sideSign) {
      let posAdj = positionAdj(params)
      let credit = params.data.warnCredit
      let aggressiveAdjustmentFactor = params.data.warnCreditAggrFactor

      // Add additional credit on the aggressive side.
      // Reduces chance to trade at a negative price relative to unadjTheo.
      let adjCredit = Math.max(0.0, sideSign * posAdj * (1.0 - aggressiveAdjustmentFactor))

      return credit + adjCredit
    }

    let lastUpdatedStyle = function(key) {
      return function(params) {
        if (params.data.symbol === 'Total') return undefined
        if (params.data[key] === undefined) return {textAlign: 'right', 'backgroundColor': 'red'}

        let now = new Date()
        let lastUpdated = new Date(params.data[key])
        let elapsedTime = now - lastUpdated
        if (elapsedTime > 120 * 1000) {
          return {textAlign: 'right', 'backgroundColor': 'red'}
        }
        return {textAlign: 'right', 'backgroundColor': ''}
      }
    }

    let theoStyle = function(params) {
      let adjTheo = adjustTheo(params)
      let buyCredit = calculateCredit(params, 1.0)
      let sellCredit = calculateCredit(params, -1.0)

      if (!adjTheo) return {textAlign: 'right', 'backgroundColor': ''}

      let mm = (params.data.marketBidPx + params.data.marketAskPx) / 2.0
      if (!mm) mm = 0

      if ((adjTheo - mm) > buyCredit || (mm - adjTheo) > sellCredit) {
        let alerts = {}
        alerts[params.data.symbol] = true
        This.dataStore.app.playSound(This.dataStore.app.alertSound, This.ldMap[params.data.back], alerts, x => x, 'vixmute', false)
        return {textAlign: 'right', 'backgroundColor': 'red'}
      } else {
        return {textAlign: 'right', 'backgroundColor': ''}
      }
    }

    let defaultColWidth = 60
    this.state =
      { defaultColDef: {resizable: true
                       , sortable: true
                       , width: defaultColWidth
                       , cellStyle: this.right
                       }
      , columnDefs: [ { width: 50
                      , headerName: 'Back'
                      , field: 'back'
                      , valueFormatter: this.backFormatter('back')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Symbol'
                      , field: 'symbol'
                      }
                    , { width: defaultColWidth
                      , headerName: 'Delta'
                      , field: 'delta'
                      , valueFormatter: this.fixedFormatter(3)
                      }
                    , { width: defaultColWidth
                      , headerName: 'mBQ'
                      , field: 'bidSize'
                      }
                    , { width: defaultColWidth
                      , headerName: 'mBPx'
                      , field: 'marketBidPx'
                      , valueFormatter: this.fixedFormatter(2)
                      }
                    , { width: defaultColWidth
                      , headerName: 'aTheo'
                      , valueGetter: adjustTheo
                      , cellStyle: theoStyle
                      , valueFormatter: this.fixedFormatter(2)
                      }
                    , { width: defaultColWidth
                      , headerName: 'mAPx'
                      , field: 'marketAskPx'
                      , valueFormatter: this.fixedFormatter(2)
                      }
                    , { width: defaultColWidth
                      , headerName: 'mAQ'
                      , field: 'askSize'
                      }
                    , { width: 50
                      , headerName: 'Pos'
                      , field: 'position'
                      }
                    , { width: 50
                      , headerName: 'aPos'
                      , field: 'desiredAdditionalPosition'
                      , valueFormatter: this.fixedFormatter(2)
                      }
                    , { width: 50
                      , headerName: 'tPos'
                      , field: 'tradingDesiredAdditional'
                      , valueFormatter: this.fixedFormatter(2)
                      }
                    , { width: 60
                      , headerName: 'UHPos'
                      , field: 'unhedgedVixFuturePosition'
                      , valueFormatter: this.fixedFormatter(2)
                      }
                    , { width: defaultColWidth
                      , headerName: 'Theo'
                      , field: 'theo'
                      , valueFormatter: this.fixedFormatter(2)
                      }
                    , { width: 55
                      , headerName: 'oAdj'
                      , field: 'hcAdjustment'
                      , valueFormatter: this.fixedFormatter(2)
                      }
                    , { width: 60
                      , headerName: 'posAdj'
                      , valueGetter: positionAdj
                      , valueFormatter: this.fixedFormatter(2)
                      }
                    , { width: defaultColWidth
                      , headerName: 'ApC'
                      , field: 'adjustPerContract'
                      , valueFormatter: this.fixedFormatter(3)
                      }
                    , { width: 70
                      , headerName: 'hcUCAdj'
                      , field: 'hcUncappedAdjustment'
                      , valueFormatter: this.fixedFormatter(2)
                      }
                    , { width: 60
                      , headerName: 'hcApC'
                      , field: 'hcAdjustPerContract'
                      , valueFormatter: this.fixedFormatter(2)
                      }
                    , { width: 60
                      , headerName: 'VAlpha'
                      , field: 'hcVolAlpha'
                      , valueFormatter: this.fixedFormatter(2)
                      }
                    , { width: 70
                      , headerName: 'dnPrChg'
                      , field: 'deltaNeutralizedVixFuturePriceChange'
                      , valueFormatter: this.fixedFormatter(2)
                      }
                    , { width: defaultColWidth
                      , headerName: 'TE'
                      , field: 'hcTrackingError'
                      , valueFormatter: this.fixedFormatter(3)
                      }
                    , { width: 70
                      , headerName: 'UHPnl'
                      , field: 'vixFutureUnhedgedPositionSlippage'
                      , valueFormatter: this.currencyFormatter('vixFutureUnhedgedPositionSlippage')
                      }
                    , { width: 70
                      , headerName: 'TEPnl'
                      , field: 'hcTrackingErrorPnl'
                      , valueFormatter: this.currencyFormatter('hcTrackingErrorPnl')
                      }
                    , { width: 75
                      , headerName: 'Convexity'
                      , field: 'convexity'
                      , valueFormatter: this.fixedFormatter(2)
                      }
                    , { width: 75
                      , headerName: 'ThetaDiff'
                      , field: 'hcThetaDiff'
                      , valueFormatter: this.fixedFormatter(3)
                      }
                    , { width: 70
                      , headerName: 'UHTheta'
                      , field: 'hcThetaDiffPV'
                      , valueFormatter: this.currencyFormatter('hcThetaDiffPV')
                      }
                    , { width: 80
                      , headerName: 'GammaDiff'
                      , field: 'hcGammaDiff'
                      , valueFormatter: this.fixedFormatter(5)
                      }
                    , { width: 75
                      , headerName: 'UHGamma'
                      , field: 'hcGammaDiffPV'
                      , valueFormatter: this.fixedFormatter(3)
                      }
                    , { width: 65
                      , headerName: 'UHVega'
                      , field: 'hcVegaDiffPV'
                      , valueFormatter: this.fixedFormatter(0)
                      }
                    , { width: defaultColWidth
                      , headerName: 'settle'
                      , field: 'settlement'
                      , valueFormatter: this.fixedFormatter(2, true)
                      }
                    , { width: 140
                      , headerName: 'Market Updated'
                      , field: 'time'
                      , cellStyle: lastUpdatedStyle('time')
                      , valueFormatter: this.timeFormatter('time')
                      }
                    , { width: 140
                      , headerName: 'Theo Updated'
                      , field: 'theo-time'
                      , cellStyle: lastUpdatedStyle('theo-time')
                      , valueFormatter: this.timeFormatter('theo-time')
                      }
                    , { width: 140
                      , headerName: 'oExpiries'
                      , field: 'optionExpiries'
                      , valueGetter: expiriesGetter
                      , valueFormatter: expiriesFormatter
                      }
                    , { width: 70
                      , headerName: 'Mute'
                      , field: 'mute'
                      , cellStyle: this.center
                      , cellRenderer: MuteRenderer
                      , cellRendererParams: { muteComponent: this.ldMap
                                            , appField: 'vixmute'
                                            , idField: 'symbol'
                                            , multiGui: true
                                            }
                      }
                    ]
      , mute: this.dataStore.vixmute
      }

    this.redraw(['time', 'theo-time', 'marketBidPx'])
    this.gengrid()
  }

  fixedFormatter = (nfixed = 3, blankZero = false) => {
    return function(params) {
      let val = params.value

      if (typeof val !== 'number') return val
      if (blankZero && val === 0) return ''
      return val.toFixed(nfixed)
    }
  }

  getRowId = (params) => {
    return params.data.back + '/' + params.data.symbol
  }

  onGridReadyDerived = () => {
    let sortModel = [ {colId: 'back', sort: 'asc'}, {colId: 'optionExpiries', sort: 'asc'} ]
    this.api.applyColumnState({state: sortModel})
  }

  isExternalFilterPresent = () => true
  doesExternalFilterPass = row => {
    return (!row.data.symbol.endsWith('-delta') && !row.data.symbol.endsWith('-theo') && !row.data.symbol.endsWith('-te') && !row.data.symbol.endsWith('-uh') && !row.data.symbol.endsWith('-volChg'))
  }

  render() {
    let gridStyle = { width: this.totalWidth() }

    return (
      <div className="guif-div">
        <div className="ag-theme-balham-dark"
             style={gridStyle}>
          {this.grid}
        </div>
      </div>
    )
  }
}

export default AuxMarketTable
