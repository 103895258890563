import React from 'react'
import MuteRenderer from './MuteRenderer'
import ParameterQuickSet from './ParameterQuickSet'
import ServiceInstrumentation from './ServiceInstrumentation'
import ScenarioTable from './ScenarioTable'
import SlippageTable from './SlippageTable'
import StraddleOptionTable from './StraddleOptionTable'
import StraddleComboTable from './StraddleComboTable'
import FitValidationTable from './FitValidationTable'
import AuxMarketTable from './AuxMarketTable'
import PnlStatisticsTable from './PnlStatisticsTable'
import TradingMode from './TradingMode'

import PropTypes from 'prop-types'
import { AgGridReact } from 'ag-grid-react'

import moment from 'moment'

import {getCookies} from './util.jsx'
import './App.css'

class ServiceStatus extends TradingMode {
  constructor(props) {
    super(props)

    this.pctr = props.pctr
    this.throughMarket = props.throughMarket
    this.okToUpdate = true

    this.pctr.addListener('service-status-table', this.update)
    this.pctr.addListener('heartbeat-filter', this.onNewHeartbeatFilter)
    this.pctr.listenerData.addListener('config', this.onConfig)

    this.ldMap = {'default': this.pctr.listenerData}
    //
    // Constructor helper functions
    //

    let left   = {textAlign: 'left', borderLeft: '1px solid #555555'}
    let right  = {textAlign: 'right', borderLeft: '1px solid #555555'}
    let center = {textAlign: 'center', borderLeft: '1px solid #555555'}

    let merge = function(style) {
      // setting border-left here is a hack to get the appearance of vertical 
      // grid lines.  If you end up extending functionality here and this
      // is squatting on your intended styling, look at writing custom
      // css for .ag-cell-no-focus and .ag-cell-focus
      let styleDefault = {borderLeft: '1px solid #555555'}
      return Object.assign(style, styleDefault)
    }

    let statusStyle = function(params) {
      if (params.data.status === 'OK')    return merge({backgroundColor: 'green'})
      if (params.data.status === 'WARN')  return merge({backgroundColor: 'orange'})
      if (params.data.status === 'ALERT') return merge({backgroundColor: 'red'})

      if (params.data.lastUpdated === undefined) return merge({backgroundColor: 'red'})
      return merge({backgroundColor:''})
    }

    let runningStyle = function(params) {
      if (params.data.lastUpdated === undefined) return merge({backgroundColor: 'red'})

      let now = new Date()
      let lastUpdated = new Date(params.data.lastUpdated)
      let elapsedTime = now - lastUpdated
      if (elapsedTime > 30 * 1000) {
        return merge({backgroundColor: 'red'})
      } else {
        return merge({backgroundColor: 'green'})
      }
    }

    let lastUpdatedStyle = function(params) {
      if (params.data.lastUpdated === undefined) 
        return merge({textAlign: 'right', backgroundColor: 'red'})
      return merge({textAlign: 'right', backgroundColor:''})
    }

    let timeRenderer = function(params) {
      let time = params.data[params.timeKey]
      if (time === undefined) return time

      // Need to write these out with styling so that the numbers don't bounce around as
      // the text changes (monospaced fonts are ugly, just put some divs/padding in place)
      return moment(time).format('YYYY/MM/DD hh:mm:ss')
    }

    //
    // Component state
    //
    this.state = 
      { defaultColDef: { resizable: true
                       , sortable: true
                       }
      , columnDefs: [ { width: 120
                      , headerName: 'Service Name'
                      , field: 'serviceName'
                      , cellStyle: left
                      }
                    , { width: 80
                      , headerName: 'Run'
                      , field: 'running'
                      , cellStyle: runningStyle
                      }
                    , { width: 80
                      , headerName: 'Status'
                      , field: 'status'
                      , cellStyle: statusStyle
                      }
                    , { width: 625
                      , headerName: 'Details'
                      , field: 'details'
                      , cellStyle: left
                      }
                    , { width: 160
                      , headerName: 'Last Updated'
                      , field: 'lastUpdated'
                      , cellStyle: lastUpdatedStyle
                      , cellRenderer: timeRenderer
                      , cellRendererParams: { timeKey: 'lastUpdated' }
                      }
                    , { width: 80
                      , headerName: 'ID'
                      , field: 'serviceID'
                      , cellStyle: right
                      }
                    , { width: 70
                      , headerName: 'Mute'
                      , field: 'mute'
                      , cellStyle: center
                      , cellRenderer: MuteRenderer
                      , cellRendererParams: { muteComponent: this
                                            , appField: 'mute'
                                            , idField: 'serviceID'
                                            }
                      }
                    ]
      , rowData: {}
      , mute: this.pctr.listenerData.mute
      , muteButtons: {}
      , soundEnabled: this.pctr.listenerData.soundEnabled
      , heartbeatsEnabled: this.pctr.listenerData.heartbeatsEnabled
      , heartbeatFilter: this.pctr.listenerData.heartbeatFilter
      , executionAlertsEnabled: this.pctr.listenerData.executionAlertsEnabled
      , pnlAlertsEnabled: this.pctr.listenerData.pnlAlertsEnabled
      , pnlEWMADiffThreshold: this.pctr.listenerData.pnlEWMADiffThreshold
      }

    let cookies = getCookies()
    this.user = cookies.user

    this.gengrid()
    this.redraw()
  }

  update = rowData => {
    if (this.okToUpdate && rowData !== undefined) {
      let servicesMap = {}
      for (let index in rowData) {
        servicesMap[rowData[index].serviceID] = rowData[index]
      }
      
      let adds = []
      let updates = []
      if (this.api !== undefined && !this.api.isDestroyed()) {
        for (let id in servicesMap) {
          if (id in this.state.rowData) {
            let updateItem = servicesMap[id]
            updates.push(updateItem)
          } else {
            let newItem = servicesMap[id]
            adds.push(newItem)
          }        
        }
      }
      
      if (this.api !== undefined && !this.api.isDestroyed()) {
        this.okToUpdate = false
        let updateCallback = () => {
          this.okToUpdate = true
          this.setState({rowData: servicesMap})
        }

        this.api.applyTransactionAsync({
          add: adds
          , update: updates
        }, 
        updateCallback)
      }
    }
  }

  componentDidMount() {
    this.setState({ heartbeatFilter: this.pctr.listenerData.heartbeatFilter })
  }

  componentWillUnmount() {
    this.pctr.removeListener('service-status-table', this.update)
    this.pctr.removeListener('heartbeat-filter', this.onNewHeartbeatFilter)
    this.pctr.listenerData.removeListener('config', this.onConfig)
  }

  getRowId = (params) => {
    return `${params.data.serviceID}`
  }

  onGridReady = (params) => {
    this.api = params.api
    this.update(this.pctr.listenerData['service-status-table'])
  }

  onCellClicked = (params) => {
    if (params.colDef.headerName === 'Service Name') {
      if (params.data.instrumentation && params.data.serviceName !== 'SP') {
        let serviceID = params.data.serviceID
        let x = params.event.clientX
        let y = params.event.clientY
        
        let menu = document.createElement('div')
        menu.style.width = '110px'
        menu.style.backgroundColor = 'white'
        
        // bring up the menu under the current pointer position.  Offset just
        // a bit so mouse is in menu at start -- leaving the menu closes it
        menu.style.position = 'absolute'
        menu.style.left = (x - 10) + 'px'
        menu.style.top = (y - 10) + 'px'
        
        // Make the menu disappear if user leaves
        menu.addEventListener('mouseleave', function() {
          menu.parentNode.removeChild(menu)
        })
        
        let label = document.createElement('div')
        label.innerHTML = 'ServiceID ' + serviceID
        label.style.fontSize = '12px'
        label.style.textAlign = 'center'
        menu.appendChild(label)
        
        let sendControlService = action => {
          return () => {
            this.pctr.POST('/control/service', {serviceID: serviceID, action: action})

            menu.parentNode.removeChild(menu)
          }
        }
        
        {
          let button = document.createElement('button')
          button.style.width = '100%'
          button.innerHTML = 'Start'
          button.onclick = sendControlService('start')
          menu.appendChild(button)
        }
        
        {
          let button = document.createElement('button')
          button.style.width = '100%'
          button.innerHTML = 'Stop'
          button.onclick = sendControlService('stop')
          menu.appendChild(button)
        }
        
        {
          let button = document.createElement('button')
          button.style.width = '100%'
          button.innerHTML = 'Pause'
          button.onclick = sendControlService('pause')
          menu.appendChild(button)
        }
        
        {
          let button = document.createElement('button')
          button.style.width = '100%'
          button.innerHTML = 'Resume'
          button.onclick = sendControlService('resume')
          menu.appendChild(button)
        }
        
        document.body.appendChild(menu)
      }
    }
  }

  gengrid = () => {
    this.grid = (
      <AgGridReact defaultColDef={this.state.defaultColDef}
                   columnDefs={this.state.columnDefs}
                   suppressRowClickSelection={true}
                   suppressCellSelection={true}
                   getRowId={this.getRowId}
                   onGridReady={this.onGridReady}
                   domLayout="autoHeight"
                   onCellClicked={this.onCellClicked}>
      </AgGridReact>
    )
  }

  redraw = () => {
    // 'service-status-table' events will cause the grid to be redrawn.  But *also* redraw
    // every second -- this is so we can keep the columns up-to-date in the event the
    // backend goes down.  This also keep backgrounds up to date after bulk updates
    // to the ag-grid.
    if (this.api !== undefined && !this.api.isDestroyed()) {
      this.api.refreshCells({force:true, columns: ['running', 'status', 'lastUpdated']})
    }
    setTimeout(this.redraw, 1000)
  }

  onEnableSound = (params) => {
    this.pctr.listenerData.soundEnabled = params.target.checked
    this.setState({'soundEnabled': this.pctr.listenerData.soundEnabled})
  }

  onEnableHeartbeats = (params) => {
    this.pctr.listenerData.heartbeatsEnabled = params.target.checked
    this.setState({'heartbeatsEnabled': this.pctr.listenerData.heartbeatsEnabled})
  }

  onEnableExecutionAlerts = (params) => {
    this.pctr.listenerData.executionAlertsEnabled = params.target.checked
    this.setState({'executionAlertsEnabled': this.pctr.listenerData.executionAlertsEnabled})
  }

  onEnablePnlAlerts = (params) => {
    this.pctr.listenerData.pnlAlertsEnabled = params.target.checked
    this.setState({'pnlAlertsEnabled': this.pctr.listenerData.pnlAlertsEnabled})
  }

  onPnlEWMADiffThreshold = (params) => {
    this.pctr.listenerData.pnlEWMADiffThreshold = params.target.value
    this.setState({'pnlEWMADiffThreshold': this.pctr.listenerData.pnlEWMADiffThreshold})
  }

  commandAll = action => {
    for (let serviceID in this.state.rowData) {
      let row = this.state.rowData[serviceID]
      if (row.instrumentation && row.serviceName !== 'SP') {
        this.pctr.POST('/control/service', {serviceID: serviceID, action: action})
      }
    }
  }

  onStartAll = () => {
    this.commandAll('start')
  }

  onStopAll = () => {
    this.commandAll('stop')
  }

  alertFilterOnInput = (event) => {
    this.pctr.listenerData.alertFilter = event.target.value
  }

  onTakeHeartbeatFilter = () => {
    this.pctr.POST('/control/heartbeatFilter', { clientIP: this.pctr.listenerData.clientIP
                                               , user: this.user})
  }

  onNewHeartbeatFilter = data => {
    let filter = data.ip
    this.pctr.heartbeatFilter = filter
    this.setState({ heartbeatFilter: filter
                  })
  }

  onConfig = () => {
    this.setState({ heartbeatFilter: this.pctr.listenerData.heartbeatFilter
                  , pnlEWMADiffThreshold: this.pctr.listenerData.pnlEWMADiffThreshold})
  }

  render() {  
    let totalWidth = 2 // pixel on each side
    this.state.columnDefs.forEach(col => {if (col.width) totalWidth += col.width})
    if (totalWidth > window.innerWidth - 50) totalWidth = window.innerWidth - 50

    let gridStyle = { width: totalWidth + 'px' }
    let hbfBackground = this.state.heartbeatFilter === this.pctr.listenerData.clientIP ? 'green' : 'red'

    let disabled = (this.pctr.back !== undefined) && (this.pctr.back !== 'stand-alone')

    return (
      <div className="service-status" style={{marginBottom: '20px'}}>
        <div className="ag-theme-balham-dark"
             style={gridStyle}>
          {this.grid}
        </div>

        <div style={{ fontSize: '14px', textAlign: 'left'
                    , minWidth: '900px', marginTop: '10px', display: 'inline-block'}}>
          <div style={{display: 'inline-block'}}>
            Send Heartbeats <input type="checkbox" 
                                   defaultChecked={this.state.heartbeatsEnabled}
                                   onChange={this.onEnableHeartbeats}
                                   disabled={disabled}/>
          </div>

          <button onClick={this.onTakeHeartbeatFilter}
                  style={{marginLeft: '5px', background: hbfBackground}}
                  disabled={disabled}>Take</button>

          <div style={{display: 'inline-block', width: '30px'}}>
          </div>

          <div style={{display: 'inline-block'}}>
            Enable sound <input type="checkbox"
                                defaultChecked={this.state.soundEnabled}
                                onChange={this.onEnableSound}
                                disabled={disabled}/>
          </div>

          <div style={{display: 'inline-block', width: '30px'}}>
          </div>

          <button onClick={this.onStartAll}>Start All</button>

          <div style={{display: 'inline-block', width: '30px'}}>
          </div>

          <button onClick={this.onStopAll}>Stop All</button>

          <input className="guif-input" type="text" size="20" placeholder="ignore alert filter"
                 style={{marginLeft: '50px'}}
                 defaultValue={this.pctr.listenerData.alertFilter}
                 onChange={this.alertFilterOnInput}
                 disabled={disabled}>
          </input>
        </div>

        <div style={{ fontSize: '14px', textAlign: 'left'
                    , minWidth: '900px', marginTop: '10px', display: 'inline-block'}}>
          <div style={{display: 'inline-block'}}>
            Execution Alerts <input type="checkbox"
                                    defaultChecked={this.state.executionAlertsEnabled}
                                    onChange={this.onEnableExecutionAlerts}
                                    disabled={disabled}/>
          </div>

          <div style={{display: 'inline-block', width: '20px'}}>
          </div>

          <div style={{display: 'inline-block'}}>
            PnL Alerts <input type="checkbox"
                              defaultChecked={this.state.pnlAlertsEnabled}
                              onChange={this.onEnablePnlAlerts}
                              disabled={disabled}/>
          </div>

          <div style={{display: 'inline-block', width: '5px'}}>
          </div>

          <div style={{display: 'inline-block'}}>
            EWMA diff threshold <input type="text"
                                       size="5"
                                       onChange={this.onPnlEWMADiffThreshold}
                                       disabled={disabled}
                                       defaultValue={this.state.pnlEWMADiffThreshold}/>
          </div>
        </div>

        {this.tradingModes &&
        <div style={{padding: '10px', marginTop: '15px', border: '1px solid #555555'}}>
          <div style={{fontSize: '14px', marginBottom: '10px'}}>Trading Modes</div>
          {this.tradingModes.map(mode => this.renderTradingMode(mode))}
        </div>
        }

        <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
          <ParameterQuickSet pctr={this.pctr} messagename='parameters'/>
        </div>

        <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
          <ServiceInstrumentation pctr={this.pctr} messagename='service-status-table'/>
        </div>

        {this.throughMarket &&
          <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
            <AuxMarketTable dataStore={this.pctr.dataStore} messagename='aux-market-info'/>
          </div>
        }

        {this.throughMarket &&
          <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
            <PnlStatisticsTable pctr={this.pctr} messagename='pnl-statistics'/>
          </div>
        }

        {this.throughMarket &&
          <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
            <FitValidationTable pctr={this.pctr} messagename='fit-validation'/>
          </div>
        }

        <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
          <ScenarioTable pctr={this.pctr} messagename='scenarios'/>
        </div>

        {this.throughMarket &&
            <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
              <SlippageTable pctr={this.pctr} messagename='slippage'/>
            </div>
        }

        {this.throughMarket &&
          <div className="straddle-view">
            <StraddleOptionTable pctr={this.pctr}
                                 height='300px'
                                 title='Through Market Options'
                                 filter='through-market'/>
          </div>
        }

        {this.throughMarket &&
          <div className="straddle-view">
            <StraddleComboTable pctr={this.pctr}
                                type='combos'
                                height='300px'
                                messagename='straddle-combos-sec-params'
                                title='Through Market Combos'
                                filter='through-market'/>
          </div>
        }
      </div>
    )    
  }
}

ServiceStatus.propTypes = 
  { pctr: PropTypes.object
  , throughMarket: PropTypes.bool
  }

export default ServiceStatus
