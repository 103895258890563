import cookie from 'cookie'

let getCookies = function() {
  let cookies = cookie.parse(document.cookie)
  for (let cookie in cookies) {
    if (cookies[cookie] === 'true') cookies[cookie] = true
    if (cookies[cookie] === 'false') cookies[cookie] = false
  }

  return cookies
}

let getValue = function(value, path) {
  let parts = path.split('.')
  parts.forEach(part => {
    if (value) value = value[part]
  })

  return value
}

let bidStyle = function(theo, bid, theoThreshold, theoScale, width) {
  if (bid === 0 || isNaN(bid)) {
    return {textAlign: 'right', backgroundColor: ''}
  } else {
    if (isNaN(width)) {
      width = 0.0
    }
    const pushFactor = 0.1
    let push = pushFactor * width
    const bidOffset = (bid + push) - theo
    if (bidOffset < theoThreshold)
      return {textAlign: 'right', backgroundColor: ''}
    const scaledOffset = bidOffset / theoThreshold * 0.1
    return {textAlign: 'right', backgroundColor: theoScale(scaledOffset)}
  }
}

let askStyle = function(theo, ask, theoThreshold, theoScale, width) {
  if (ask === 0 || isNaN(ask)) {
    return {textAlign: 'right', backgroundColor: ''}
  } else {
    if (isNaN(width)) {
      width = 0.0
    }
    const pushFactor = 0.1
    let push = pushFactor * width
    const askOffset = (ask - push) - theo
    if (askOffset > (-1 * theoThreshold))
      return {textAlign: 'right', backgroundColor: ''}
    const scaledOffset = askOffset / theoThreshold * 0.1
    return {textAlign: 'right', backgroundColor: theoScale(scaledOffset)}
  }
}

let singleValueStyle = function(value, threshold, scaler) {
  if (Math.abs(value) < threshold) {
    return {textAlign: 'right', backgroundColor: ''}
  } else {
    const scaledValue = value / threshold * 0.1
    return {textAlign: 'right', backgroundColor: scaler(scaledValue)}
  }
}

function toNumber(val) {
  if (typeof val === 'number') return val
  if (typeof val === 'string') {
    if (val.trim() === '') return NaN
    else                   return Number(val)
  }
  return NaN
}

function notify(message, color) {
  let notification = document.createElement('div')
  notification.style.backgroundColor = color
  notification.style.position = 'fixed'
  notification.style.left = 'calc(50% - 350px)'
  notification.style.top = 'calc(50% - 100px)'
  notification.style.width = '700px'
  notification.style.height = '200px'

  notification.style.fontSize = '300%'
  notification.style.textAlign = 'center'
  notification.style.lineHeight = notification.style.height
  notification.innerHTML = message

  notification.style.transition = 'opacity 500ms linear'
  notification.style['-webkit-transition'] = 'opacity 500ms linear'

  document.body.appendChild(notification)

  setTimeout(function() {
    notification.style.opacity = 0
    setTimeout(function() {
      notification.parentNode.removeChild(notification)
    }, 500)
  }, 250)
}


export {getCookies, getValue, bidStyle, askStyle, singleValueStyle, toNumber, notify}
