import React, {Component} from 'react'
import { AgGridReact } from 'ag-grid-react'
import PropTypes from 'prop-types'
import chroma from 'chroma-js'

import {getCookies} from './util.jsx'

import {bidStyle, askStyle, singleValueStyle, toNumber, notify} from './util.jsx'
import './StraddleTable.css'

let tableCount = 0

let tradeDiv = undefined

function equal(n1, n2) {
  return Math.abs(n1 - n2) < 0.000000001
}

function less(n1, n2) {
  return (n1 - n2) < -0.000000001
}

class StraddleTable extends Component {
  constructor(props) {
    super(props)

    if (props.back && props.back !== 'stand-alone') this.title = props.back + ' ' + props.title
    else                                            this.title = props.title
    this.strikeDeltaScale = chroma.scale(['#282c34', 'blue', '#282c34']).mode('lab')
    this.adjusterScale = chroma.scale(['red', '#282c34', 'green']).domain([-1.0, 0.0, 1.0]).mode('lrgb')

    this.theoScale = chroma.scale(['red', '#282c34', 'green']).domain([-1.0, 0.0, 1.0]).mode('lrgb')
    this.errorScale = chroma.scale(['#282c34', 'red']).mode('lab')

    this.staticBackgroundColor = '#284434'
    this.expiryScale = chroma.cubehelix().lightness([0.2,0.4])

    this.config = {}
    this.pinRiskExists = {}
    this.adjThreshold = {}
    this.errThreshold = {}
    this.theoThreshold = {}
    this.minDeltaThreshold = {}
    this.maxDeltaThreshold = {}
    this.fixedPriceDisplay = {}

    let cookies = getCookies()
    this.manualOrders = cookies.manualOrders
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyPress)
  }

  componentWillUnmount() {
    this.removeTradeDiv()
    window.removeEventListener('keydown', this.onKeyPress)
  }

  onKeyPress = event => {
    if (event.key === 'Enter') {
      if (tradeDiv) {
        let tradeButton = document.getElementById('place-order-button')
        if (tradeButton) {
          tradeButton.onclick()
        }
      }
    } else if (event.key === 'Escape') {
      this.removeTradeDiv()
    }
  }

  removeTradeDiv() {
    if (tradeDiv !== undefined) {
      tradeDiv.parentNode.removeChild(tradeDiv)
      tradeDiv = undefined
    }
  }

  right = {textAlign: 'right'}
  left = {textAlign: 'left'}

  renderFixed = (nfixed = 3, blankZero = false) => {
    let This = this
    return function(params) {
      let val = params.value
      if (typeof val !== 'number') return val
      if (blankZero && val === 0) return ''
      if (nfixed === -1) {
        let back = params.data.back
        nfixed = This.fixedPriceDisplay[back]
      }
      return val.toFixed(nfixed)
    }
  }

  exponentialFormatter = (colname, nfixed = 3, blankZero = false) => {
    return function(params) {
      let val = params.data[colname]

      if (typeof val !== 'number') return val
      if (blankZero && val === 0) return ''
      return val.toExponential(nfixed)
    }
  }

  strippedNumber = (minFrac = 3, blankZero = false, maxFrac = 3) => {
    let This = this
    return function(params) {
      let val = params.value
      if (typeof val !== 'number') return val
      if (blankZero && val === 0) return ''

      if (minFrac <= -1) {
        let back = params.data.back
        minFrac = This.fixedPriceDisplay[back] - minFrac - 1
        maxFrac = Math.max(maxFrac, minFrac)
      }

      if (Math.abs(val) < (1 / Math.pow(10.0, maxFrac))) {
        const nExp = Math.max(minFrac - 2, 1)
        return val.toExponential(nExp)
      }
      var myObj = { style: 'decimal'
                  , minimumFractionDigits: minFrac
                  , maximumFractionDigits: maxFrac
                  }
      return val.toLocaleString('en-US', myObj)
    }
  }

  sLow = (str) => {
    return str[0].toLowerCase() + str.slice(1)
  }

  creditStyle = () => {
    let This = this
    return function(params) {
      let back = params.data.back
      let credit = params.value
      return singleValueStyle(credit, This.theoThreshold[back], This.theoScale)
    }
  }

  bidStyle = (prefix) => {
    let This = this
    return function(params) {
      let theo = params.data[This.sLow(prefix + 'AdjTheo')]
      let bid = params.data[This.sLow(prefix + 'MarketBidPx')]
      let marketWidth = params.data[This.sLow(prefix + 'MarketWidth')]
      let back = params.data.back

      return bidStyle(theo, bid, This.theoThreshold[back], This.theoScale, marketWidth)
    }
  }

  askStyle = (prefix) => {
    let This = this
    return function(params) {
      let theo = params.data[This.sLow(prefix + 'AdjTheo')]
      let ask = params.data[This.sLow(prefix + 'MarketAskPx')]
      let marketWidth = params.data[This.sLow(prefix + 'MarketWidth')]
      let back = params.data.back

      return askStyle(theo, ask, This.theoThreshold[back], This.theoScale, marketWidth)
    }
  }

  quoteStyle = (prefix, side, isMarket) => {
    let This = this
    return function(params) {
      let ownPx = params.data[This.sLow(prefix + 'Own' + side + 'Px')]
      let ownVolume = params.data[This.sLow(prefix + 'Own' + side + 'Volume')]
      let mktBidPx = params.data[This.sLow(prefix + 'MarketBidPx')]
      let mktBidVolume = params.data[This.sLow(prefix + 'MarketBidVolume')]
      let mktAskPx = params.data[This.sLow(prefix + 'MarketAskPx')]
      let mktAskVolume = params.data[This.sLow(prefix + 'MarketAskVolume')]
      let marketWidth = params.data[This.sLow(prefix + 'MarketWidth')]
      let theo = params.data[This.sLow(prefix + 'AdjTheo')]
      let back = params.data.back


      let backgroundColor = ''
      let ownValid = ownVolume > 0

      if (ownValid) {
        let mktPx = 0
        let mktVolume = 0
        let isInside = false
        let throughTheo = false
        if (side === 'Bid') {
          mktPx = mktBidPx
          mktVolume = mktBidVolume
          isInside = mktVolume === 0 || less(mktPx, ownPx)
          throughTheo = mktVolume > 0 && less(theo, mktPx)
        } else if (side === 'Ask') {
          mktPx = mktAskPx
          mktVolume = mktAskVolume
          isInside = mktVolume === 0 || less(ownPx, mktPx)
          throughTheo = mktVolume > 0 && less(mktPx, theo)
        }
        if (isInside) {
          backgroundColor = '#37387b'
        } else if (equal(ownPx, mktPx)) {
          if (ownVolume >= mktVolume) {
            backgroundColor = '#37387b'
          } else {
            backgroundColor = '#375a7b'
          }
        } else if (!throughTheo) {
          backgroundColor = '#6c8093'
        }
      }

      // Own quotes take precedence for market highlighting.
      if (isMarket && backgroundColor === '') {
        if (side === 'Bid') {
          return bidStyle(theo, mktBidPx, This.theoThreshold[back], This.theoScale, marketWidth)
        } else if (side === 'Ask') {
          return askStyle(theo, mktAskPx, This.theoThreshold[back], This.theoScale, marketWidth)
        }
      } else {
        return {textAlign: 'right', backgroundColor: backgroundColor}
      }
    }
  }

  getCredit = (prefix) => {
    let This = this
    return function(params) {
      let theo = params.data[This.sLow(prefix + 'AdjTheo')]
      let bid = params.data[This.sLow(prefix + 'MarketBidPx')]
      let ask = params.data[This.sLow(prefix + 'MarketAskPx')]
      let credit = 0
      if (bid !== 0) {
        credit = Math.max(credit, bid - theo)
      }
      if (ask !== 0) {
        credit = Math.max(credit, theo - ask)
      }
      return credit
    }
  }

  renderEnabled = (prefix) => {
    let This = this
    return function(params) {
      return params.data[This.sLow(prefix + 'BuyEnabled')] + ':'
        + params.data[This.sLow(prefix + 'SellEnabled')] + ':'
        + params.data[This.sLow(prefix + 'QuoteBuyEnabled')] + ':'
        + params.data[This.sLow(prefix + 'QuoteSellEnabled')] + ':'
        + params.data[This.sLow(prefix + 'Flatten')] + ':'
        + params.data[This.sLow(prefix + 'AdjustFlatten')]
    }
  }

  percentFormatter = (params) => {
    let val = params.value
    val = (val * 100).toFixed(0)
    return val + '%'
  }

  positionStyle = (field, flattenField, isCombined = false) => {
    let This = this

    return function(params) {
      let position = (typeof field === 'function') ? field(params) : params.data[field]

      if (  params.data[flattenField] === 1
         && (  (This.pinRiskExists[params.data.back] && !isCombined)
            || (!This.pinRiskExists[params.data.back] && isCombined)) ) {
        if (position !== 0)
          return {textAlign: 'right', backgroundColor: 'blue'}
      }

      return {textAlign: 'right', backgroundColor: ''}
    }
  }

  enableStyle = (adjustflattenField) => {
    return function(params) {
      if (params.data[adjustflattenField] === 1) {
        return {textAlign: 'right', backgroundColor: 'blue'}
      }
      return {textAlign: 'right', backgroundColor: ''}
    }
  }

  adjustmentStyle = (field) => {
    let This = this
    return function(params) {
      const adjustment = params.data[field]
      const adjThreshold = This.adjThreshold[params.data.back]
      return singleValueStyle(adjustment, adjThreshold, This.adjusterScale)
    }
  }

  errStyle = (field) => {
    let This = this
    return function(params) {
      const error = params.data[field]
      const errThreshold = This.errThreshold[params.data.back]
      return singleValueStyle(error, errThreshold, This.errorScale)
    }
  }

  strikeStyle = (params) => {
    if (!params.data.callDelta) return {textAlign: 'center', backgroundColor: ''}
    return {textAlign: 'center', backgroundColor: this.strikeDeltaScale(params.data.callDelta)}
  }

  expiryStyle = (params) => {
    if (!params.data.expiration || !this.minExpiry || !this.maxExpiry || this.minExpiry === this.maxExpiry)
      return {textAlign: 'right', backgroundColor: this.staticBackgroundColor}

    let norm = (params.data.expiration - this.minExpiry) / (this.maxExpiry - this.minExpiry)
    return {textAlign: 'right', backgroundColor: this.expiryScale(norm)}
  }

  adjFracOfCapStyle = (prefix) => {
    let This = this
    return function(params) {
      let adjFractionOfCap = params.data[This.sLow(prefix + 'AdjFractionOfCap')]
      if (adjFractionOfCap < 0.1) return {textAlign: 'right', backgroundColor: ''}
      let norm = adjFractionOfCap * 0.25
      return {textAlign: 'right', backgroundColor: This.errorScale(norm)}
    }
  }

  marketStatusStyle = (prefix) => {
    let This = this
    return function(params) {
      let marketStatus = params.data[This.sLow(prefix + 'MarketStatus')]
      if (marketStatus === 3) {
        return { textAlign: 'right', backgroundColor: '' }
      } else if (marketStatus === 1 || marketStatus === 2) {
        return { textAlign: 'right', backgroundColor: '#cc6600' }
      } else {
        return { textAlign: 'right', backgroundColor: '#8b0000' }
      }
    }
  }

  defaultRowFilter = (params) => {
    if (this.state.showAllRows) return true

    let d = params.data
    let back = d.back

    return (  (d.callAdjTheo >= d.callMarketAskPx && d.callMarketAskPx !== 0 && d.callVtexp !== 0)
           || (d.callAdjTheo <= d.callMarketBidPx && d.callMarketBidPx !== 0 && d.callVtexp !== 0)
           || (d.callDelta < this.maxDeltaThreshold[back] && d.callDelta > this.minDeltaThreshold[back])
           || (Math.abs(d.callAdjustment) > this.adjThreshold[back])
           || (d.putAdjTheo >= d.putMarketAskPx && d.putMarketAskPx !== 0 && d.putVtexp !== 0)
           || (d.putAdjTheo <= d.putMarketBidPx && d.putMarketBidPx !== 0 && d.putVtexp !== 0)
           || (Math.abs(d.putAdjustment) > this.adjThreshold[back])
           || d.callFlatten || d.putFlatten )
  }

  throughMarketFilter = (params) => {
    let d = params.data
    return ( ((d.callAdjTheo >= d.callMarketAskPx && d.callMarketAskPx !== 0)
           || (d.callAdjTheo <= d.callMarketBidPx && d.callMarketBidPx !== 0)
           || (d.putAdjTheo >= d.putMarketAskPx && d.putMarketAskPx !== 0)
           || (d.putAdjTheo <= d.putMarketBidPx && d.putMarketBidPx !== 0)
           || (d.callAdjustFlatten || d.putAdjustFlatten))
           && (d.callVtexp !== 0) )
  }

  comboDefaultRowFilter = () => {
    return true
  }

  comboThroughMarketFilter = (params) => {
    let d = params.data
    return ( ((d.adjTheo >= d.marketAskPx && d.marketAskPx !== 0)
           || (d.adjTheo <= d.marketBidPx && d.marketBidPx !== 0)
           || (d.adjustFlatten))
           && (d.vtexp !== 0) )
        
  }


  gengrid = () => {
    tableCount += 1
    if (this.props.autoheight) {
      this.grid =
        <AgGridReact key={tableCount}
                     columnDefs={this.state.columnDefs}
                     defaultColDef={this.state.defaultColDef}
                     rowSelection='single'
                     rowDeselection={true}
                     suppressCellSelection={true}
                     alwaysShowVerticalScroll={true}
                     suppressHorizontalScroll={true}
                     getRowId={this.getRowId}
                     isExternalFilterPresent={this.isExternalFilterPresent}
                     doesExternalFilterPass={this.state.filter}
                     getRowStyle={this.getRowStyle}
                     rowHeight={20}
                     domLayout="autoHeight"
                     onCellDoubleClicked={this.onCellDoubleClicked}
                     onGridReady={this.onGridReady}>
        </AgGridReact>
    } else {
      this.grid =
        <AgGridReact key={tableCount}
                     columnDefs={this.state.columnDefs}
                     defaultColDef={this.state.defaultColDef}
                     rowSelection='single'
                     rowDeselection={true}
                     suppressCellSelection={true}
                     alwaysShowVerticalScroll={true}
                     suppressHorizontalScroll={true}
                     getRowId={this.getRowId}
                     isExternalFilterPresent={this.isExternalFilterPresent}
                     doesExternalFilterPass={this.state.filter}
                     getRowStyle={this.getRowStyle}
                     rowHeight={20}
                     onCellDoubleClicked={this.onCellDoubleClicked}
                     onGridReady={this.onGridReady}>
        </AgGridReact>
    }
  }

  getRowStyle = () => {
    return {backgroundColor: '#262c2e'}
  }

  updateConfig = (back, config) => {
    this.config[back] = config
    this.pinRiskExists[back] = false
    this.adjThreshold[back] = 0.01
    this.errThreshold[back] = 0.01
    this.theoThreshold[back] = 0.01
    this.minDeltaThreshold[back] = 0.02
    this.maxDeltaThreshold[back] = 0.98
    this.fixedPriceDisplay[back] = 2

    if (typeof config === 'object') {
      if (typeof config.strategies === 'object') {
        let strategy = config.strategies[0]
        if (typeof strategy === 'object') {
          if (strategy.pinRiskExists !== undefined) this.pinRiskExists[back] = strategy.pinRiskExists
          if (strategy.adjThreshold !== undefined) this.adjThreshold[back] = strategy.adjThreshold
          if (strategy.errThreshold !== undefined) this.errThreshold[back] = strategy.errThreshold
          if (strategy.theoThreshold !== undefined) this.theoThreshold[back] = strategy.theoThreshold
          if (strategy.minDeltaThreshold !== undefined) this.minDeltaThreshold[back] = strategy.minDeltaThreshold
          if (strategy.maxDeltaThreshold !== undefined) this.maxDeltaThreshold[back] = strategy.maxDeltaThreshold
          if (strategy.fixedPriceDisplay !== undefined) this.fixedPriceDisplay[back] = strategy.fixedPriceDisplay
        }
      }
    }
  }

  onCellDoubleClicked = params => {
    if (!this.manualOrders) return

    let px, vol, side, sid, display
    if (params.colDef.field === 'marketBidPx') {
      display = params.data.display
      sid = params.data.securityId
      px = params.data.marketBidPx
      vol = params.data.marketBidVolume
      side = 'SELL'
    } else if (params.colDef.field === 'marketAskPx') {
      display = params.data.display
      sid = params.data.securityId
      px = params.data.marketAskPx
      vol = params.data.marketAskVolume
      side = 'BUY'
    } else if (params.colDef.field === 'callMarketBidPx') {
      display = params.data.callSymbol
      sid = params.data.callSecurityId
      px = params.data.callMarketBidPx
      vol = params.data.callMarketBidVolume
      side = 'SELL'
    } else if (params.colDef.field === 'putMarketBidPx') {
      display = params.data.putSymbol
      sid = params.data.putSecurityId
      px = params.data.putMarketBidPx
      vol = params.data.putMarketBidVolume
      side = 'SELL'
    } else if (params.colDef.field === 'callMarketAskPx') {
      display = params.data.callSymbol
      sid = params.data.callSecurityId
      px = params.data.callMarketAskPx
      vol = params.data.callMarketAskVolume
      side = 'BUY'
    } else if (params.colDef.field === 'putMarketAskPx') {
      display = params.data.putSymbol
      sid = params.data.putSecurityId
      px = params.data.putMarketAskPx
      vol = params.data.putMarketAskVolume
      side = 'BUY'
    } else {
      return
    }

    display = display.replace(/\0/g, '') // trim trailing nulls
    console.log('initiating order popup for display :' + display + ':')
    this.tradePopup(params.event, params.data.back, display, sid, px, vol, side)
  }

  tradePopup = (event, back, display, sid, sidePx, sideVol, side) => {
    this.removeTradeDiv()
    tradeDiv = document.createElement('div')

    let maxx = window.innerWidth - 700
    let tdx = event.clientX - 10
    if (tdx > maxx) tdx = maxx

    let tdy = event.clientY + 20
    let maxy = window.innerHeight - 135
    if (tdy > maxy) tdy = maxy

    tradeDiv.style.backgroundColor = 'white'
    tradeDiv.style.position = 'fixed'
    tradeDiv.style.left = tdx + 'px'
    tradeDiv.style.top = tdy + 'px'

    let cid = 'trade-popup-comp-' + sid
    let qtyLabel = document.createElement('label')
    qtyLabel.innerHTML = display /* + ' sid:' + sid */ +  ' Qty'
    qtyLabel.for = cid

    let qty = document.createElement('input')
    qty.id = cid
    qty.size = 5
    qty.type = 'text'
    qty.style.margin = '5px'
    qty.style.marginRight = '30px'
    qty.value = Math.min(sideVol, 5)

    let pxLabel = document.createElement('label')
    pxLabel.innerHTML = 'Px'
    pxLabel.for = cid + '-px'

    let px = document.createElement('input')
    px.id = cid + '-px'
    px.size = 5
    px.type = 'text'
    px.style.margin = '5px'
    px.style.marginRight = '30px'
    px.value = sidePx

    let marketPxLabel = document.createElement('label')
    marketPxLabel.innerHTML = 'Market'
    marketPxLabel.for = cid + '-mpx'

    let marketPx = document.createElement('input')
    marketPx.id = cid + '-mpx'
    marketPx.size = 5
    marketPx.type = 'text'
    marketPx.style.margin = '5px'
    marketPx.style.marginRight = '30px'
    marketPx.value = sidePx
    marketPx.readOnly = true

    {
      let cdiv = document.createElement('div')
      cdiv.style.float = 'left'

      let qdiv = document.createElement('div')
      qdiv.style.float = 'right'
      qdiv.appendChild(qtyLabel)
      qdiv.appendChild(qty)

      let pdiv = document.createElement('div')

      {
        let ediv = document.createElement('div')
        let idiv = document.createElement('div')
        idiv.style.float = 'right'
        idiv.appendChild(pxLabel)
        idiv.appendChild(px)
        ediv.appendChild(idiv)
        pdiv.appendChild(ediv)
      }

      {
        let mdiv = document.createElement('div')
        let idiv = document.createElement('div')
        idiv.style.float = 'right'
        idiv.appendChild(marketPxLabel)
        idiv.appendChild(marketPx)
        mdiv.appendChild(idiv)
        pdiv.appendChild(mdiv)
      }


      cdiv.appendChild(qdiv)
      cdiv.appendChild(pdiv)
      tradeDiv.appendChild(cdiv)
    }

    let buy = document.createElement('input')
    buy.id = 'trade-popup-buy'
    buy.type = 'radio'
    buy.name = 'side'
    buy.value = 'buy'
    buy.checked = side === 'BUY'

    let buyLabel = document.createElement('label')
    buyLabel.innerHTML = 'Buy'
    buyLabel.for = 'trade-popup-buy'

    let sell = document.createElement('input')
    sell.id = 'trade-popup-sell'
    sell.type = 'radio'
    sell.name = 'side'
    sell.value = 'sell'
    sell.checked = side === 'SELL'

    let sellLabel = document.createElement('label')
    sellLabel.innerHTML = 'Sell'
    sellLabel.for = 'trade-popup-sell'
    sellLabel.style.marginRight = '30px'

    let account = document.createElement('input')
    account.type = 'text'
    account.size = 5
    account.style.margin = '5px'
    account.style.marginTop = '10px'
    account.id = 'trade-popup-account'

    {
      account.value = 101
      let config = this.ldMap[back].config
      if (config && config.manualTrade && config.manualTrade.account) {
        account.value = config.manualTrade.account
      }
    }

    let accountLabel = document.createElement('label')
    accountLabel.innerHTML = 'Account'
    accountLabel.for = 'trade-popup-account'
    accountLabel.style.padding = '5px'

    let client = document.createElement('input')
    client.type = 'text'
    client.size = 5
    client.style.margin = '5px'
    client.id = 'trade-popup-service'

    {
      let config = this.ldMap[back].config

      if (config && config.manualTrade && config.manualTrade.clientMap) {
        config.manualTrade.clientMap.some(pair => {
          if (display.match(pair.regex)) {
            client.value = pair.client_id
            return true
          }
          return false
        })
      }
    }

    let clientLabel = document.createElement('label')
    clientLabel.innerHTML = 'Client ID'
    clientLabel.for = 'trade-popup-service'
    clientLabel.style.padding = '5px'

    {
      let idiv = document.createElement('div')
      idiv.appendChild(buy)
      idiv.appendChild(buyLabel)
      idiv.appendChild(sell)
      idiv.appendChild(sellLabel)
      idiv.style.float = 'left'
      idiv.style.padding = '5px'

      let iidiv = document.createElement('div')

      let adiv = document.createElement('div')
      adiv.style.float = 'right'
      adiv.appendChild(accountLabel)
      adiv.appendChild(account)

      let sdiv = document.createElement('div')
      sdiv.appendChild(clientLabel)
      sdiv.appendChild(client)

      iidiv.appendChild(adiv)
      iidiv.appendChild(sdiv)
      idiv.appendChild(iidiv)
      tradeDiv.appendChild(idiv)
    }

    let overrideLabel = document.createElement('label')
    overrideLabel.for = cid + '-override'
    overrideLabel.innerHTML = 'Safety Override'

    let override = document.createElement('input')
    override.id = cid + '-override'
    override.type = 'checkbox'
    override.checked = false

    {
      let trade = document.createElement('button')
      trade.id = 'place-order-button'
      trade.innerHTML = 'Place IOC'
      trade.style.margin = '5px'
      trade.onclick = () => {
        this.removeTradeDiv()

        if (toNumber(account.value) && toNumber(client.value)) {
          let order = { account: toNumber(account.value)
                      , side: buy.checked ? 'BUY' : 'SELL'
                      , qty: toNumber(qty.value)
                      , px: toNumber(px.value)
                      , tif: 'IOC'
                      , clientId: toNumber(client.value)
                      , symbol: display
                      , safetyOverride: override.checked
                      }

          console.log('will place order ' + JSON.stringify(order))
          this.ldMap[back].POST('/api/send-order', order)
          notify('Order Sent', 'green')
        } else {
          notify('Invalid order not sent', 'red')
        }
      }

      let cancel = document.createElement('button')
      cancel.innerHTML = 'Cancel'
      cancel.style.margin = '5px'
      cancel.onclick = () => {
        tradeDiv.parentNode.removeChild(tradeDiv)
        tradeDiv = undefined
        notify('Trade request canceled', 'yellow')
      }

      let bdiv = document.createElement('div')
      let odiv = document.createElement('div')

      bdiv.style.float = 'right'
      bdiv.appendChild(trade)
      bdiv.appendChild(cancel)

      odiv.appendChild(override)
      odiv.appendChild(overrideLabel)

      tradeDiv.appendChild(bdiv)
      tradeDiv.appendChild(odiv)
    }

    document.body.appendChild(tradeDiv)
  }

  redraw = (columns) => {
    // Bid/Ask columns need to be periodically redrawn, because their background depends on
    // current theo, and those cells will only be rerendered if their value (the bid or ask)
    // actually changes.
    if (this.api !== undefined && !this.api.isDestroyed()) {
      this.api.refreshCells({force:true, columns:columns})
    }
    setTimeout(() => {this.redraw(columns)}, 1000)
  }

  renderModeRadio = () => {
    return null
  }

  renderAllContracts = () => {
    return null
  }

  render() {
    let gridStyle = { minWidth: '900px' 
                    , width: 'calc(100% - 50px)'
                    } 

    if (this.height) gridStyle.height = this.height
    
    return (
      <div className="straddle-view">
      
        <div style={{ fontSize: '14px', textAlign: 'left'
                    , width: 'calc(100% - 50px)', minWidth: '900px'
                    , marginTop: '10px'}}>
          {this.title + ' (valuationId = ' + this.state.valuationId + ')'}
        </div>
        
        <div className="guif-div">
          <div style={{float: 'left', width: 'calc(100% - 50px)', display: 'flex', alignItems: 'center'}}>
            <input className="guif-input" type="text" size="20" placeholder="filter"
                   onChange={this.quickFilterOnInput}>
            </input>

            {this.renderModeRadio()}

            {this.renderAllContracts()}
          </div>
          <div className="ag-theme-balham-dark straddle-table"
               style={gridStyle}>
            {this.grid}
          </div>
        </div>
      </div>
    ) 
  }
}

StraddleTable.propTypes = 
  { title: PropTypes.string
  , back: PropTypes.string
  , autoheight: PropTypes.bool
  }

export default StraddleTable
