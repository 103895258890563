import React from 'react'
import {UpdatedTable, FilteredUpdatedTable} from './UpdatedTable'
import MuteRenderer from './MuteRenderer'

class JobTable extends UpdatedTable {
  constructor(props) {
    super(props)

    let statusStyle = function(params) {
      if (params.data.status === 'OK') {
        return { textAlign: 'center'
               , 'background-color': 'green'
               }
      }

      if (params.data.status === 'LATE' || params.data.status === 'FAILED') {
        return { textAlign: 'center'
               , 'background-color': 'red'
               }
      }

      return { textAlign: 'center', 'background-color': '' }
    }

    let center = { textAlign: 'center' }

    this.state =
      { defaultColDef: { resizable: true
                       , sortable: true
                       , cellStyle: this.right
                       }
      , columnDefs: [ { width: 50
                      , headerName: 'Back'
                      , field: 'back'
                      , valueFormatter: this.backFormatter('back')
                      }
                    , { width: 80
                      , headerName: 'Status'
                      , field: 'status'
                      , cellStyle: statusStyle
                      }
                    , { width: 65
                      , headerName: 'Strategy'
                      , field: 'strategy'
                      }
                    , { width: 220
                      , headerName: 'Job Name'
                      , field: 'jobName'
                      , cellStyle: this.left
                      }
                    , { width: 160
                      , headerName: 'Last Updated'
                      , field: 'lastUpdateTime'
                      , valueFormatter: this.timeFormatter('lastUpdateTime')
                      }
                    , { width: 80
                      , headerName: 'requiredBy'
                      , field: 'requiredBy'
                      }
                    , { width: 80
                      , headerName: 'ID'
                      , field: 'jobID'
                      }
                    , { width: 70
                      , headerName: 'Mute'
                      , field: 'mute'
                      , cellStyle: center
                      , cellRenderer: MuteRenderer
                      , cellRendererParams: { muteComponent: this.ldMap
                                            , appField: 'jobmute'
                                            , idField: 'jobID'
                                            , multiGui: true
                                            }
                      }
                    ]
      , details: ''
      }

    this.redraw(['status'])
    if (this.props.autoHeight) this.gengridAutoHeight()
    else                       this.gengridFixedHeight()
  }

  getRowId = (params) => {
    return `${params.data.jobID}`
  }

  onGridReadyDerived = () => {
    let sortModel = [ {colId: 'back', sort: 'asc'}, {colId: 'requiredBy', sort: 'asc'} ]
    this.api.applyColumnState({state: sortModel})
  }

  onCellClickedDerived = params => {
    if (!this.props.gridOnly) {
      params.node.setSelected(true, true)
      let details = params.data.details
      if (!details) details = ''
      this.setState({details: details})
    }
  }

  render() {
    if (this.api) this.api.onFilterChanged()
    let gridStyle = { width: this.totalWidth(true)
                    , height: 'calc(65vh - 50px)'
                    }

    let textStyle = { height: 'calc(35vh - 150px)'
                    , width: '100%'
                    , background: '#282c34'
                    , color: 'white'
                    }

    if (this.props.autoHeight)  {
      delete textStyle['height']
      delete gridStyle['height']
    }

    return (
      <div className="guif-div">
        <div className="ag-theme-balham-dark broker-markets"
             style={gridStyle}>
          {this.grid}
        </div>

        { !this.props.gridOnly &&
          <div style={{textAlign: 'left', width: '90%'}}>
            <label style={{marginTop: '15px', fontSize: '14px'}}>Full Details</label>
            <textarea style={textStyle} readOnly={true} value={this.state.details}>
            </textarea>
          </div>
        }
      </div>
    )
  }
}

class FilteredJobTable extends FilteredUpdatedTable {
  renderUpdatedTable(dataStore, backFilter, messagename) {
    return (
      <JobTable dataStore={dataStore}
                backFilter={backFilter}
                extraFilters={this.props.extraFilters}
                gridOnly={this.props.gridOnly}
                autoHeight={this.props.autoHeight}
                messagename={messagename}/>
    )
  }
}

export {JobTable, FilteredJobTable}
