import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ParameterQuickSet extends Component {
  constructor(props) {
    super(props)

    if (props.pctr) {
      this.pctr = props.pctr
      this.ldList = [props.pctr.listenerData]
      this.ldMap = {}
      this.ldMap[this.pctr.back] = props.pctr.listenerData
      this.listenerData = props.pctr.listenerData
    } else {
      this.dataStore = props.dataStore
      this.ldList = this.dataStore.ldList
      this.ldMap = {}
      this.ldList.forEach(ld => {
        this.ldMap[ld.back] = ld
      })
    }

    this.messageName = props.messagename
    this.updateListeners = {}
    this.rowData = {}

    this.backs = []
    this.state = {}
    this.state['parametrizer'] = {}
    for (let back in this.ldMap) {
      this.rowData[back] = {}
      this.updateListeners[back] = data => this.update(data, back)

      this.backs.push(back)
      this.state.parametrizer[back] = {}
    }
  }

  componentWillUnmount() {
    for (let back in this.ldMap) {
      this.ldMap[back].removeListener(this.messageName, this.updateListeners[back])
    }

    if (this.componentWillUnmountDerived) this.componentWillUnmountDerived()
  }

  componentDidMount() {
    for (let back in this.ldMap) {
      this.update(this.ldMap[back][this.messageName], back, false)
    }

    for (let back in this.ldMap) {
      this.ldMap[back].addListener(this.messageName, this.updateListeners[back])
    }
  }

  renderDVE = back => {
    let displayBack = back
    if (back === 'stand-alone') displayBack = ''

    let dveBuyId = 'dve-quick-set buy-factor ' + back
    let dveSellId = 'dve-quick-set sell-factor ' + back

    let singleChanges = {}

    let buyColor = 'black'
    let buyElement = document.getElementById(dveBuyId)
    if (buyElement) {
      if (buyElement.value && Number(buyElement.value) !== this.state.parametrizer[back].dve_buy_factor) {
        buyColor = 'blue'
        singleChanges['dve_buy_factor'] = Number(buyElement.value)
      }
    }

    let sellColor = 'black'
    let sellElement = document.getElementById(dveSellId)
    if (sellElement) {
      if (sellElement.value && Number(sellElement.value) !== this.state.parametrizer[back].dve_sell_factor) {
        sellColor = 'blue'
        singleChanges['dve_sell_factor'] = Number(sellElement.value)
      }
    }

    let revert = () => {
      if (buyElement) buyElement.value = this.state.parametrizer[back].dve_buy_factor
      if (sellElement) sellElement.value = this.state.parametrizer[back].dve_sell_factor
    }

    let set = () => {
      this.ldMap[back].POST('/parameters/singles', singleChanges)
    }

    return (
      <div key={'dve-quick-set' + back} style={{display: 'table-row', fontSize: '12px'}}>
        <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
          <button style={{marginRight: '15px', width: '90px'}} onClick={set}> Set {displayBack} </button>
        </div>

        <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
          <input type="number" size="4" step={1.0} min={1} id={dveBuyId}
                 defaultValue={this.state.parametrizer[back].dve_buy_factor} name={dveBuyId}
                 style={{width: '100px', float: 'right', height:'100%', color:buyColor}}/>
        </div>

        <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
          <input type="number" size="4" step={1.0} min={1} id={dveSellId}
                 defaultValue={this.state.parametrizer[back].dve_sell_factor} name={dveSellId}
                 style={{width: '100px', float: 'right', height:'100%', color:sellColor}}/>
        </div>

        <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
          <button style={{marginLeft: '15px', width: '130px'}} onClick={revert} > Revert </button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div style={{border: '1px solid #555555', padding: '10px'}}>
        <div style={{fontSize: '14px', marginBottom: '10px'}}>Parameter Quick Set</div>
        <div style={{display: 'table'}}>
          <div key={'dve-quick-set-header'} style={{display: 'table-row', fontSize: '12px'}}>
            <div style={{display: 'table-cell', verticalAlign: 'middle'}}>

            </div>
            <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
              DVE Buy Factor
            </div>
            <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
              DVE Sell Factor
            </div>
          </div>
          {this.backs.map(this.renderDVE)}
        </div>
      </div>
    )
  }

  update = (rows, back) => {
    if (rows && rows.parametrizer) {
      let parametrizer = this.state.parametrizer
      parametrizer[back] = rows.parametrizer

      this.setState({parametrizer: parametrizer})

      this.render()
    }
  }
}

ParameterQuickSet.propTypes =
  { dataStore: PropTypes.object
  , pctr: PropTypes.object
  , messagename: PropTypes.string
  }

export default ParameterQuickSet
