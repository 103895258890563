import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { AgGridReact } from 'ag-grid-react'
import moment from 'moment'

import {getValue} from './util.jsx'
import './App.css'


class UpdatedTable extends Component {
  constructor(props) {
    super(props)

    if (props.pctr) {
      this.pctr = props.pctr
      this.ldList = [props.pctr.listenerData]
      this.ldMap = {}
      this.ldMap[this.pctr.back] = props.pctr.listenerData
      this.listenerData = props.pctr.listenerData
    } else {
      this.dataStore = props.dataStore
      this.ldList = this.dataStore.ldList
      this.ldMap = {}
      this.ldList.forEach(ld => {
        this.ldMap[ld.back] = ld
      })
    }

    this.messageName = props.messagename
    this.updateListeners = {}
    this.rowData = {}

    for (let back in this.ldMap) {
      this.rowData[back] = {}
      this.updateListeners[back] = data => this.update(data, back)
    }

  }

  right = {textAlign: 'right', backgroundColor: ''}
  left = {textAlign: 'left', backgroundColor: ''}
  center = {textAlign: 'center', backgroundColor: ''}

  backFormatter = colname => {
    return function(params) {
      let val = getValue(params.data, colname)
      if (val !== 'stand-alone') return val
      else                       return ''
    }
  }

  fixedFormatter = (colname, nfixed = 3, blankZero = false) => {
    return function(params) {
      let val = getValue(params.data, colname)

      if (typeof val !== 'number') return val
      if (blankZero && val === 0) return ''
      return val.toFixed(nfixed)
    }
  }

  exponentialFormatter = (colname, nfixed = 3, blankZero = false) => {
    return function(params) {
      let val = getValue(params.data, colname)

      if (typeof val !== 'number') return val
      if (blankZero && val === 0) return ''
      return val.toExponential(nfixed)
    }
  }

  percentFormatter = colname => {
    return function(params) {
      let val = getValue(params.data, colname)
      val = (val * 100).toFixed(2).replace(/\.?0+$/, '')
      return val + '%'
    }
  }

  timeFormatter = timeKey => {
    return function(params) {
      let time = getValue(params.data, timeKey)
      if (time === undefined) return time

      return moment(time).format('YYYY/MM/DD hh:mm:ss')
    }
  }

  currencyFormatter = colname => {
    let formatNumber = function(number) {
      return Math
      .floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    return function(params) {
      let value = Number(getValue(params.data, colname))
      let string = '\x24' + formatNumber(Math.abs(value))

      if (value < 0) string = '-' + string
      return string
    }
  }

  signedFixedFormatter = (field, nfixed = 3, blankZero = false) => {
    return function(params) {
      let val = (typeof field === 'function') ? field(params) : params.data[field]

      if (typeof val !== 'number') return val
      if (blankZero && val === 0) return ''

      let sign = ''
      if (val > 0.0) sign = '+'

      if (Math.abs(val) < (1 / Math.pow(10.0, nfixed))) {
        const nExp = Math.max(nfixed - 2, 1)
        return sign + val.toExponential(nExp)
      }
      return sign + val.toFixed(nfixed)
    }
  }

  strippedNumber = (colname, blankZero = false, minFrac = 2, maxFrac = 10) => {
    return function(params) {
      let val = getValue(params.data, colname)
      if (typeof val !== 'number') return val
      if (blankZero && val === 0) return ''
      if (Math.abs(val) < (1 / Math.pow(10.0, maxFrac))) {
        const nExp = Math.max(minFrac - 2, 1)
        return val.toExponential(nExp)
      }
      var myObj = { style: 'decimal'
                  , minimumFractionDigits: minFrac
                  , maximumFractionDigits: maxFrac
                  }
      return val.toLocaleString('en-US', myObj)
    }
  }

  update = (rows, back, batchUpdate = true) => {
    if (!rows) return

    let adds = []
    let updates = []
    let rowMap = {}

    rows.forEach(row => {
      row.back = back
      let key = this.getRowId({data: row})
      rowMap[key] = row

      if (key in this.rowData[back]) updates.push(row)
      else                           adds.push(row)

      this.rowData[back][key] = row
    })

    let removes = []
    for (let key in this.rowData[back]) {
      if (!(key in rowMap)) {
        removes.push(this.rowData[back][key])
        delete this.rowData[back][key]
      }
    }

    if (this.api !== undefined && !this.api.isDestroyed()) {
      if (batchUpdate) {
        this.api.applyTransactionAsync({
          add: adds
          , update: updates
          , remove: removes
        })
      }
      else {
        this.api.applyTransaction({
          add: adds
          , update: updates
          , remove: removes
        })
      }
    }
  }

  componentWillUnmount() {
    for (let back in this.ldMap) {
      this.ldMap[back].removeListener(this.messageName, this.updateListeners[back])
    }

    if (this.componentWillUnmountDerived) this.componentWillUnmountDerived()
  }

  totalWidth(addScrollbar = false) {
    let totalWidth = 2 // pixel on each side
    this.state.columnDefs.forEach(col => {if (col.width) totalWidth += col.width})
    if (totalWidth > window.innerWidth - 50) totalWidth = window.innerWidth - 50

    if (addScrollbar) totalWidth += 17

    return totalWidth + 'px'
  }


  onGridReady = params => {
    this.api = params.api
    for (let back in this.ldMap) {
      this.update(this.ldMap[back][this.messageName], back, false)
    }

    for (let back in this.ldMap) {
      this.ldMap[back].addListener(this.messageName, this.updateListeners[back])
    }

    if (this.onGridReadyDerived) this.onGridReadyDerived(params)
  }

  onCellClicked = params => {
    if (this.onCellClickedDerived) this.onCellClickedDerived(params)
  }

  onCellFocused = params => {
    if (this.onCellFocusedDerived) this.onCellFocusedDerived(params)
  }

  gengrid = () => {
    this.grid = (
      <AgGridReact defaultColDef={this.state.defaultColDef}
                   columnDefs={this.state.columnDefs}
                   suppressHorizontalScroll={true}
                   suppressRowClickSelection={true}
                   suppressCellSelection={true}
                   getRowId={this.getRowId}
                   isExternalFilterPresent={this.isExternalFilterPresent}
                   doesExternalFilterPass={this.doesExternalFilterPass}
                   getRowStyle={this.getRowStyle}
                   domLayout="autoHeight"
                   onGridReady={this.onGridReady}>
      </AgGridReact>
    )
  }

  gengridFixedHeight = () => {
    this.grid = (
      <AgGridReact defaultColDef={this.state.defaultColDef}
                   columnDefs={this.state.columnDefs}
                   suppressHorizontalScroll={true}
                   suppressRowClickSelection={true}
                   suppressCellSelection={true}
                   getRowId={this.getRowId}
                   isExternalFilterPresent={this.isExternalFilterPresent}
                   doesExternalFilterPass={this.doesExternalFilterPass}
                   onCellClicked={this.onCellClicked}
                   getRowStyle={this.getRowStyle}
                   rowSelection="single"
                   onGridReady={this.onGridReady}>
      </AgGridReact>
    )
  }

  gengridAutoHeight = () => {
    this.grid = (
      <AgGridReact defaultColDef={this.state.defaultColDef}
                   columnDefs={this.state.columnDefs}
                   suppressHorizontalScroll={true}
                   suppressRowClickSelection={true}
                   suppressCellSelection={true}
                   getRowId={this.getRowId}
                   isExternalFilterPresent={this.isExternalFilterPresent}
                   doesExternalFilterPass={this.doesExternalFilterPass}
                   onCellClicked={this.onCellClicked}
                   getRowStyle={this.getRowStyle}
                   rowSelection="single"
                   domLayout="autoHeight"
                   onGridReady={this.onGridReady}>
      </AgGridReact>
    )
  }

  genselectablegrid = () => {
    this.grid = (
      <AgGridReact defaultColDef={this.state.defaultColDef}
                   columnDefs={this.state.columnDefs}
                   suppressHorizontalScroll={true}
                   getRowId={this.getRowId}
                   isExternalFilterPresent={this.isExternalFilterPresent}
                   doesExternalFilterPass={this.doesExternalFilterPass}
                   getRowStyle={this.getRowStyle}
                   onCellClicked={this.onCellClicked}
                   onCellFocused={this.onCellFocused}
                   domLayout="autoHeight"
                   onGridReady={this.onGridReady}>
      </AgGridReact>
    )
  }


  getRowStyle = () => {
    return {backgroundColor: '#262c2e'}
  }

  redraw = (columns) => {
    if (this.api !== undefined && !this.api.isDestroyed()) {
      this.api.refreshCells({force:true, columns:columns})
    }
    setTimeout(() => {this.redraw(columns)}, 1000)
  }

  isExternalFilterPresent = () => {
    if (this.props.backFilter && this.props.backFilter !== '') return true
    else if (this.props.extraFilters)                          return true
    else                                                       return false
  }

  doesExternalFilterPass = params => {
    if (!this.props.extraFilters) {
      return params.data.back.match(this.props.backFilter)
    }

    let match = this.props.backFilter ? params.data.back.match(this.props.backFilter) : true
    this.props.extraFilters.forEach(filter => {
      if (!params.data[filter.col]) {
        match = false
        return
      }

      match &= (params.data[filter.col].match(filter.val) !== null)
    })

    return match
  }
}

class FilteredUpdatedTable extends Component {
  constructor(props) {
    super(props)
    this.state = {backFilter: props.dataStore.appWide.backFilter[props.messagename]}
  }

  backFilterOnInput = event => {
    this.props.dataStore.appWide.backFilter[this.props.messagename] = event.target.value
    this.setState({backFilter: event.target.value})
  }

  render() {
    return (
      <div className="guif-div">
        { !this.props.gridOnly &&
          <div style={{float: 'left', width: 'calc(100% - 50px)', display: 'flex', alignItems: 'center'}}>
            <input className="guif-input" type="text" size="20" placeholder="back"
                   defaultValue={this.state.backFilter} onChange={this.backFilterOnInput}>
            </input>
          </div>
        }
        {this.renderUpdatedTable(this.props.dataStore, this.state.backFilter, this.props.messagename)}
      </div>
    )
  }
}

UpdatedTable.propTypes =
  { pctr: PropTypes.object
  , messagename: PropTypes.string
  , dataStore: PropTypes.object
  , backFilter: PropTypes.string
  , extraFilters: PropTypes.array
  , gridOnly: PropTypes.bool
  }


FilteredUpdatedTable.propTypes =
  { dataStore: PropTypes.object
  , backFilter: PropTypes.string
  , messagename: PropTypes.string
  , extraFilters: PropTypes.array
  , gridOnly: PropTypes.bool
  }


export {UpdatedTable, FilteredUpdatedTable}
