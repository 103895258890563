import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import {getCookies} from './util.jsx'

import './App.css'

class Params extends Component {
  constructor(params) {
    super(params)
    this.listenerData = params.listenerData
    this.state = { values: {}
                 , width: 350
                 }
    this.valueKeys = []
    this.components = []
  }

  makeInput = (label, fieldName, step = 0.01, min = 0) => {
    this.valueKeys.push(fieldName)

    return ( 
      <div style={{alignItems: 'left', display: 'inline-block', width: '100%'}}>
        <label htmlFor={fieldName} 
               style={{display: 'inline-block'}}> {label}: </label>
        <input type="number" size="4" step={step} min={min} id={fieldName} 
               defaultValue={this.state.values[fieldName]} name={fieldName} 
               style={{width: '70px', float: 'right'}}/>
      </div> 
    )
  }

  makeTextInput = (label, fieldName, placeholder = '', widthFactor = 1) => {
    this.valueKeys.push(fieldName)

    let width = (350 * widthFactor - 210) + 'px'
    return (
      <div style={{alignItems: 'left', display: 'inline-block', width: '100%'}}>
        <label htmlFor={fieldName}
               style={{display: 'inline-block'}}> {label}: </label>
        <input type="text" id={fieldName} name={fieldName}
               placeholder={placeholder}
               defaultValue={this.state.values[fieldName]}
               style={{width: width, float: 'right'}}/>
      </div>
    )
  }

  makeDisabledTextInput = (label, fieldName, placeholder = '', widthFactor = 1) => {
    this.valueKeys.push(fieldName)

    let width = (350 * widthFactor - 210) + 'px'
    return (
      <div style={{alignItems: 'left', display: 'inline-block', width: '100%'}}>
        <label htmlFor={fieldName}
               style={{display: 'inline-block'}}> {label}: </label>
        <input type="text" id={fieldName} name={fieldName}
               placeholder={placeholder}
               defaultValue={this.state.values[fieldName]}
               style={{width: width, float: 'right'}}
               disabled/>
      </div>
    )
  }

  makeCheckboxInput = (label, fieldName) => {
    this.valueKeys.push(fieldName)

    return (
      <div style={{marginTop: '2px', marginBottom: '2px', alignItems: 'left', display: 'inline-block', width: '100%'}}>
        <label htmlFor={fieldName}
               style={{display: 'inline-block'}}> {label}: </label>
        <input type="checkbox" id={fieldName} name={fieldName}
               defaultChecked={this.state.values[fieldName]}
               style={{float: 'right'}}/>
      </div>
    )
  }

  makeDisabledCheckboxInput = (label, fieldName) => {
    this.valueKeys.push(fieldName)

    return (
      <div style={{marginTop: '2px', marginBottom: '2px', alignItems: 'left', display: 'inline-block', width: '100%'}}>
        <label htmlFor={fieldName}
               style={{display: 'inline-block'}}> {label}: </label>
        <input type="checkbox" id={fieldName} name={fieldName}
               defaultChecked={this.state.values[fieldName]}
               style={{float: 'right'}}
               disabled/>
      </div>
    )
  }

  onSubmit = () => {
    let message = {}
    let valuesValidate = true

    this.valueKeys.forEach(documentKey => {
      // documentKey is distinct from key because documentKey needs to be unique on page, and the same key
      // can be part of different messages
      let key = documentKey.split('__')[0]

      let el = document.getElementById(documentKey)

      if (el.type === 'number') {
        if (Number(el.value) < el.min) {
          valuesValidate = false
          el.style.color = 'red'
        } else {
          el.style.color = ''
        }
      }

      if (el.type !== 'checkbox') {
        message[key] = el.value
      } else {
        message[key] = el.checked
      }

      if (key === 'desired_additional_positions')
      {
        el.style.color = ''

        if (el.value !== '')
        {
          let positions = el.value.split(',')
          positions.forEach(position => {
            let parts = position.split(':')

            if (parts.length !== 2 || isNaN(parts[1])) {
              valuesValidate = false
              el.style.color = 'red'
            }
          })
        }
      } else if (key === 'event_time') {
        el.style.color = ''
        let isValidFormattedDate = true
        if (el.value !== '') {
          const stringDate = el.value
          const potentialDate = new Date(stringDate)
          // Check valid date.
          if (isNaN(potentialDate)) {
            isValidFormattedDate = false
          // Check valid formatting
          } else {
            const positions = stringDate.split(' ')
            if (positions.length !== 2) {
              isValidFormattedDate = false
            } else {
              const dateYear = potentialDate.getFullYear()
              const dateMonth = potentialDate.getMonth() + 1 // Jan is 0
              const dateDay = potentialDate.getDate()
              const dateParts = positions[0].split('/')
              const timeParts = positions[1].split(':')
              // Must be yyyy/mm/dd HH:MM:SS
              if (dateParts.length !== 3
                || timeParts.length !== 3
                || timeParts[0] === ''
                || timeParts[1] === ''
                || timeParts[2] === ''
                || dateYear !== parseInt(dateParts[0])
                || dateMonth !== parseInt(dateParts[1])
                || dateDay !== parseInt(dateParts[2]))
              {
                isValidFormattedDate = false
              }
            }
          }
        }

        if (!isValidFormattedDate) {
          valuesValidate = false
          el.style.color = 'red'
        }
      }
    })

    if (valuesValidate) {
      if (this.strategyKey) message.strategy = this.strategyKey
      this.listenerData.POST(this.postRoute, message)

      let parts = this.postRoute.split('/')
      let name = parts[parts.length - 1]
      
      // Reflect submitted values, at least until next update
      if (this.strategyKey) { this.listenerData.parameters[name][this.strategyKey] = message }
      else                  { this.listenerData.parameters[name] = message }
    }
  }

  update = parameters => {
    if (this.derivedUpdate) this.derivedUpdate(parameters)
    if (parameters === undefined) return

    let readRoute = this.readRoute
    if (!readRoute) readRoute = this.postRoute

    let parts = readRoute.split('/')
    let name = parts[parts.length - 1]

    if (!parameters[name]) return
    let values = parameters[name]
    if (this.strategyKey) values = values[this.strategyKey]
    if (!values) return

    this.setState({values: values})

    this.valueKeys.forEach(documentKey => {
      let key = documentKey.split('__')[0]
      let el = document.getElementById(documentKey)

      if (el.type !== 'checkbox') {
        el.value = values[key]
      } else {
        el.checked = values[key]
      }
    })
  }

  refresh = () => {
    if (this.listenerData.parameters !== undefined) {
      this.update(this.listenerData.parameters)
    }
  }

  componentDidMount() {
    this.refresh()
  }
}

class AdjusterParams extends Params {
  constructor(params) {
    super(params)
    this.postRoute = '/parameters/adjuster'
  }
  
  render() {
    return (
      <div style={{display: 'inline-block', width: this.state.width + 'px', verticalAlign: 'top', margin: '25px'}}>
        Adjuster
        <div style={{fontSize: '14px', display: 'table-cell'}}>
          <div>
            {this.makeInput('APM Delta', 'delta_apm')}
            {this.makeInput('APM Gamma', 'gamma_apm')}
            {this.makeInput('APM Jump', 'jump_apm')}
            {this.makeInput('APM Pin', 'pin_apm')}
            {this.makeInput('APM Strike', 'strike_apm')}
            {this.makeInput('APM Vola', 'vola_apm')}
            {this.makeInput('APM Event', 'event_apm')}
            {this.makeInput('APM Forward', 'forward_apm')}
            {this.makeInput('APM Cash', 'cash_apm')}
            {this.makeInput('APM Rate', 'rate_apm')}
            {this.makeInput('APM Syn Delta', 'synthetic_delta_apm')}
            {this.makeInput('Min Base Cap', 'min_base_cap')}
            {this.makeInput('Base Cap', 'base_cap')}
            {this.makeInput('Market Width Factor', 'market_width_factor')}
            {this.makeInput('Vola Expiry Corr.', 'expiry_correlation')}
            {this.makeInput('Vola mStrikeVolFrac', 'moneyness_strike_vol_fraction')}
            {this.makeInput('Fwd closeExpiryMult', 'forward_close_to_expiry_multiplier')}
            {this.makeInput('Event variance', 'event_variance')}
            {this.makeTextInput('Event time', 'event_time', '2020/12/31 15:00:00')}
            <button onClick={this.onSubmit}>Submit</button>
            <button onClick={this.refresh}>Refresh</button>
          </div>
        </div>
      </div>
    )
  }
}

class ParametrizerParams extends Params {
  constructor(params) {
    super(params)
    this.postRoute = '/parameters/parametrizer'
  }

  render() {
    return (
      <div style={{display: 'inline-block', width: this.state.width + 'px', verticalAlign: 'top', margin: '25px'}}>
        Parametrizer
        <div style={{fontSize: '14px'}}>
          <div>
            {this.makeInput('Base valuation error factor', 'base_valuation_error_factor')}
            {this.makeInput('DVE factor', 'derived_valuation_error_factor')}
            {this.makeInput('DVE buy factor', 'dve_buy_factor')}
            {this.makeInput('DVE sell factor', 'dve_sell_factor')}
            {this.makeInput('DVE floor', 'derived_valuation_error_floor')}
            {this.makeInput('DVE boost vtexp cutoff', 'dve_boost_vtexp_cutoff')}
            {this.makeInput('DVE boost factor', 'dve_boost_factor')}
            {this.makeInput('Max DVE error abs', 'max_derived_valuation_error_abs')}
            {this.makeInput('Max DVE in vol', 'max_derived_valuation_error_in_vol')}
            {this.makeInput('Pickoff offset in ticks', 'pickoff_offset_in_ticks')}
            {this.makeInput('Dime volume', 'dime_volume', 1, 0)}
            {this.makeInput('Join volume', 'join_volume', 1, 0)}
            {this.makeInput('Max available volume', 'max_available_volume', 1, 1)}
            {this.makeInput('Adj fraction of cap', 'adj_fraction_of_cap')}
            {this.makeInput('Combo Adj fraction of cap', 'combo_adj_fraction_of_cap')}
            {this.makeInput('Variable offset factor', 'variable_offset_factor')}
            {this.makeCheckboxInput('Buy enabled', 'buy_enabled')}
            {this.makeCheckboxInput('Sell enabled', 'sell_enabled')}
            {this.makeInput('Alpha only cutoff', 'alpha_only_cutoff')}
            {this.makeInput('Max delta threshold', 'max_delta_threshold')}
            {this.makeInput('Qbs delta threshold', 'qbs_delta_threshold')}
            {this.makeInput('Qbs min delta threshold', 'qbs_min_delta_threshold')}
            {this.makeInput('Near expiry qbs delta threshold', 'near_expiry_qbs_delta_threshold')}
            {this.makeInput('Reduce only vtexp cutoff', 'reduce_only_vtexp_cutoff')}
            {this.makeInput('Combo reduce only vtexp cutoff', 'combo_reduce_only_vtexp_cutoff')}
            {this.makeInput('Reduce only thresh factor', 'reduce_only_threshold_factor')}
            {this.makeInput('Reduce only thresh of largest factor', 'reduce_only_threshold_of_largest_factor')}
            {this.makeInput('Reduce adj fraction of cap threshold', 'reduce_adj_fraction_of_cap_threshold')}
            {this.makeInput('Alpha aggressive factor', 'alpha_aggressive_factor')}
            {this.makeInput('Alpha defensive factor', 'alpha_defensive_factor')}
            {this.makeTextInput('Disable prefixes', 'disable_prefixes')}
            {this.makeTextInput('Enable securities', 'enable_securities')}
            {this.makeTextInput('Reduce only prefixes', 'reduce_only_prefixes')}
            {this.makeTextInput('Desired extra pos (n/a)', 'desired_additional_positions', 'VXQ9:10,VXU9:-5')}
          </div>
          <font size="+1">Flatteners:</font>
          <div>
            {this.makeCheckboxInput('Flatten', 'flatten')}
            {this.makeInput('Ignore flatten gamma', 'ignore_flatten_gamma')}
            {this.makeCheckboxInput('Adjust flatten', 'adjust_flatten')}
            {this.makeInput('Ignore flatten adjustment', 'ignore_flatten_adjustment')}
            {this.makeInput('Adj Flatten Max Sec', 'adjust_flatten_max_securities')}
            {this.makeInput('Adj Flatten Max Sec/Expiry', 'adjust_flatten_max_securities_per_expiry')}
            {this.makeInput('Adj Flatten Max T. Delta', 'adjust_flatten_max_total_signed_delta')}
            {this.makeTextInput('Adjust flatten prefixes', 'adjust_flatten_prefixes')}
            {this.makeCheckboxInput('Expiry flatten', 'expiry_flatten')}
            {this.makeInput('Expiry ignore flatten adjustment', 'expiry_ignore_flatten_adjustment')}
            {this.makeInput('Expiry Flatten Max Sec', 'expiry_flatten_max_securities')}
            {this.makeInput('Expiry Flatten Max T. Delta', 'expiry_flatten_max_total_signed_delta')}
          </div>
          <div>
            <button onClick={this.onSubmit}>Submit</button>
            <button onClick={this.refresh}>Refresh</button>
          </div>
        </div>
      </div>
    )
  }
}

class VolFitterParams extends Params {
  constructor(params) {
    super(params)
    this.postRoute = '/parameters/volFitter'
  }

  render() {
    return (
      <div style={{display: 'inline-block', width: this.state.width + 'px', verticalAlign: 'top', margin: '25px'}}>
        Vol Fitter
        <div style={{fontSize: '14px'}}>
          <div>
            {this.makeInput('Curve stability factor', 'curve_stability_factor')}
            {this.makeInput('Memory factor', 'memory_factor')}
            {this.makeInput('Smoothness factor', 'smoothness_factor')}
            {this.makeInput('Derivative2 stability', 'derivative2_stability')}
            {this.makeInput('Width factor', 'width_factor')}
            {this.makeInput('Uncertainty memory', 'uncertainty_memory')}
            {this.makeInput('Uncertainty width factor', 'uncertainty_width_factor')}
            <button onClick={this.onSubmit}>Submit</button>
            <button onClick={this.refresh}>Refresh</button>
          </div>
        </div>
      </div>
    )
  }
}

class VixFuturePricerRunnerParams extends Params {
  constructor(params) {
    super(params)
    this.postRoute = '/parameters/vixFuturePricerRunner'
  }

  render() {
    return (
      <div style={{display: 'inline-block', width: this.state.width + 'px', verticalAlign: 'top', margin: '25px'}}>
        Vix Pricer
        <div style={{fontSize: '14px'}}>
          <div>
            {this.makeInput('Wing vega correction factor', 'wing_vega_correction_factor')}
            {this.makeInput('Net gamma cap', 'net_gamma_cap', 0.00001)}
            {this.makeInput('Net gamma floor', 'net_gamma_floor', 0.00001, -1.0)}
            {this.makeInput('Net gamma vtexp cutoff', 'net_gamma_vtexp_cutoff')}
            {this.makeCheckboxInput('Net gamma cancel extra vega', 'net_gamma_cancel_extra_vega')}
            {this.makeInput('Settlement Correction', 'settlement_correction', 0.01, -1.0)}
            {this.makeCheckboxInput('Use term structure interpolation', 'use_term_structure_interpolation')}
            {this.makeInput('Interpolation vtexp power', 'interpolation_vtexp_power', 0.1, -2.0)}
            {this.makeInput('APC ref vtexp', 'adjust_per_contract_ref_vtexp')}
            {this.makeInput('APC multiplier', 'adjust_per_contract_multiplier')}
            {this.makeInput('Credit multiplier', 'credit_multiplier', 0.1)}
            {this.makeInput('Credit aggr factor', 'credit_aggr_factor', 0.1)}
            {this.makeInput('Max unhedged position', 'max_unhedged_position', 1.0)}
            {this.makeInput('Max position', 'max_position', 1.0)}
            {this.makeInput('Option first vtexp cutoff', 'option_first_vtexp_cutoff')}
            {this.makeTextInput('Auto symbols', 'auto_symbols')}
            <button onClick={this.onSubmit}>Submit</button>
            <button onClick={this.refresh}>Refresh</button>
          </div>
        </div>
      </div>
    )
  }
}

class SlowPathGeneralParams extends Params {
  constructor(params) {
    super(params)
    this.postRoute = '/parameters/slowPathGeneralParams'
  }

  render() {
    return (
      <div style={{display: 'inline-block', width: this.state.width + 'px', verticalAlign: 'top', margin: '25px'}}>
        Slow Path General
        <div style={{fontSize: '14px'}}>
          <div>
            {this.makeInput('SP max run interval in millis', 'maxRunIntervalInMillis', 1, 1)}
            {this.makeInput('SP min run interval in millis', 'minRunIntervalInMillis', 1, 1)}
            {this.makeInput('Option uncertainty factor', 'optionUncertaintyFactor')}
            {this.makeInput('Crossed pnl option threshold', 'crossedPnlOptionThreshold', 1, 1)}
            {this.makeInput('Crossed pnl future threshold', 'crossedPnlFutureThreshold', 1, 1)}
            {this.makeInput('Crossed pnl roll threshold', 'crossedPnlRollThreshold', 1, 1)}
            {this.makeInput('Arb threshold', 'arbThreshold', 0.1, 0)}
            {this.makeInput('Max uncertainty market factor', 'maxUncertaintyMarketFactor', 0.1, 0)}
            {this.makeInput('Market liquidity threshold', 'marketLiquidityThreshold', 0.1, 0)}
            {this.makeInput('ADJ run frequency in millis', 'runFrequencyInMillis', 1, 0)}
            {this.makeTextInput('Interest rate pillars', 'interestRatePillars')}
            {this.makeCheckboxInput('Crypto Alpha Enabled', 'cryptoAlphaEnabled')}
            <button onClick={this.onSubmit}>Submit</button>
            <button onClick={this.refresh}>Refresh</button>
          </div>
        </div>
      </div>
    )
  }
}


class ForwardFitterParams extends Params {
  constructor(params) {
    super(params)
    this.postRoute = '/parameters/forwardFitter'
  }

  derivedUpdate(parameters) {
    // Figure out the contents of ffEditableBounds based on ffManualCaps and ffManualFloors, which are what
    // the slow path speaks.  Initial values of ManualCaps/Floors provided by slow path .ini, translated here
    // into something easier to edit.  the onChange method of the input field will translate ffEditableBounds
    // back into ffManualCaps/Floors to transmit back to slow path.
    parameters.forwardFitter.ffEditableBounds = ''

    let ffManualCaps = parameters.forwardFitter.ffManualCaps
    let ffManualFloors = parameters.forwardFitter.ffManualFloors

    let caps = ffManualCaps ? ffManualCaps.split(';') : []
    let floors = ffManualFloors ? ffManualFloors.split(';') : []

    let expiries = {}
    let parseBound = (expiryBound, field) => {
      if (!parseBound) return

      let parts = expiryBound.split('=')
      if (parts.length !== 2) return

      let expiry = parts[0]
      let bound = parts[1]

      if (!expiries[expiry]) expiries[expiry] = {}
      expiries[expiry][field] = bound
    }

    caps.forEach(cap => parseBound(cap, 'cap'))
    floors.forEach(floor => parseBound(floor, 'floor'))

    let ebounds = ''
    for (let expiry in expiries) {
      if (expiry) {
        if (ebounds) ebounds += ','
        ebounds += expiry

        ebounds += ':'
        if (expiries[expiry].floor) ebounds += expiries[expiry].floor

        ebounds += ':'
        if (expiries[expiry].cap) ebounds += expiries[expiry].cap
      }
    }

    parameters.forwardFitter.ffEditableBounds = ebounds
  }


  render() {
    let widthFactor = 2.2

    let paramBox = (
      <div style={{display: 'inline-block', width: this.state.width * widthFactor + 'px', verticalAlign: 'top', margin: '25px'}}>
        Forward Fitter
        <div style={{fontSize: '14px'}}>
          <div>
            {this.makeInput('Roll Stability Factor', 'ffRollStabilityFactor', 0.01, 0)}
            {this.makeInput('Memory Factor', 'ffMemoryFactor', 0.01, 0)}
            {this.makeInput('Uncertainty Memory', 'ffUncertaintyMemory', 1, 0)}
            {this.makeInput('Uncertainty Width Factor', 'ffUncertaintyWidthFactor', 0.001, 0)}
            {this.makeInput('lt Bound Floor', 'ffLtBoundFloor', 0.0001, 0)}
            {this.makeInput('lt Bound Texp Factor', 'ffLtBoundTexpFactor', 0.01, 0)}
            {this.makeInput('lt Bound Max Impact', 'ffLtBoundMaxImpact', 0.001, 0)}
            {this.makeInput('lt Bound Max Impact Extra Factor', 'ffLtBoundMaxImpactExtraFactor', 0.001, 0)}
            {this.makeInput('Uncertainty Bound Shift Factor', 'ffUncertaintyBoundShiftFactor', 0.01, 0)}
            {this.makeTextInput('Editable Bounds', 'ffEditableBounds', '20210108:-40:30,20210109::50', widthFactor)}
            {this.makeDisabledTextInput('Manual Caps', 'ffManualCaps', '', widthFactor)}
            {this.makeDisabledTextInput('Manual Floors', 'ffManualFloors', '', widthFactor)}
            <button onClick={this.onSubmit}>Submit</button>
            <button onClick={this.refresh}>Refresh</button>
          </div>
        </div>
      </div>
    )

    let bounds = document.getElementById('ffEditableBounds')
    let manualCaps = document.getElementById('ffManualCaps')
    let manualFloors = document.getElementById('ffManualFloors')
    if (bounds && manualCaps && manualFloors) {
      bounds.addEventListener('change', () => {
        let floors = ''
        let caps = ''
        let malformed = false

        let expiries = bounds.value.split(',')
        expiries.forEach(expiry => {
          let parts = expiry.split(':')
          if (parts.length !== 3) {
            malformed = true
            return
          }
          let date = parts[0]
          let floor = parts[1]
          let cap = parts[2]

          let dateParts = date.split('-') // dates either single '20200101' or range 20170401-20170301'
          dateParts.forEach(datePart => {
            if (!Number.isFinite(+datePart)) malformed = true
            if (datePart.length !== 8) malformed = true
          })
          if (!Number.isFinite(+floor) || !Number.isFinite(+cap)) malformed = true

          if (malformed) return

          if (floor) {
            if (floors) floors += ';'
            floors += date + '=' + floor
          }

          if (cap) {
            if (caps) caps += ';'
            caps += date + '=' + cap
          }

        })

        if (malformed) {
          floors = ''
          caps = ''
          bounds.value = ''
        }

        manualCaps.value = caps
        manualFloors.value = floors
      })
    }

    return paramBox
  }
}

class AutoParams extends Params {
  constructor(params) {
    super(params)
    this.postRoute = '/parameters/auto'
  }

  render() {
    return (
      <div style={{display: 'inline-block', width: this.state.width + 'px', verticalAlign: 'top', margin: '25px'}}>
        Automatic Monitoring
        <div style={{fontSize: '14px'}}>
          <div>
            {this.makeDisabledCheckboxInput('Auto heartbeats supported', 'heartbeatsSupported')}
            {this.makeCheckboxInput('Auto mode enabled', 'enabled')}
            {this.makeCheckboxInput('Entry enabled', 'entryEnabled')}
            {this.makeCheckboxInput('Mode Recovery enabled', 'modeRecoveryEnabled')}
            {this.makeTextInput('Disable regex', 'disableRegex')}
            <button onClick={this.onSubmit}>Submit</button>
            <button onClick={this.refresh}>Refresh</button>
          </div>
        </div>
      </div>
    )
  }
}

class StrategyAlertParams extends Params {
  constructor(params) {
    super(params)
    this.strategyKey = params.strategyKey
    this.postRoute = '/parameters/strategiesMap'
  }

  render() {
    return (
      <div style={{display: 'inline-block', width: this.state.width + 'px', verticalAlign: 'top', margin: '25px'}}>
        Strategy {this.strategyKey} Alerts
        <div style={{fontSize: '14px'}}>
          <div>
            {/* this.makeInput('Delta Threshold', 'deltaThreshold', 0.01, 0) */}
            {/* this.makeInput('gamma Threshold', 'gammaThreshold', 0.01, 0) */}
            {this.makeInput('PNL Threshold', 'pnlThreshold', 1000, -1000000)}
            {this.makeInput('Gui PNL Alert Factor', 'guiPnlAlertFactor', 0.1, 0)}
            <button onClick={this.onSubmit}>Submit</button>
            <button onClick={this.refresh}>Refresh</button>
          </div>
        </div>
      </div>
    )
  }
}

class ParametersView extends Component {
  constructor(props) {
    super(props)
    this.dataStore = props.dataStore
    this.back = props.back
    this.dataStore.ldList.forEach(ld => {
      if (ld.back === this.back) this.listenerData = ld
    })

    this.cookies = getCookies()
  }

  render() {
    if (this.listenerData.parameters === undefined) {
      return ( <div className="guif-div"> No parameters to set </div> )
    }

    let strategiesMap = this.listenerData.parameters.strategiesMap
    let strategyParams = []

    if (strategiesMap) {
      for (let key in strategiesMap) {
        strategyParams.push(<StrategyAlertParams listenerData={this.listenerData} key={'sap' + key} strategyKey={key}/>)
      }
    }

    return (
      <div className="guif-div">
        <div className="guif-div-sbs">
          <AdjusterParams listenerData={this.listenerData}/>
          <ParametrizerParams listenerData={this.listenerData}/>
          <VolFitterParams listenerData={this.listenerData}/>
          <VixFuturePricerRunnerParams listenerData={this.listenerData}/>
          <SlowPathGeneralParams listenerData={this.listenerData}/>
          <ForwardFitterParams listenerData={this.listenerData}/>
          { this.cookies.autoOperation && <AutoParams listenerData={this.listenerData}/> }
          { strategyParams }
        </div>
      </div>
    )
  }
}

class ParametersTabs extends Component {
  constructor(props) {
    super(props)
    this.dataStore = props.dataStore
  }

  render = () => {
    return (
      <div className="guif-div">
        <Tabs className="sub-tabs">
          <TabList>
            {this.dataStore.ldList.map(ld => <Tab key={ld.back}>{ld.back}</Tab>)}
          </TabList>

          {this.dataStore.ldList.map(ld => {
            let dataStore = {ldList: [ld], appWide: this.dataStore.appWide}
            return <TabPanel key={ld.back}><ParametersView dataStore={dataStore} back={ld.back}/></TabPanel>
          })}
        </Tabs>
      </div>
    )
  }
}

ParametersView.propTypes =
  { dataStore: PropTypes.object
  , back: PropTypes.string
  }

ParametersTabs.propTypes =
  { dataStore: PropTypes.object
  }

export {ParametersView, ParametersTabs}
