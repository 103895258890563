// LoginForm.jsx
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './LoginForm.css'

const LoginForm = ({ onLogin }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    onLogin(username, password)
  }

  return (
    <div className='login-fullscreen-wrapper'>
      <div className='login-container'>
        <h1 className='login-title'>Kurtosis Control</h1>
        <form className='login-form' onSubmit={handleSubmit}>
          <input 
            type='text' 
            placeholder='Enter Username' 
            name='username' 
            autoComplete='username'
            value={username} 
            onChange={(e) => setUsername(e.target.value)}
            required
            style={{float: 'right'}} />

          <input 
            type='password'
            placeholder='Enter Password'
            name='password'
            autoComplete='current-password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{float: 'right'}} />

          <button type='submit'>Login</button>
        </form>
      </div>
    </div>
  )
}
LoginForm.propTypes = 
{ onLogin: PropTypes.func
}
  
export default LoginForm