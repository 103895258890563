import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import io from 'socket.io-client'
import 'whatwg-fetch'

import ServiceStatus from './Multi/ServiceStatus.jsx'
import {StraddleTabs} from './StraddleView.jsx'
import {ParametersTabs} from './ParametersView'
import {ChartTabs} from './PNLChart'
import ProductController from './ProductController'
import {FilteredExecutionTable} from './ExecutionTable'
import {FilteredTradesTable} from './TradesTable'
import {FilteredAlertTable} from './AlertTable'
import {TradesAnalysis} from './TradesAnalysis'
import {FilteredJobTable} from './JobTable'
import Risk from './Risk'
import LoginForm from './LoginForm'

import CryptoJS from 'crypto-js'
import AES from 'crypto-js/aes'

import {getCookies} from './util.jsx'
import './App.css'

/* global process */ // Magic to make eslint not complain about process.env
let socketRoute = ':3001'
socketRoute = ''
if (process.env.REACT_APP_DEFAULT_SOCKET) {
  socketRoute = process.env.REACT_APP_DEFAULT_SOCKET
}

console.log('Socket route = ' + socketRoute)

let ldList = []  // List of listenerData for all strategies


function makeAppWide() {
  let appWide = { mode: {}
                , allContracts: {}
                , backFilter: {}
                }
  return appWide
}
let appWide = makeAppWide()

// Defaults for joined straddle tables.  Non-joined tables get their default values
// when the listenerData for the back is created
appWide.mode['Options'] = 'full'
appWide.mode['Through Market Options'] = 'full'

function constructForwardBaseVols(atmBaseVols) {
  let forward = []
  let baseVols = atmBaseVols.baseVols

  for (let j = 0; j < baseVols.length - 1; ++j) {
    let vtexp1 = baseVols[j].mSqrtvtexp ** 2
    let vtexp2 = baseVols[j + 1].mSqrtvtexp ** 2
    let vol1 = baseVols[j].mBasevol
    let vol2 = baseVols[j + 1].mBasevol

    let vol = Math.sqrt((vtexp2 * vol2 * vol2 - vtexp1 * vol1 * vol1) / (vtexp2 - vtexp1))
    forward[j] = { mBasevol: vol
                 , mSqrtvtexp: baseVols[j + 1].mSqrtvtexp
                 , mIntexpiry: baseVols[j + 1].mIntexpiry
                 }
  }

  return {time: atmBaseVols.time, baseVols: forward}
}

function registerSocketListener(name, listenerData, socket) {
  socket.on(name, data => {
    let msg = JSON.parse(data)

    if (typeof msg === 'object' && msg.seqnum) {
      if (listenerData.seqnum[name] === undefined
          || listenerData.seqnum[name] < msg.seqnum) {
        listenerData.seqnum[name] = msg.seqnum
        msg = msg.data
      } else {
        console.log('Skipping stale message seqnum = ' + listenerData.back + ' ' + name + ' ' + msg.seqnum + ' currently at ' + listenerData.seqnum[name])
        msg = undefined
      }
    }

    if (msg !== undefined) {
      listenerData[name] = msg

      listenerData.getListeners(name).forEach(listener => {
        listener(listenerData[name])
      })

      if (listenerData.pctr) {
        // Hack for the single view -- relay messages through their listeners as well
        // until we can refactor everything up to listenerData
        listenerData.pctr.getListeners(name).forEach(listener => {
          listener(listenerData[name])
        })
      }
    }

    socket.emit(name, {received: true})
  })
}

function makeListenerData(app, name, socketAddress, cookies) {
  let lastSocket = null

  let ld = { back: name !== '' ? name : 'stand-alone'
           , config: {}
           , seqnum:{}
           , soundEnabled: false
           , mute: {}
           , vixmute: {}
           , jobmute: {}
           , lastHeartbeatTime: new Date(0)
           , heartbeatsEnabled: false
           , heartbeatFilter: ''
           , heartbeatUser: ''
           , executionAlertsEnabled: true
           , pnlAlertsEnabled: true
           , pnlEWMADiffThreshold: ''
           , alertFilter: ''
           , listeners: []
           , timeSeries: {}
           , clientIP: ''
           , streamUpdates: false
           , atmBaseVols: []
           , forwardAtmBaseVols: []
           , deltaVols: []
           }

  let prefix = name === '' ? '' : '/' + name

  if (name !== '') {
    ld.alertFilter = appWide.alertFilter
    ld.pnlEWMADiffThreshold = appWide.pnlEWMADiffThreshold
  }

  ld.GET = function(route) {
    return fetch(prefix + route)
  }

  ld.POST = function(route, obj) {
    return fetch(prefix + route,
                 { method: 'POST'
                 , headers: { 'Content-Type': 'application/json' }
                 , body: JSON.stringify(obj)
                 })
  }

  function connectToSocket(socket) {
    ld.seqnum = {}

    registerSocketListener('service-status-table', ld, socket)
    registerSocketListener('heartbeat-filter', ld, socket)

    if (cookies.straddleTab)        registerSocketListener('straddle-options-sec-params', ld, socket)
    if (cookies.straddleTab)        registerSocketListener('straddle-combos-sec-params', ld, socket)
    if (cookies.straddleTab)        registerSocketListener('straddle-futures-sec-params', ld, socket)
    if (cookies.executionsTab)      registerSocketListener('executions', ld, socket)
    if (cookies.tradesTab)          registerSocketListener('strategy-order-descriptions', ld, socket)
    if (cookies.alertsTab)          registerSocketListener('alerts', ld, socket)
    if (cookies.parametersTab)      registerSocketListener('parameters', ld, socket)
    if (cookies.riskTab)            registerSocketListener('scenarios', ld, socket)
    if (cookies.riskTab)            registerSocketListener('slippage', ld, socket)
    if (cookies.fitValidationTable) registerSocketListener('fit-validation', ld, socket)
    if (cookies.auxMarketTable)     registerSocketListener('aux-market-info', ld, socket)
    if (cookies.brokerMarkets)      registerSocketListener('broker-markets', ld, socket)
    if (cookies.pnlStatisticsTable) registerSocketListener('pnl-statistics', ld, socket)
    if (cookies.jobsTab)            registerSocketListener('jobs', ld, socket)

    socket.on('alert-sound', data => {
      let serviceIDs = JSON.parse(data)
      app.playSound(app.alertSound, ld, serviceIDs)
      socket.emit('alert-sound', {received: true})
    })
    socket.on('execution-sound', data=> {
      if (ld.executionAlertsEnabled) {
        let serviceIDs = JSON.parse(data)
        app.playSound(app.executionSound, ld, serviceIDs, Number, 'mute', false)
      }
      socket.emit('execution-sound', {received: true})
    })

    socket.on('jobs', data => {
      let jobs = JSON.parse(data)
      let alertList = {}
      let playAlert = false

      jobs.forEach(job => {
        if (typeof job === 'object') {
          let status = job.status
          if (status === 'LATE' || status === 'FAILED') {
            alertList[job.jobID] = true
            playAlert = true
          }
        }
      })

      if (playAlert) {
        app.playSound(app.alertSound, ld, alertList, Number, 'jobmute', false)
      }
    })
  }

  ld.getConfig = function() {
    return ld.config
  }

  ld.getListeners = function(name) {
    if (!ld.listeners.hasOwnProperty(name)) {
      ld.listeners[name] = []
    }

    return ld.listeners[name]
  }

  ld.addListener = function(name, listener) {
    ld.getListeners(name).push(listener)
  }

  ld.removeListener = function(name, listener) {
    let notListener = function(value) {
      return value !== listener
    }

    ld.listeners[name] = ld.getListeners(name).filter(notListener)
  }

  function updateInstrumentationTimeSeries(rows) {
    rows.forEach(row => {
      if (row.serviceName === 'SP') {
        let strategy = row.strategy
        if (ld.timeSeries.instrumentation[strategy] === undefined) {
          ld.timeSeries.instrumentation[strategy] = []
        }

        if (row.instrumentation) {
          let tsEntry = { time: row.lastUpdated
                        , instrumentation: row.instrumentation
                        }

          let ts = ld.timeSeries.instrumentation[strategy]
          let pushNew = true

          if (ts.length > 0) {
            if (tsEntry.time - ts[ts.length - 1].time < 5000) pushNew = false
          }

          if (pushNew) ts.push(tsEntry)
        }
      }
    })
  }

  function updateSlippageTimeSeries(rows) {
    rows.forEach(row => {
      if (row.expiry === 0) {
        let strat = row.strategy
        let tsEntry = {time: new Date(), slippage: row}

        let ts = ld.timeSeries.slippage[strat]
        if (ts) {
          let pushNew = true
          if (ts.length > 0) {
            if (tsEntry.time - ts[ts.length - 1].time < 5000) pushNew = false
          }

          if (pushNew) ts.push(tsEntry)
        }
      }
    })
  }

  function updateUnderlyingTimeSeries(update) {
    // To work with both straddle-futures-sec-params and with aux-market info messages
    let rows = update.rows ? update.rows : update

    rows.forEach(row => {
      let display = row.display ? row.display : row.symbol
      display = display.replace(/\0/g, '') // trim trailing nulls

      if (display in ld.timeSeries.underlying) {
        let px = (row.marketBidPx + row.marketAskPx) / 2

        if (px) {
          let tsEntry = {time: new Date(), px: px}

          let ts = ld.timeSeries.underlying[display]
          if (ts) {
            let pushNew = true
            if (ts.length > 0) {
              if (tsEntry.time - ts[ts.length - 1].time < 5000) pushNew = false
            }

            if (pushNew) ts.push(tsEntry)
          }
        }
      }
    })
  }

  let pendingGetldConfig = false
  function getldConfig() {
    console.log('Attempting to connect to ' + name)

    pendingGetldConfig = false
    let tryConfigAgain = function() {
      if (!pendingGetldConfig) {
        pendingGetldConfig = true
        setTimeout(getldConfig, 5000)
      }
    }

    ld.GET('/api/config')
    .then(
      response => {
        if (response.status === 401) {
          console.log('Unauthorized')
          app.setState({ isAuthenticated: false })
          return
        }
        return response.json()
      }
    )
    .then(
      (result) => {
      if (typeof result === 'string') {
        let bytes = AES.decrypt(result, 'so-totally-secret')
        result = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      }

      if (typeof result !== 'object') {
        console.log('There was an error getting config, will try again in 5 seconds')
        tryConfigAgain()
      }

      ld.config = result
      if (name === '') {
        if (ld.alertFilter === '') {
          ld.alertFilter = ld.config.defaultAlertFilter
        }
        if (ld.pnlEWMADiffThreshold === '') {
          ld.pnlEWMADiffThreshold = ld.config.defaultPnlEWMADiffThreshold
        }
      }
      if (ld.heartbeatFilter === '')
        ld.heartbeatFilter = ld.config.heartbeatFilter
      if (ld.heartbeatUser === '')
        ld.heartbeatUser = ld.config.heartbeatUser

      ld.clientIP = ld.config.clientIP

      console.log(name + '/heartbeatFilter = ' + JSON.stringify(ld.config.heartbeatFilter))
      console.log(name + '/heartbeatUser = ' + JSON.stringify(ld.config.heartbeatUser))
      console.log(name + '/clientIP = ' + JSON.stringify(ld.config.clientIP))
      ld.getListeners('config').forEach(listener => {
        listener(ld.config)
      })

      if (lastSocket) lastSocket.close()
      console.log('Connecting to ' + name + ' at ' + socketAddress)
      let socket = io.connect(socketAddress)
      connectToSocket(socket)
      lastSocket = socket

      {
        let lastHeartbeat = new Date()
        socket.on('connect', () => {
          let checkSocketConnection = () => {
            let now = Date.now()
            if (now - lastHeartbeat > 90000) {
              console.log('Connection to ' + name + ' appears to be dead')
              socket.close()
              tryConfigAgain()
            } else {
              socket.emit('guif-heartbeat', JSON.stringify(now))
              setTimeout(checkSocketConnection, 1000)
            }
          }

          checkSocketConnection()

          if (ld.streamUpdates) socket.emit('activate-secparams', JSON.stringify(ld.streamUpdates))
        })

        socket.on('connect_error', (error) => {
          console.error('Socket connection error:', error)
        })

        socket.on('guif-heartbeat', msg => {
          lastHeartbeat = JSON.parse(msg)
        })
      }

      socket.on('disconnect', () => {
        console.log('Socket to ' + name + ' disconnected')
      })

      let getSomeOfKey = (key, route, fullResult, start, length, contin) => {
        ld.POST(route, {key: key, start: start, end: start + length})
        .then(response => {
          if (response.status === 401) {
            console.log('Unauthorized')
            app.setState({ isAuthenticated: false })
            return
          }
          return response.json()
        })
        .then(
          (result) => {
            if (typeof result !== 'object') {
              console.log('Error getting timeseries from ' + route)
              return
            }

            fullResult[key] = fullResult[key].concat(result)
            if (result.length === length) {
              getSomeOfKey(key, route, fullResult, start + length, length, contin)
            } else {
              console.log('All done with key ' + key)
              contin()
            }
          },
          (error) => {
            console.log('Error getting time series from ' + route + ' error = ' + error)
          })
      }

      let getKeys = (keys, route, fullResult, processor) => {
        if (keys.length === 0) {
          processor(fullResult)
        } else {
          let key = keys[0]
          keys.shift()

          fullResult[key] = []
          getSomeOfKey(key, route, fullResult, 0, 100000, () => getKeys(keys, route, fullResult, processor))
        }
      }

      if (cookies.chartsTab) {
        let processInstrumentation = result => {
          for (let strat in result) {
            console.log('Instrumentation ' + strat + ' ' + result[strat].length
                        + ' ' + JSON.stringify(result[strat]).length)
          }
          ld.timeSeries.instrumentation = result
          ld.addListener('service-status-table', updateInstrumentationTimeSeries)
        }

        ld.GET('/api/time-series/instrumentation/keys')
        .then(response => {
          if (response.status === 401) {
            console.log('Unauthorized')
            app.setState({ isAuthenticated: false })
            return
          }
          return response.json()
        })
        .then(
          (keys) => {
            getKeys(keys, '/api/time-series/range/instrumentation', {}, processInstrumentation)
          },
          (error) => {
            console.log('Error getting instrumentation keys, will try legacy route for instrumentation time series')
            console.log(error)

            ld.GET('/api/time-series/instrumentation')
            .then(response => {
              if (response.status === 401) {
                console.log('Unauthorized')
                app.setState({ isAuthenticated: false })
                return
              }
              return response.json()
            })
            .then(
              (result) => {
                processInstrumentation(result)
              },
              (error) => {
                ld.timeSeries.instrumentation = {}
                console.error('There was an error getting instrumentation time series from legacy route -- no instrumentation', error)
              }
            )
          })
        }

        if (cookies.chartsTab) {
          let processUnderlying = result => {
            for (let key in result) {
              console.log('Underlying ' + key + ' ' + result[key].length +
                         ' ' + JSON.stringify(result[key]).length)
            }
            ld.timeSeries.underlying = result
            ld.addListener('straddle-futures-sec-params', updateUnderlyingTimeSeries)
            ld.addListener('aux-market-info', updateUnderlyingTimeSeries)
          }

          ld.GET('/api/time-series/underlying/keys')
          .then(response => {
            if (response.status === 401) {
              console.log('Unauthorized')
              app.setState({ isAuthenticated: false })
              return
            }
            return response.json()
          })
          .then(
            (keys) => {
              getKeys(keys, '/api/time-series/range/underlying', {}, processUnderlying)
            },
            (error) => {
              console.log('Error getting underlying keys, will try legacy route for underlying time series')
              console.log(error)

              ld.GET('/api/time-series/underlying')
              .then(response => {
                if (response.status === 401) {
                  console.log('Unauthorized')
                  app.setState({ isAuthenticated: false })
                  return
                }
                return response.json()
              })
              .then(
                (result) => {
                  processUnderlying(result)
                },
                (error) => {
                  ld.timeSeries.underlying = {}
                  console.log('There was an error getting underlying time series from legacy route -- no underlying')
                  console.log(error)
                })
            }
          )
        }

        if (cookies.chartsTab) {
          let processSlippage = result => {
            for (let strat in result) {
              console.log('Slippage ' + strat + ' ' + result[strat].length
                          + ' ' + JSON.stringify(result[strat]).length)
            }
            ld.timeSeries.slippage = result
            ld.addListener('slippage', updateSlippageTimeSeries)
          }

          ld.GET('/api/time-series/slippage/keys')
          .then(response => {
            if (response.status === 401) {
              console.log('Unauthorized')
              app.setState({ isAuthenticated: false })
              return
            }
            return response.json()
          })
          .then(
            (keys) => {
              getKeys(keys, '/api/time-series/range/slippage', {}, processSlippage)
            },
            (error) => {
              console.log('Error getting slippage keys, will try legacy route for slippage time series')
              console.log(error)

              ld.GET('/api/time-series/slippage')
              .then(response => {
                if (response.status === 401) {
                  console.log('Unauthorized')
                  app.setState({ isAuthenticated: false })
                  return
                }
                return response.json()
              })
              .then(
                (result) => {
                  processSlippage(result)
                },
                (error) => {
                  ld.timeSeries.slippage = {}
                  console.log('There was an error getting slippage time series from legacy route -- no slippage')
                  console.log(error)
                })
            }
          )
        }

        if (cookies.chartsTab) {
          let constructAndPushDeltaVols = baseVols => {
            let deltaVols = {}
            if (typeof baseVols === 'object' && typeof baseVols.baseVols === 'object') {
              baseVols.baseVols.forEach(baseVol => {
                if (typeof baseVol.mDeltapointsList === 'object') {
                  baseVol.mDeltapointsList.forEach(deltaPoint => {
                    let delta = deltaPoint.mDelta
                    if (deltaVols[delta] === undefined) {
                      deltaVols[delta] = {time: baseVols.time, baseVols: []}
                    }
                    deltaVols[delta].baseVols.push({ mBasevol: deltaPoint.mVol
                                                   , mSqrtvtexp: baseVol.mSqrtvtexp
                                                   , mIntexpiry: baseVol.mIntexpiry
                                                   })
                  })
                  delete baseVol.mDeltapointsList  // No need to store more than once
                }
              })
            }
            ld.deltaVols.push(deltaVols)
          }

          let processBaseVols = result => {
            ld.atmBaseVols = result
            ld.atmBaseVols.forEach(baseVols => {
              ld.forwardAtmBaseVols.push(constructForwardBaseVols(baseVols))
              constructAndPushDeltaVols(baseVols)
            })

            socket.emit('atm-base-vols', {received: true}) // In case we missed some waiting for initial load
            socket.on('atm-base-vols', data => {
              let baseVols = JSON.parse(data)

              let pushNew = true
              let bvts = ld.atmBaseVols
              if (bvts.length > 0) {
                // most throttling done server side.  But the server broadcasts things fairly quickly so that new
                // guis and changes are reflected promptly.  Mostly, that'll be the same baseVols broadcast over
                // and over -- so this prevents us from storing a bunch of them with the same timestamp
                let time2 = new Date(baseVols.time)
                let time1 = new Date(bvts[bvts.length - 1].time)
                if (time2 - time1 < 5000) pushNew = false
              }

              if (pushNew) {
                if (ld.atmBaseVols.length === 0 ||
                    JSON.stringify(baseVols) !== JSON.stringify(ld.atmBaseVols[ld.atmBaseVols.length - 1])) {
                  ld.atmBaseVols.push(baseVols)
                  ld.forwardAtmBaseVols.push(constructForwardBaseVols(baseVols))
                  constructAndPushDeltaVols(baseVols)
                }
              }

              socket.emit('atm-base-vols', {received: true})
            })
          }

          let fullResult = []
          let getSome = (start, length) => {
            ld.POST('/api/time-series/range/atm-base-vols', {start: start, end: start + length})
            .then(response => {
              if (response.status === 401) {
                console.log('Unauthorized')
                app.setState({ isAuthenticated: false })
                return
              }
              return response.json()
            })
            .then(
              (result) => {
                if (typeof result !== 'object') {
                  console.log('Some sort of bogus atm-base-vols, error getting time series')
                }

                fullResult = fullResult.concat(result)
                if (result.length === length) {
                  console.log('Getting some more atm-base-vols ' + (start + length))
                  getSome(start + length, length)
                }
                else {
                  console.log('All done, fullResult length = ' + fullResult.length + ' ' + result.length)
                  processBaseVols(fullResult)
                }
              },
              (error) => {
                console.log('Error getting atm-base-vols from ranged route, trying legacy route')
                console.log(error)

                // Try the non range based route
                ld.GET('/api/time-series/atm-base-vols')
                .then(response => {
                  if (response.status === 401) {
                    console.log('Unauthorized')
                    app.setState({ isAuthenticated: false })
                    return
                  }
                  return response.json()
                })
                .then(
                  (result) => {
                    processBaseVols(result)
                  },
                  (error) => {
                    console.error('There was an error getting base vols time series from legacy route -- no base vols', error)
                  }
                )
              }
            )
          }

          getSome(0, 100000)
        }

        if (cookies.straddleTab) {
          ld.setStraddleUpdates = function(val) {
            ld.streamUpdates = val
            socket.emit('activate-secparams', JSON.stringify(ld.streamUpdates))
          }
        }
      },
      (error) => {
        console.error('There was an error getting config, will try again in 5 seconds', error)
        tryConfigAgain()
      }
    )
  }

  ld.addListener('heartbeat-filter', data => {
    ld.heartbeatFilter = data.ip
    ld.heartbeatUser = data.heartbeatUser

    let now = Date.now()
    if (ld.heartbeatsEnabled && now - ld.lastHeartbeatTime > 5000) {

      fetch(prefix + '/control/heartbeat',
            { method: 'POST'
            , headers: { 'Content-Type': 'application/json' }
            , body: JSON.stringify({clientIP: ld.clientIP})
            })

      ld.lastHeartbeatTime = now
    }
  })

  {
    // This group of listeners work together to detect differences between pnl from
    // SP and the pnl-statistics ewma pnl.  Diffs are compared and alerts sounded
    // when the service-status-tables message comes in
    let ewmaPnl = undefined
    ld.addListener('service-status-table', data => {
      data.forEach(service => {
        if (service.serviceName === 'SP') {
          if (ewmaPnl) {
            let spPnl = service.instrumentation.mPnl
            let spServiceID = service.serviceID
            let diff = Math.abs(spPnl - ewmaPnl)
            if (diff > ld.pnlEWMADiffThreshold) {
              if (ld.pnlAlertsEnabled) {
                {
                  // Adding 'EWMA Pnl Breach' to SP details.  This is a pretty convienent
                  // place to do it because it automatically gets added to anything that uses
                  // details -- like groups.  But it is a bit sketcy to modify something that's
                  // clearly implied to be a read only parameter.  As long as this is the first
                  // listener (which we can guarantee by it's placement in the listenerData
                  // constructor) this should be OK.  If it eventually becomes not ok, put an
                  // "additionalDetails" map in listenerData, splice the two together in the
                  // table with a value getter.
                  let origDetails = service.details
                  service.details = 'EWMA Pnl Breach'
                  if (origDetails) service.details += '; ' + origDetails
                }
                let alerts = {}
                alerts[spServiceID] = true
                app.playSound(app.alertSound, ld, alerts)
              }
            }
          }
        }
      })

    })
    ld.addListener('pnl-statistics', data => {
      if (data && typeof data === 'object' && typeof data[0] === 'object') {
        ewmaPnl = data[0].mEwmapnl
      }
    })
  }
  appWide.mode[ld.back + ' Options'] = 'full'
  appWide.mode[ld.back + ' Through Market Options'] = 'full'
  ldList.push(ld)

  getldConfig()

  return ld
}

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      controllers: []
                 , tradesAnalysisURL: ''
                 , tradingModes: []
                 , loading: true
                 , isAuthenticated: false
                 }

    this.alertSound = new Audio('/media/alert.wav')
    this.executionSound = new Audio('/media/execution.wav')
  }

  componentDidMount() {
    console.log('componentDidMount')
    this.checkAuth()
  }

  initAfterAuth() {
    console.log('initAfterAuth')
    this.cookies = getCookies()
    console.log('Cookies: ' + JSON.stringify(this.cookies))
    this.fetchConfig()
    //window.location.href = "/"
  }

  fetchConfig = () => {
    console.log('fetchConfig')
    fetch('/api/config')
    .then((response) => {
      if (response.status === 401) {
        this.setState({ isAuthenticated: false })
        throw new Error('User not authenticated')
      }
      else if (!response.ok) {
        this.setState({ loading: true })
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .then((config) => {
      if (typeof config === 'string') {
        let bytes = AES.decrypt(config, 'so-totally-secret')
        config = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        console.log('config: ' + JSON.stringify(config))    
      }

      this.initializeControllers(config)
      this.setState({ loading: false })
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation: ' + error.message)
      this.setState({ loading: true })
    })
  }

  initializeControllers = (config) => {
    console.log('initializeControllers')
    appWide.alertFilter = config.defaultAlertFilter
    appWide.pnlEWMADiffThreshold = config.defaultPnlEWMADiffThreshold

    if (config.backs) {
      const controllers = config.backs.map((back) => {
        const socketAddress = `${socketRoute}/${back.name}`
        const listenerData = makeListenerData(this, back.name, socketAddress, this.cookies)
        const dataStore = { ldList: [listenerData], app: this, appWide }

        return {
          name: back.name
               ,listenerData
               ,controller: (
            <ProductController
              app={this}
              back={back.name}
              dataStore={dataStore}
            />
          ),
               }
      })
      this.setState({
        controllers
                    ,tradesAnalysisURL: config.tradesAnalysisURL
                    ,tradingModes: config.tradingModes
                    ,loading: false,
                    })
    } else {
      const socketAddress = `${socketRoute}/`
      const listenerData = makeListenerData(this, '', socketAddress, this.cookies)
      const dataStore = { ldList: [listenerData], app: this, appWide }

      console.log('creating a controller, tradingModes = ' + config.tradingModes + '  ' + this.state.tradingModes)
      const controller = {
        name: 'default'
                         ,listenerData
                         ,controller: (
          <ProductController
            app={this}
            back="stand-alone"
            dataStore={dataStore}
            tradingModes={config.tradingModes}
          />
        ),
                         }

      this.setState({
        controllers: [controller]
                    ,tradesAnalysisURL: config.tradesAnalysisURL
                    ,tradingModes: config.tradingModes
                    ,loading: false,
                    })
    }
  }

  playSound = (sound, listenerData, alertList, idCoerce = Number, muteField = 'mute',
    filterAlerts = true) => {

    if (listenerData.soundEnabled) {
      let playSound = false
      if (sound) {
        for (let key in alertList) {
          key = idCoerce(key) // coerce to proper key format

          if (alertList[key] === true &&
              !listenerData[muteField][key]) {
            if (filterAlerts && listenerData.alertFilter !== '') {
              let details = ''
              listenerData['service-status-table'].forEach(service => {
                if (service.serviceID === key && service.details) {
                  details = service.details
                }
              })

              if (listenerData.alertFilter && details.match(listenerData.alertFilter)) continue
            }

            playSound = true
          }
        }

        if (playSound) sound.play()
      }
    }
  }

  checkAuth = () => {
    console.log('checkAuth')
    fetch('/api/check-auth', {
      credentials: 'include' // This is important for sending cookies
                             })
    .then(response => response.json())
    .then(data => {
      this.setState({ isAuthenticated: data.isAuthenticated })
      if (data.isAuthenticated) {
        this.initAfterAuth()
      }
    })
    .catch(error => {
      console.error('Auth check failed:', error)
      this.setState({ isAuthenticated: false })
    })
  }

  handleLogin = (username, password) => {
    console.log('handleLogin')
    return fetch('/login', 
                 { method: 'POST'
                 , headers: { 'Content-Type': 'application/json' }
                 , body: JSON.stringify(
                     { username
                     , password })
                 , credentials: 'include'
                 })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.success) {
        this.setState({ isAuthenticated: true })
        this.initAfterAuth()
      } else {
        throw new Error(data.error || 'Login failed')
      }
    })
    .catch(error => {
      console.error('Login error:', error)
      window.alert(error.message)
    })
  }

  handleLogout = () => {
    fetch('/logout', 
          { method: 'POST'
          , credentials: 'include'
          })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        this.setState({ isAuthenticated: false, controllers: [] })
      }
    })
    .catch(error => {
      console.error('Logout error:', error)
    })
  }

  renderTabs() {
    const { controllers, tradingModes } = this.state
    const cookies = this.cookies

    let dataStore = {ldList: ldList, app: this, appWide: appWide}
    let tabs = []
    let panels = []

    tabs.push(<Tab key="service.status">Service Status</Tab>)
    panels.push(
      <TabPanel key="service.status">
        <ServiceStatus dataStore={dataStore} tradingModes={tradingModes} />
      </TabPanel>
    )

    if (cookies.riskTab) {
      tabs.push(<Tab key="risk">Risk</Tab>)
      panels.push(
        <TabPanel key="risk">
          <Risk dataStore={dataStore} />
        </TabPanel>
      )
    }

    if (cookies.straddleTab)  {
      tabs.push(<Tab key="straddle">Straddle</Tab>)
      panels.push(
        <TabPanel key="straddle">
          <StraddleTabs dataStore={dataStore}/>
        </TabPanel>)
    }

    if (cookies.parametersTab) {
      tabs.push(<Tab key="parameters">Parameters</Tab>)
      panels.push(
        <TabPanel key="parameters">
          <ParametersTabs dataStore={dataStore}/>
        </TabPanel>)
    }

    if (cookies.executionsTab) {
      tabs.push(<Tab key="executions">Executions</Tab>)
      panels.push(
        <TabPanel key="executions"> 
          <FilteredExecutionTable dataStore={dataStore} messagename='executions'/>
        </TabPanel>)
    }

    if (cookies.tradesTab) { 
      tabs.push(<Tab key="trades">Trades</Tab>)
      panels.push(
        <TabPanel key="trades">
          <FilteredTradesTable dataStore={dataStore} messagename='strategy-order-descriptions'/>
        </TabPanel>)
    }

    if (cookies.alertsTab) {
      tabs.push(<Tab key="alerts">Alerts</Tab>)
      panels.push(
        <TabPanel key="alerts">
          <FilteredAlertTable dataStore={dataStore} messagename='alerts'/>
        </TabPanel>)
    }

    if (cookies.chartsTab) {
      tabs.push(<Tab key="charts">Charts</Tab>)
      panels.push(
        <TabPanel key="charts">
          <ChartTabs dataStore={dataStore}/>
        </TabPanel>)
    }
    if (cookies.tradesAnalysisTab) {
      tabs.push(<Tab key="tradesanalysis">Trades Analysis</Tab>)
      panels.push(
        <TabPanel key="tradesanalysis">
          <TradesAnalysis url={this.state.tradesAnalysisURL}/>
        </TabPanel>)
    }

    if (cookies.jobsTab) {
      tabs.push(<Tab key="jobs">Jobs</Tab>)
      panels.push(
        <TabPanel key="jobs">
          <FilteredJobTable dataStore={dataStore} messagename='jobs'/>
        </TabPanel>)
    }

    if (cookies.rawBacks) {
      tabs.push(...controllers.map((ctr) => <Tab key={ctr.name}>{ctr.name}</Tab>))
      panels.push(...controllers.map((ctr) => (
        <TabPanel key={ctr.name}>
          {ctr.controller}
        </TabPanel>
      )))
    }

    return (
      <div className="App">
        <header className="App-header">
          <Tabs className="top-tabs">

            <TabList>
              {tabs}
            </TabList>

            {panels}

          </Tabs>
        </header>
        <footer className="App-footer">
          <button onClick={this.handleLogout}>Logout</button>
        </footer>
      </div>
    )
  }

  renderLoading() {
    return (
      <div className="App">
        <header className="App-header">
          Loading
        </header>
      </div>
    )
  }

  render() {    
    const { controllers, loading, isAuthenticated } = this.state

    console.log('render: isAuthenticated = ' + isAuthenticated + ' loading = ' + loading)

    if (!isAuthenticated) {
      return <LoginForm onLogin={this.handleLogin} />
    }

    if (loading) return this.renderLoading()
    if (controllers.length > 0) return this.renderTabs()
    return this.renderLoading()
  }
}

export default App
