import React from 'react'
import {UpdatedTable} from './UpdatedTable'
import chroma from 'chroma-js'

import './App.css'

class ScenarioTable extends UpdatedTable {
  constructor(props) {
    super(props)

    this.pnlScale = chroma.scale(['green', '#282c34', 'red']).domain([-1.0, 0.0, 1.0]).mode('lrgb')

    let statusStyle = function(params) {
      if (params.data.status === 'OK')    return {textAlign: 'center', backgroundColor: 'green'}
      if (params.data.status === 'SOFT_BREACH')  return {backgroundColor: 'orange'}
      if (params.data.status === 'HARD_BREACH') return {backgroundColor: 'red'}

      if (params.data.lastUpdated === undefined) return {backgroundColor: 'red'}
      return {backgroundColor:''}
    }

    this.state =
      { defaultColDef: {resizable: true
                       , sortable: true
                       , cellStyle: this.right
                       }
      , columnDefs: [ { width: 110
                      , headerName: 'Status'
                      , field: 'status'
                      , cellStyle: statusStyle
                      }
                    , { width: 80
                      , headerName: 'Back'
                      , field: 'back'
                      , valueFormatter: this.backFormatter('back')
                      }
                    , { width: 70
                      , headerName: 'Base Chg'
                      , field: 'baseValuationChangeFraction'
                      , valueFormatter: this.percentFormatter('baseValuationChangeFraction')
                      }
                    , { width: 70
                      , headerName: 'Vol Chg'
                      , field: 'volatilityChangeFraction'
                      , valueFormatter: this.percentFormatter('volatilityChangeFraction')
                      }
                    , { width: 100
                      , headerName: 'Time Chg (yrs)'
                      , field: 'vtexpChange'
                      , valueFormatter: this.fixedFormatter('vtexpChange', 5)
                      }
                    , { width: 100
                      , headerName: 'limit'
                      , field: 'limit'
                      , valueFormatter: this.currencyFormatter('limit')
                      }
                    , { width: 100
                      , headerName: 'pnl Impact'
                      , field: 'pnlImpact'
                      , valueFormatter: this.currencyFormatter('pnlImpact')
                      , cellStyle: this.pnlStyle
                      }
                    , { width: 85
                      , headerName: 'delta Impact'
                      , field: 'deltaImpact'
                      , valueFormatter: this.fixedFormatter('deltaImpact', 2)
                      }
                    , { width: 85
                      , headerName: 'vega Impact'
                      , field: 'vegaImpact'
                      , valueFormatter: this.fixedFormatter('vegaImpact', 0)
                      }
                    , { width: 95
                      , headerName: 'wVega Impact'
                      , field: 'wVegaImpact'
                      , valueFormatter: this.fixedFormatter('wVegaImpact', 0)
                      }
                    , { width: 160
                      , headerName: 'Last Updated'
                      , field: 'time'
                      , valueFormatter: this.timeFormatter('time')
                      }
                    ]
      }

    this.redraw(['status', 'pnlImpact'])
    this.gengrid()
  }

  pnlStyle = params => {
    const scaledPnl = params.data.pnlImpact / params.data.limit
    if (Math.abs(scaledPnl < 0.1)) return {textAlign: 'right', backgroundColor: ''}

    return {textAlign: 'right', backgroundColor: this.pnlScale(scaledPnl)}
  }


  getRowId = (params) => {
    return params.data.baseValuationChangeFraction + ' ' 
    + params.data.volatilityChangeFraction + ' '
    + params.data.vtexpChange + ' ' 
    + params.data.limit + params.data.back
  }

  redraw = (columns) => {
    if (this.api !== undefined && !this.api.isDestroyed()) {
      this.api.refreshCells({force:true, columns:columns})
    }
    setTimeout(() => {this.redraw(columns)}, 1000)
  }

  render() {
    if (this.api) this.api.onFilterChanged()
    let gridStyle = { width: this.totalWidth() }

    return (
      <div className="guif-div">
        <div className="ag-theme-balham-dark"
             style={gridStyle}>
          {this.grid}
        </div>
      </div>
    )
  }
}

export default ScenarioTable
