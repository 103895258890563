import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import StraddleOptionTable from './StraddleOptionTable'
import StraddleComboTable from './StraddleComboTable'

class StraddleView extends Component {
  constructor(props) {
    super(props)

    this.dataStore = props.dataStore
    this.back = props.back

    let streamUpdates = true
    this.dataStore.ldList.forEach(ld => streamUpdates &= ld.streamUpdates)
    this.state = { streamUpdates: streamUpdates }
  }

  onStreamUpdates = (params) => {
    let sval = params.target.checked
    this.dataStore.ldList.forEach(ld => ld.setStraddleUpdates(sval))
    this.setState({streamUpdates: sval})
  }

  render = () => {
    return (
      <div className="straddle-view" style={{marginBottom: '20px'}}>

        <div style={{ fontSize: '14px', textAlign: 'left'
                    , width: 'calc(100% - 50px)', marginTop: '10px', display: 'inline-block'}}>
          <div style={{display: 'inline-block'}}>
            Stream Updates <input type="checkbox"
                                  defaultChecked={this.state.streamUpdates}
                                  onChange={this.onStreamUpdates}/>
          </div>
        </div>

        <div className="straddle-view">
          <StraddleComboTable dataStore={this.dataStore}
                              type='futures'
                              title='Futures'
                              messagename='straddle-futures-sec-params'
                              back={this.back}
                              autoheight={true}
                              filter='default'/>
        </div>

        <div className="straddle-view">
          <StraddleOptionTable dataStore={this.dataStore}
                               height='800px'                               
                               title='Options'
                               back={this.back}
                               filter='default'/>
        </div>

        <div className="straddle-view"> 
          <StraddleComboTable dataStore={this.dataStore}
                              type='combos'
                              height='500px'
                              messagename='straddle-combos-sec-params' 
                              title='Combos'
                              back={this.back}
                              filter='default'/>
        </div>

        <div className="straddle-view">
          <StraddleOptionTable dataStore={this.dataStore}
                               height='300px'
                               title='Through Market Options'
                               back={this.back}
                               filter='through-market'/>
        </div>

        <div className="straddle-view">
          <StraddleComboTable dataStore={this.dataStore}
                              type='combos'
                              height='300px'
                              messagename='straddle-combos-sec-params'
                              title='Though Market Combos'
                              back={this.back}
                              filter='through-market'/>
        </div>
      </div>
    ) 
  }
}

class StraddleTabs extends Component {
  constructor(props) {
    super(props)
    this.dataStore = props.dataStore
    this.back = props.back
  }

  render = () => {
    return (
      <div className="straddle-view" style={{marginBottom: '20px'}}>
        <Tabs className="sub-tabs">
          <TabList>
            {this.dataStore.ldList.map(ld => <Tab key={ld.back}>{ld.back}</Tab>)}
          </TabList>

          {this.dataStore.ldList.map(ld => {
            let dataStore = {ldList: [ld], appWide: this.dataStore.appWide}
            return <TabPanel key={ld.back}><StraddleView dataStore={dataStore} back={ld.back}/></TabPanel>
          })}
        </Tabs>
      </div>
    )
  }
}

StraddleView.propTypes =
  { dataStore: PropTypes.object
  , back: PropTypes.string
  }

StraddleTabs.propTypes =
  { dataStore: PropTypes.object
  , back: PropTypes.string
  }

export {StraddleView, StraddleTabs}
