import React, { Component } from 'react'
import PropTypes from 'prop-types'

class TradesAnalysis extends Component {

  constructor(props) {
    super(props)
    this.iframeURL = props.url
  }

  render = () => {
    if (this.iframeURL) {
      let iframe = `<iframe width="2000" height="1250" src="${this.iframeURL}" frameborder="0" style="border:0" allowfullscreen></iframe>`

      return (
        <div className="guif-div">
          <div dangerouslySetInnerHTML={{__html: iframe}}/>
        </div>
      )
    }
    else {
      return (
        <div className="guif-div">
          No tradesAnalysisURL in config
        </div>
      )
    }
  }
}

TradesAnalysis.propTypes =
{ url: PropTypes.string
}

export {TradesAnalysis}
