import React, {Component} from 'react'
import PropTypes from 'prop-types'

class MuteRenderer extends Component {
  constructor(props) {
    super(props)
    this.multiGui = props.multiGui

    if (this.multiGui === true) {
      // The multiGui switch is needed (for now) because multiGui has an abstracted
      // datastore backing it, wheras the single ones have there state entirely in their
      // React state object.  So different logic on construction and onChange.  Will
      // refactor the single versions (at some point) to use the more generic datastore
      if (props.data.back) {
        let ld = props.muteComponent[props.data.back]

        this.state = { muteComponent: ld
                     , mute: ld[props.appField][props.data[props.idField]]
                     , muteID: props.data[props.idField]
                     , appField: props.appField
                     }
      }
    } else {
      this.state = { muteComponent: props.muteComponent
                   , idField: props.idField
                   , muteID: props.data[props.idField]
                   , mute: props.muteComponent.state.mute[props.data[props.idField]]
                   , appField: props.appField
                   }
    }
  }

  render() {
    if (this.state) {
      let checked = this.state.mute === true
      return <input type="checkbox" onChange={this.onChange} checked={checked}/>
    }

    return ''  // Just an empty DOM element if there should be no checkbox here
  }

  onChange = (params) => {
    // update our own state (if we live long enough to see it)
    this.setState({mute: params.target.checked})

    if (this.multiGui) {
      let mc = this.state.muteComponent
      let muteMap = mc[this.props.appField]
      muteMap[this.state.muteID] = params.target.checked
    } else {
      // Update the state of the underlying grid
      let mc = this.state.muteComponent
      let muteList = mc.state.mute
      muteList[this.state.muteID] = params.target.checked
      mc.setState({mute: muteList})
      mc.pctr[this.state.appField] = muteList
    }
  }
}

MuteRenderer.propTypes =
  { muteComponent: PropTypes.object
  , data: PropTypes.object
  , appField: PropTypes.string
  , idField: PropTypes.string
  , multiGui: PropTypes.bool
  }

export default MuteRenderer
