import React, {Component} from 'react'
import {UpdatedTable} from './UpdatedTable'
import {toNumber, notify} from './util.jsx'
import PropTypes from 'prop-types'

import chroma from 'chroma-js'

import {getValue, bidStyle, askStyle, singleValueStyle} from './util.jsx'
import './App.css'
import './BrokerMarkets.css'

function checkValid(thing) {
  return typeof thing === 'number'
    || (typeof thing === 'string' && thing.trim())
}

let tradeDiv = undefined   // There can be only one ...

class TradeButton extends Component {
  constructor(props) {
    super(props)
    this.defaultAccount = props.account
    this.data = props.data
    this.listenerData = props.listenerData

    // Check if there is any reason to enable this
    this.disabled = 'disabled'
    if (checkValid(this.data.hedge.contract) && checkValid(this.data.hedge.weight))
      this.disabled = ''
    this.data.components.forEach(comp => {
      if (checkValid(comp.contract) && checkValid(comp.weight)) this.disabled = ''
    })

    // If there is at least one reason to enable, check if there is any reason to
    // disable
    if (this.disabled === '') {
      if (typeof this.data.hedge.contract === 'object') this.disabled = 'disabled'
      if (typeof this.data.hedge.weight === 'object') this.disabled = 'disabled'
      this.data.components.forEach(comp => {
        if (typeof comp.contract === 'object') this.disabled = 'disabled'
        if (typeof comp.weight === 'object') this.disabled = 'disabled'
      })
    }
  }

  onClick = event => {
    if (this.setPrice) delete this.setPrice
    if (this.setQty) delete this.setQty

    if (tradeDiv !== undefined) {
      tradeDiv.parentNode.removeChild(tradeDiv)
    }
    tradeDiv = document.createElement('div')

    tradeDiv.style.backgroundColor = 'white'
    tradeDiv.style.position = 'absolute'
    tradeDiv.style.left = (event.clientX - 10) + 'px'
    tradeDiv.style.top = (event.clientY + 50) + 'px'

    let componentInputs = []
    let componentInputMap = {}

    let genExecutions = () => {
      let executions = []
      let genExecution = input => {
        if (input.qty.value && input.qty.value.trim() !== '') {
          let nqty = Number(input.qty.value)

          if (!isNaN(nqty) && nqty !== 0) {
            let execution = { contract: input.contract
                            , qty: nqty
                            , weight: input.weight
                            }

            if (input.px.value && input.px.value.trim() !== '') {
              let npx = Number(input.px.value)
              if (!isNaN(npx)) execution.px = npx
            }

            executions.push(execution)
          }
        }
      }
      componentInputs.forEach(input => {
        if (Array.isArray(input)) input.forEach(genExecution)
        else genExecution(input)
      })

      return executions
    }

    let updatePx = () => {
      let executions = genExecutions()

      this.listenerData.POST('/broker-markets/get-prices', executions)
      .then(res => res.json())
      .then(res => {
        let totalPx = 0
        let wadjustsum = 0 // sum of adjustable weights

        let handleComponent = (execution, c) => {
          console.log('processing c ' + JSON.stringify(c))
          c.marketPx.value = execution.marketPx
          if (execution.marketPx !== '') {
            let px = Number(execution.marketPx)
            if (!c.setPrice) {
              c.px.value = px
              if (!c.isHedge) wadjustsum += Math.abs(Number(c.qty.value))
            }

            if (!c.isHedge) totalPx += c.px.value * Number(c.qty.value)
          }
        }

        res.forEach(execution => {
          let c = componentInputMap[execution.contract]
          if (Array.isArray(c)) c.forEach(cc => handleComponent(execution, cc))
          else handleComponent(execution, c)
        })

        let wpx = totalPx / Number(qty.value)
        if (!this.setPrice) {
          px.value = Math.round(wpx * 1e9 + Number.EPSILON) / 1e9
          if (sell.checked) px.value *= -1
        } else {
          // need to adjust component prices to match set price
          if (wadjustsum === 0) {
            // early abort
            notify('Inconsistent prices, nothing to adjust', 'red')
          } else {
            let delta = wpx - this.setPrice
            let inc = delta / wadjustsum * Number(qty.value)

            componentInputs.forEach(input => {
              console.log('checking component, ' + input.isHedge + ' ' + input.setPrice)
              if (!input.isHedge && !input.setPrice && Number(input.qty.value) !== 0) {
                let apx = Number(input.px.value) - inc * Math.sign(Number(input.qty.value))
                input.px.value = Math.round(apx * 1e9 + Number.EPSILON) / 1e9
              }
            })
          }
        }
      })
    }

    let recalcQtys = () => {
      componentInputs.forEach(input => {
        if (input.setQty) input.qty.value = input.setQty
        else {
          input.qty.value = Math.round(this.setQty * input.weight)
          if (sell.checked) input.qty.value *= -1
        }
      })

      updatePx()
    }

    let genInputForComponent = (component, isHedge) => {
      if (component.weight && component.contract) {
        let cid = 'trade-popup-comp-' + component.contract + '-' + component.weight

        let cinput = { weight: component.weight
                     , contract: component.contract
                     , isHedge: isHedge
                     }
        cinput.label = document.createElement('label')
        cinput.label.innerHTML = cinput.contract
        cinput.label.for = cid

        cinput.qty = document.createElement('input')
        cinput.qty.id = cid
        cinput.qty.size = 5
        cinput.qty.type = 'text'
        cinput.qty.style.margin = '5px'
        cinput.qty.style.marginRight = '30px'
        let doqty = (doUpdate) => {
          let nqty = toNumber(cinput.qty.value)
          if (nqty) {
            cinput.qty.style.color = 'blue'
            cinput.setQty = nqty
            recalcQtys()
          } else {
            if (cinput.qty.value.trim() === '') {
              delete cinput.setQty
              cinput.qty.style.color = 'black'
              if (doUpdate) recalcQtys()
            } else {
              cinput.qty.style.color = 'red'
            }
          }
        }
        cinput.qty.oninput = () => {doqty(false)}
        cinput.qty.onchange = () => {doqty(true)}

        cinput.pxLabel = document.createElement('label')
        cinput.pxLabel.innerHTML = 'Px'
        cinput.pxLabel.for = cid + '-px'

        cinput.px = document.createElement('input')
        cinput.px.id = cid + '-px'
        cinput.px.size = 5
        cinput.px.type = 'text'
        cinput.px.style.margin = '5px'
        cinput.px.style.marginRight = '30px'
        let dopx = (doUpdate) => {
          let price = toNumber(cinput.px.value)
          if (price) {
            cinput.setPrice = price
            cinput.px.style.color = 'blue'
            updatePx()
          } else {
            if (cinput.px.value.trim() === '') {
              delete cinput.setPrice
              cinput.px.style.color = 'black'
              if (doUpdate) updatePx()
            } else {
              cinput.px.style.color = 'red'
            }
          }
        }
        cinput.px.oninput = () => {dopx(false)}
        cinput.px.onchange = () => {dopx(true)}

        cinput.marketPxLabel = document.createElement('label')
        cinput.marketPxLabel.innerHTML = 'Market'
        cinput.marketPxLabel.for = cid + '-mpx'

        cinput.marketPx = document.createElement('input')
        cinput.marketPx.id = cid + '-mpx'
        cinput.marketPx.size = 5
        cinput.marketPx.type = 'text'
        cinput.marketPx.style.margin = '5px'
        cinput.marketPx.style.marginRight = '30px'
        cinput.marketPx.readOnly = true

        componentInputs.push(cinput)
        if (!componentInputMap[cinput.contract]) {
          componentInputMap[cinput.contract] = cinput
        } else if (Array.isArray(componentInputMap[cinput.contract])) {
          componentInputMap[cinput.contract].push(cinput)
        } else {
          componentInputMap[cinput.contract] = [componentInputMap[cinput.contract], cinput]
        }
      }
    }

    this.data.components.forEach(comp => genInputForComponent(comp, false))
    genInputForComponent(this.data.hedge, true)

    let buy = document.createElement('input')
    buy.id = 'trade-popup-buy'
    buy.type = 'radio'
    buy.name = 'side'
    buy.value = 'buy'
    buy.checked = true
    buy.onchange = recalcQtys

    let buyLabel = document.createElement('label')
    buyLabel.innerHTML = 'Buy'
    buyLabel.for = 'trade-popup-buy'

    let sell = document.createElement('input')
    sell.id = 'trade-popup-sell'
    sell.type = 'radio'
    sell.name = 'side'
    sell.value = 'sell'
    sell.onchange = recalcQtys

    let sellLabel = document.createElement('label')
    sellLabel.innerHTML = 'Sell'
    sellLabel.for = 'trade-popup-sell'
    sellLabel.style.marginRight = '30px'

    let account = document.createElement('input')
    account.type = 'text'
    account.size = 5
    account.style.padding = '5px'
    account.id = 'trade-popup-account'
    account.value = this.defaultAccount

    let accountLabel = document.createElement('label')
    accountLabel.innerHTML = 'Account'
    accountLabel.for = 'trade-popup-account'
    accountLabel.style.padding = '5px'

    let qty = document.createElement('input')
    qty.id = 'trade-popup-qty'
    qty.size = 5
    qty.type = 'text'
    qty.style.margin = '5px'
    qty.style.marginRight = '30px'
    qty.oninput = () => {
      let nqty = toNumber(qty.value)
      if (nqty) {
        this.setQty = nqty
        qty.style.color = 'blue'
        recalcQtys()
      } else {
        qty.style.color = 'red'
      }
    }

    if (this.data.ownQty && this.data.ownQty.trim() !== '') {
      qty.value = this.data.ownQty
      qty.oninput()
    }

    let qtyLabel = document.createElement('label')
    qtyLabel.innerHTML = 'Combo Qty'
    qtyLabel.for = 'trade-popup-qty'

    let px = document.createElement('input')
    px.id = 'trade-popup-px'
    px.size = 5
    px.type = 'text'
    px.style.margin = '5px'
    px.style.marginRight = '30px'
    let totalPx = (doUpdate) => {
      let price = toNumber(px.value)
      if (price) {
        if (sell.checked) price *= -1
        this.setPrice = price
        updatePx()
        px.style.color = 'blue'
      } else {
        if (px.value.trim() === '') {
          delete this.setPrice
          px.style.color = 'black'
          if (doUpdate) updatePx()
        } else {
          px.style.color = 'red'
        }
      }
    }
    px.oninput = () => {totalPx(false)}
    px.onchange = () => {totalPx(true)}


    if (this.data.ownBid && this.data.ownBid.trim() !== '') {
      px.value = this.data.ownBid
      px.oninput()
    } else if (this.data.ownAsk && this.data.ownAsk.trim() !== '') {
      sell.checked = 'checked'
      px.value = this.data.ownAsk
      recalcQtys()
      px.oninput()
    }

    let pxLabel = document.createElement('label')
    pxLabel.innerHTML = 'Px'
    pxLabel.for = 'trade-popup-px'

    let trade = document.createElement('button')
    trade.innerHTML = 'Trade'
    trade.style.margin = '5px'
    trade.onclick = () => {
      let executions = genExecutions()
      let request = {executions: executions, broker: this.data.broker, account: account.value}
      this.listenerData.POST('/broker-markets/send-executions', request)
      .then(res => res.json())
      .then(res => {
        if (res === 'OK') {
          notify('Executions sent', 'green')
        } else {
          notify('Executions send failed', 'red')
        }
      })

      tradeDiv.parentNode.removeChild(tradeDiv)
      tradeDiv = undefined
    }

    let cancel = document.createElement('button')
    cancel.innerHTML = 'Cancel'
    cancel.style.margin = '5px'
    cancel.onclick = () => {
      tradeDiv.parentNode.removeChild(tradeDiv)
      tradeDiv = undefined
      notify('Trade request canceled', 'yellow')
    }

    let marketDiv = document.createElement('div')
    let marketText = document.createTextNode(this.data.name + ': ')
    marketDiv.style.float = 'left'
    marketDiv.style.padding = '5px'
    marketDiv.appendChild(marketText)
    tradeDiv.appendChild(marketDiv)

    {
      let cdiv = document.createElement('div')
      cdiv.style.float = 'left'

      let qdiv = document.createElement('div')
      qdiv.appendChild(qtyLabel)
      qdiv.appendChild(qty)

      let pdiv = document.createElement('div')
      pdiv.style.float = 'right'
      pdiv.appendChild(pxLabel)
      pdiv.appendChild(px)

      cdiv.appendChild(qdiv)
      cdiv.appendChild(pdiv)
      tradeDiv.appendChild(cdiv)
    }

    componentInputs.forEach(input => {
      let cdiv = document.createElement('div')
      cdiv.style.float = 'left'

      let qdiv = document.createElement('div')
      qdiv.style.float = 'right'
      qdiv.appendChild(input.label)
      qdiv.appendChild(input.qty)

      let pdiv = document.createElement('div')

      {
        let ediv = document.createElement('div')
        let idiv = document.createElement('div')
        idiv.style.float = 'right'
        idiv.appendChild(input.pxLabel)
        idiv.appendChild(input.px)
        ediv.appendChild(idiv)
        pdiv.appendChild(ediv)
      }

      {
        let mdiv = document.createElement('div')
        let idiv = document.createElement('div')
        idiv.style.float = 'right'
        idiv.appendChild(input.marketPxLabel)
        idiv.appendChild(input.marketPx)
        mdiv.appendChild(idiv)
        pdiv.appendChild(mdiv)
      }


      cdiv.appendChild(qdiv)
      cdiv.appendChild(pdiv)
      tradeDiv.appendChild(cdiv)
    })

    {
      let idiv = document.createElement('div')
      idiv.appendChild(buy)
      idiv.appendChild(buyLabel)
      idiv.appendChild(sell)
      idiv.appendChild(sellLabel)
      idiv.style.float = 'left'
      idiv.style.padding = '5px'

      let adiv = document.createElement('div')
      adiv.style.paddingTop = '10px'
      adiv.appendChild(accountLabel)
      adiv.appendChild(account)

      idiv.appendChild(adiv)
      tradeDiv.appendChild(idiv)
    }

    tradeDiv.appendChild(trade)
    tradeDiv.appendChild(cancel)
    document.body.appendChild(tradeDiv)
  }

  render() {
    return <button type="button" onClick={this.onClick} disabled={this.disabled}>Trade</button>
  }
}

TradeButton.propTypes =
  { data: PropTypes.object
  , account: PropTypes.string
  , listenerData: PropTypes.object
  }

class BrokerMarkets extends UpdatedTable {
  constructor(props) {
    super(props)

    this.theoScale = chroma.scale(['red', '#282c34', 'green']).domain([-1.0, 0.0, 1.0]).mode('lab')
    this.adjusterScale = chroma.scale(['red', '#282c34', 'green']).domain([-1.0, 0.0, 1.0]).mode('lrgb')
    this.errorScale = chroma.scale(['#282c34', 'red']).mode('lab')
    this.theoThreshold = 0.01
    this.adjThreshold = 0.01
    this.errThreshold = 0.01

    this.updateConfig(this.listenerData.getConfig())
    this.listenerData.addListener('config', this.updateConfig)

    let genGetter = function(field) {
      return function(params) {
        let val = getValue(params.data, field)
        if (typeof val === 'object') return val.value
        else                         return val
      }
    }

    let genStyle = function(field) {
      return function(params) {
        let val = getValue(params.data, field)
        if (typeof val === 'object' && !val.valid) return {color: 'red'}
        else                                       return {color: 'white'}
      }
    }

    let genComponentGetter = function(pos, field) {
      return function(params) {
        let components = params.data.components
        if (components && components.length > pos) {
          let val = components[pos][field]
          if (typeof val === 'object') return val.value
          else                         return val
        }
        else return undefined
      }
    }

    let genComponentStyle = function(pos, field) {
      return function(params) {
        let components = params.data.components
        if (components && components.length > pos) {
          let val = components[pos][field]
          if (typeof val === 'object' && !val.valid) return {color: 'red'}
          else                                       return {color: 'white'}
        }
        else return {color: 'white'}
      }
    }

    let genComponentSetter = function(pos, field) {
      return function(params) {
        let components = params.data.components
        while (components.length <= pos) components.push({})
        let component = components[pos]

        component[field] = params.newValue
        return true
      }
    }

    this.state =
      { defaultColDef: { resizable: true
                       , sortable: true
                       , cellStyle: this.right
                       , onCellValueChanged: this.onCellValueChanged
                       }
      , columnDefs: [ { width: 75
                      , cellStyle: this.center
                      , cellRenderer: TradeButton
                      , cellRendererParams: { account: this.defaultAccount
                                            , listenerData: this.listenerData
                                            }
                      }

                    , { width: 80
                      , headerName: 'Name'
                      , field: 'name'
                      , editable: true
                      }
                    , { width: 50
                      , headerName: 'W1'
                      , valueGetter: genComponentGetter(0, 'weight')
                      , valueSetter: genComponentSetter(0, 'weight')
                      , cellStyle:   genComponentStyle(0, 'weight')
                      , editable: true
                      }
                    , { width: 100
                      , headerName: 'Contract 1'
                      , valueGetter: genComponentGetter(0, 'contract')
                      , valueSetter: genComponentSetter(0, 'contract')
                      , cellStyle:   genComponentStyle(0, 'contract')
                      , editable: true
                      }
                    , { width: 50
                      , headerName: 'W2'
                      , valueGetter: genComponentGetter(1, 'weight')
                      , valueSetter: genComponentSetter(1, 'weight')
                      , cellStyle:   genComponentStyle(1, 'weight')
                      , editable: true
                      }
                    , { width: 100
                      , headerName: 'Contract 2'
                      , valueGetter: genComponentGetter(1, 'contract')
                      , valueSetter: genComponentSetter(1, 'contract')
                      , cellStyle:   genComponentStyle(1, 'contract')
                      , editable: true
                      }
                    , { width: 50
                      , headerName: 'W3'
                      , valueGetter: genComponentGetter(2, 'weight')
                      , valueSetter: genComponentSetter(2, 'weight')
                      , cellStyle:   genComponentStyle(2, 'weight')
                      , editable: true
                      }
                    , { width: 100
                      , headerName: 'Contract 3'
                      , valueGetter: genComponentGetter(2, 'contract')
                      , valueSetter: genComponentSetter(2, 'contract')
                      , cellStyle:   genComponentStyle(2, 'contract')
                      , editable: true
                      }
                    , { width: 50
                      , headerName: 'W4'
                      , valueGetter: genComponentGetter(3, 'weight')
                      , valueSetter: genComponentSetter(3, 'weight')
                      , cellStyle:   genComponentStyle(3, 'weight')
                      , editable: true
                      }
                    , { width: 100
                      , headerName: 'Contract 4'
                      , valueGetter: genComponentGetter(3, 'contract')
                      , valueSetter: genComponentSetter(3, 'contract')
                      , cellStyle:   genComponentStyle(3, 'contract')
                      , editable: true
                      }
                    , { width: 60
                      , headerName: 'WF'
                      , field: 'hedge.weight'
                      , valueGetter: genGetter('hedge.weight')
                      , cellStyle: genStyle('hedge.weight')
                      , editable: true
                      }
                    , { width: 60
                      , headerName: 'Future'
                      , field: 'hedge.contract'
                      , valueGetter: genGetter('hedge.contract')
                      , cellStyle: genStyle('hedge.contract')
                      , editable: true
                      }
                    , { width: 75
                      , headerName: 'Ref Price'
                      , field: 'hedge.refPrice'
                      , valueGetter: genGetter('hedge.refPrice')
                      , cellStyle: genStyle('hedge.refPrice')
                      , editable: true
                      }
                    , { width: 75
                      , headerName: 'Live Price'
                      , field: 'hedge.livePrice'
                      , valueFormatter: this.fixedFormatter('hedge.livePrice', 2)
                      , valueGetter: genGetter('hedge.livePrice')
                      , cellStyle: genStyle('hedge.livePrice')
                      }
                    , { width: 70
                      , headerName: 'Broker'
                      , field: 'broker'
                      , editable: true
                      }
                    , { width: 65
                      , headerName: 'bBid'
                      , field: 'brokerBid'
                      , editable: true
                      , cellStyle: this.bidStyle('broker')
                      }
                    , { width: 65
                      , headerName: 'bAsk'
                      , field: 'brokerAsk'
                      , cellStyle: this.askStyle('broker')
                      , editable: true
                      }
                    , { width: 60
                      , headerName: 'bQty'
                      , field: 'brokerQty'
                      , editable: true
                      }
                    , { width: 65
                      , headerName: 'oBid'
                      , field: 'ownBid'
                      , editable: true
                      , cellStyle: this.bidStyle('own')
                      }
                    , { width: 65
                      , headerName: 'oAsk'
                      , field: 'ownAsk'
                      , cellStyle: this.askStyle('own')
                      , editable: true
                      }
                    , { width: 60
                      , headerName: 'oQty'
                      , field: 'ownQty'
                      , editable: true
                      }
                    , { width: 65
                      , headerName: 'mBid'
                      , field: 'marketBid'
                      , valueFormatter: this.fixedFormatter('marketBid', 2)
                      , cellStyle: this.bidStyle('market')
                      }
                    , { width: 80
                      , headerName: 'UnAdjTheo'
                      , field: 'theo'
                      , valueFormatter: this.fixedFormatter('theo', 3)
                      }
                    , { width: 65
                      , headerName: 'mAsk'
                      , field: 'marketAsk'
                      , valueFormatter: this.fixedFormatter('marketAsk', 2)
                      , cellStyle: this.askStyle('market')
                      }

                    , { width: 65
                      , headerName: 'Adjust'
                      , field: 'adjustment'
                      , valueFormatter: this.signedFixedFormatter('adjustment', 3)
                      , cellStyle: this.adjustmentStyle('adjustment')
                      }
                    , { width: 65
                      , headerName: 'Delta'
                      , field: 'delta'
                      , valueFormatter: this.fixedFormatter('delta', 3)
                      }
                    , { width: 65
                      , headerName: 'Gamma'
                      , field: 'gamma'
                      , valueFormatter: this.fixedFormatter('gamma', 4)
                      }
                    , { width: 65
                      , headerName: 'Vega'
                      , field: 'vega'
                      , valueFormatter: this.fixedFormatter('vega', 2)
                      }
                    , { width: 65
                      , headerName: 'bError'
                      , field: 'buyError'
                      , valueFormatter: this.fixedFormatter('buyError', 2)
                      , cellStyle: this.errStyle('buyError')
                      }
                    , { width: 65
                      , headerName: 'sError'
                      , field: 'sellError'
                      , valueFormatter: this.fixedFormatter('sellError', 2)
                      , cellStyle: this.errStyle('sellError')
                      }
                    , { width: 65
                      , headerName: 'pAlpha'
                      , field: 'priceAlpha'
                      , valueFormatter: this.fixedFormatter('priceAlpha', 3)
                      }
                    , { width: 65
                      , headerName: 'APC'
                      , field: 'apc'
                      , valueFormatter: this.exponentialFormatter('apc', 1)
                      }
                    ]
      }

    this.genselectablegrid()
  }

  onCellValueChanged = () => {
    let brokerMarkets = []
    this.api.forEachNode(function(rowNode) {
      brokerMarkets.push(rowNode.data)
    })

    this.changeMarkets(brokerMarkets)
  }

  onCellClickedDerived = params => {
    if (params.colDef.field === 'name') {
      params.node.setSelected(true, true)
    } else {
      this.api.deselectAll()
    }
  }

  onCellFocusedDerived = params => {
    // If we tab off of name cell, change row to be unselected
    if (params.column && params.column.colDef.field !== 'name') {
      this.api.deselectAll()
    }
  }

  componentWillUnmountDerived() {
    this.listenerData.removeListener('config', this.updateConfig)
  }

  updateConfig = config => {
    if (typeof config === 'object') {
      if (typeof config.brokerMarkets === 'object') {
        if (config.brokerMarkets.defaultHedge) {
          this.defaultHedge = config.brokerMarkets.defaultHedge
        }

        if (config.brokerMarkets.account) {
          this.defaultAccount = config.brokerMarkets.account
        }
      }
    }
  }

  changeMarkets = brokerMarkets => {
    this.listenerData.removeListener(this.messageName, this.update)
    this.listenerData.POST('/broker-markets/update', brokerMarkets)
    .then(res => res.json())
    .then(
      result => {
        this.listenerData.seqnum['broker-markets'] = result.seqnum
        console.log('setting seqnum = ' + result.seqnum)
        this.update(result.data, this.listenerData.back, false)
      })
  }

  getRowId = (params) => {
    return params.data.name
  }

  onAddMarket = () => {
    let nid = Object.keys(this.rowData[this.listenerData.back]).length
    let allGood = false
    var name, brokerMarkets

    while (!allGood) {
      let itBrokerMarkets = []
      let itName = 'Market ' + nid++
      let itAllGood = true
      this.api.forEachNode(rowNode => {
        itBrokerMarkets.push(rowNode.data)
        if (rowNode.data.name === itName) itAllGood = false
      })

      brokerMarkets = itBrokerMarkets
      name = itName
      allGood = itAllGood
    }

    let hedge = {}
    if (this.defaultHedge) hedge = JSON.parse(JSON.stringify(this.defaultHedge))

    brokerMarkets.push({name: name, components:[], hedge:hedge})
    this.changeMarkets(brokerMarkets)
  }

  onDeleteMarket = () => {
    let brokerMarkets = []
    this.api.forEachNode(rowNode => {
      let good = true
      this.api.getSelectedRows().forEach(row => {
        if (row.name === rowNode.data.name) good = false
      })

      if (good) brokerMarkets.push(rowNode.data)
    })

    this.api.deselectAll()
    this.api.refreshCells({force:true})
    this.changeMarkets(brokerMarkets)
  }

  bidStyle = (prefix) => {
    let This = this
    return function(params) {
      let theo = params.data['theo']
      let bid = Number(params.data[prefix + 'Bid'])
      let marketWidth = params.data['marketWidth']

      return bidStyle(theo, bid, This.theoThreshold, This.theoScale, marketWidth)
    }
  }

  askStyle = (prefix) => {
    let This = this
    return function(params) {
      let theo = params.data['theo']
      let ask = Number(params.data[prefix + 'Ask'])
      let marketWidth = params.data['marketWidth']

      return askStyle(theo, ask, This.theoThreshold, This.theoScale, marketWidth)
    }
  }

  adjustmentStyle = (field) => {
    let This = this
    return function(params) {
      const adjustment = params.data[field]
      const adjThreshold = This.adjThreshold
      return singleValueStyle(adjustment, adjThreshold, This.adjusterScale)
    }
  }

  errStyle = (field) => {
    let This = this
    return function(params) {
      const error = params.data[field]
      const errThreshold = This.errThreshold
      return singleValueStyle(error, errThreshold, This.errorScale)
    }
  }

  render() {
    let gridStyle = { width: this.totalWidth() }

    return (
      <div className="guif-div">
        <div className="ag-theme-balham-dark broker-markets"
             style={gridStyle}>
          {this.grid}
        </div>

        <div style = {{textAlign: 'left', width: 'calc(100% - 50px'}}>
          <button onClick={this.onAddMarket}>Add Market</button>
          <button onClick={this.onDeleteMarket}>Delete Market</button>
        </div>
      </div>
    )
  }
}

export default BrokerMarkets
