import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import TimeSeriesPlot from './TimeSeriesPlot'

import moment from 'moment'

class PNLChart extends Component {
  constructor(props) {
    super(props)

    this.type = props.type
    this.dataStore = props.dataStore
    this.back = props.back

    this.dataStore.ldList.forEach(ld => {
      if (ld.back === this.back) this.listenerData = ld
    })

    this.myRef = React.createRef()
    this.selectRef = React.createRef()
    this.margin = {top: 20, right: 30, bottom:30, left: 80}

    this.atmVolIndex = -1    // -1 -> last available, otherwise index from start
    this.atmVolLength = 0

    this.setStrategy()

    this.dy = 0
    this.mountedWithTimeseries = false

    this.colors = { 'instantPnl': 'red'
                  , 'adjustmentCost': 'blue'
                  , 'alphaPremium': 'pink'
                  , 'volSlippage': 'green'
                  , 'basePriceSlippage': 'yellow'
                  , 'forwardSlippage': 'orange'
                  , 'alphaSlippage': 'purple'
                  , 'residualSlippage': 'cyan'
                  }

    this.sync = props.sync ? props.sync : this
    this.tsPlot = new TimeSeriesPlot(this.myRef, this.sync)
    if (props.sync) this.sync.register(this.tsPlot)

    this.atmBaseVolsSync = genSyncer()
    this.atmBaseVolsSync.register(this.tsPlot)

    this.listenerData.addListener('service-status-table', this.update)
  }

  setStrategy = () => {
    this.strategy = ''
    if (this.listenerData && this.listenerData.timeSeries && this.listenerData.timeSeries.instrumentation) {
      let keys = Object.keys(this.listenerData.timeSeries.instrumentation)

      if (keys.length > 0)
        this.strategy = Object.keys(this.listenerData.timeSeries.instrumentation)[0]
    }
  }

  componentDidMount = () => {
    if (!this.listenerData.timeSeries || !this.listenerData.timeSeries.underlying) return
    this.mountedWithTimeseries = true

    let underKeys = Object.keys(this.listenerData.timeSeries.underlying).filter(
      val => !val.endsWith('-theo'))

    underKeys.forEach(val => {
      let option = document.createElement('OPTION')
      option.appendChild(document.createTextNode(val))

      this.selectRef.current.appendChild(option)
    })

    let stats = [ 'pnl', 'delta', 'theta', 'vega', 'instantPnl', 'adjustmentCost', 'alphaPremium'
                , 'volSlippage', 'basePriceSlippage', 'forwardSlippage', 'alphaSlippage', 'residualSlippage', 'PnL with slippage'
                , 'ATM Base Vols', 'Forward ATM Base Vols'
                ]

    let deltaVols = {}
    if (this.listenerData.deltaVols.length > 0) deltaVols = this.listenerData.deltaVols.slice(-1)[0]
    for (let delta in deltaVols) {
      stats.push('Vol Delta = ' + delta)
    }

    stats.forEach(val => {
      let option = document.createElement('OPTION')
      let text = document.createTextNode(val)
      option.appendChild(text)

      this.selectRef.current.appendChild(option)
      if (this.type === val) {
        this.selectRef.current.value = this.type
      }
    })

    this.update()
    window.addEventListener('resize', this.update)
    window.addEventListener('keydown', this.keydown)
  }

  componentWillUnmount = () => {
    this.listenerData.removeListener('service-status-table', this.update)
    window.removeEventListener('resize', this.update)
    window.removeEventListener('keydown', this.keydown)
    if (this.props.sync) this.sync.remove(this.tsPlot)
  }

  keydown = (e) => {
    this.tsPlot.keydown(this, e)

    if (this.tsPlot.mouseActive) {
      let incVal = e.shiftKey === true ? 60 : 1   // normal updates come every 5 sec, using shift with jump by 5 min
      if (this.atmVolIndex !== -1) {
        if      (e.key === 'ArrowRight') this.atmVolIndex += incVal
        else if (e.key === 'ArrowLeft')  this.atmVolIndex -= incVal

        // Make sure atmVolIndex is something valid.
        if (this.atmVolIndex < 0) this.atmVolIndex = 0
        if (this.atmVolIndex >= this.atmVolLength) this.atmVolIndex = -1
      } else {
        // On last chart, move to second to last
        if (e.key === 'ArrowLeft' && this.atmVolLength > 1) {
          if (this.atmVolLength > incVal) this.atmVolIndex = this.atmVolLength - 1 - incVal
          else                            this.atmVolIndex = 0
        }
      }

      if (e.key === 'ArrowDown' && this.atmVolLength > 0) this.atmVolIndex = 0
      if (e.key === 'ArrowUp')                            this.atmVolIndex = -1

      this.update()
      e.preventDefault()
    }
  }

  update = () => {
    if (this.disableUpdates) return
    if (!this.mountedWithTimeseries) this.componentDidMount()
    if (!this.mountedWithTimeseries) return

    let This = this    // For use in event handlers
    let tss = []
    let yextractors = []
    let xextractors = []
    let dextractors = []
    let labels = []
    let title = undefined
    let xlabel = undefined
    let xfixed = undefined
    let yfixed = undefined

    This.ready = false
    this.setStrategy()
    if (this.strategy === '') return  // Nothing set yet, wait for first update
    if (!this.listenerData.timeSeries.slippage) return
    if (!this.listenerData.timeSeries.instrumentation) return
    if (!this.listenerData.timeSeries.underlying) return

    let doVolChart = function(chart, key, fits, delta) {
      chart.tsPlot.setSync(chart.atmBaseVolsSync)
      chart.tsPlot.setXTime(false)

      chart.atmVolLength = fits.length

      if (fits.length > 0) {
        let fit = chart.atmVolIndex >= 0 ? fits[chart.atmVolIndex] : fits[fits.length - 1]
        if (delta) fit = fit[delta]
        if (fit) tss = [fit.baseVols]
        else     tss = [[]]
        title = moment(fit.time).format('YYYY/MM/DD hh:mm:ss')
        xlabel = 'sqrtVtexp'
        xfixed = 4
        yfixed = 4
      } else {
        tss = [[]]
      }

      yextractors = [d => { return d.mBasevol }]
      xextractors = [d => { return d.mSqrtvtexp }]
      dextractors = [d => { return d.mIntexpiry }]

      labels = [key]
    }

    let key = this.selectRef.current.value
    if (key === 'pnl' || key === 'delta' || key === 'theta' || key === 'vega') {
      tss = [this.listenerData.timeSeries.instrumentation[this.strategy]]
      let field = 'm' + key.charAt(0).toUpperCase() + key.slice(1)
      yextractors = [d => { return d.instrumentation[field] }]
      xextractors = [d => { return new Date(d.time).getTime() }]
      labels = [key]
    } else if (key === 'instantPnl' || key === 'adjustmentCost' ||
               key === 'alphaPremium' || key === 'volSlippage' ||
               key === 'basePriceSlippage' || key === 'forwardSlippage' ||
               key === 'alphaSlippage' || key === 'residualSlippage') {
      tss = [this.listenerData.timeSeries.slippage[this.strategy]]
      yextractors = [d => { return d.slippage[key] }]
      xextractors = [d => { return new Date(d.time).getTime() }]
      labels = [key]
    } else if (key === 'PnL with slippage') {
      tss = [this.listenerData.timeSeries.instrumentation[this.strategy]]
      yextractors = [d => { return d.instrumentation['mPnl'] }]
      xextractors = [d => { return new Date(d.time).getTime() }]
      labels = ['pnl']

      let keys = [ 'instantPnl', 'adjustmentCost', 'alphaPremium', 'volSlippage', 'basePriceSlippage'
                 , 'forwardSlippage', 'alphaSlippage', 'residualSlippage']
      keys.forEach(k => {
        tss.push(this.listenerData.timeSeries.slippage[this.strategy])
        yextractors.push(d => { return d.slippage[k] })
        xextractors.push(d => { return new Date(d.time).getTime() })
        labels.push(k)
      })
    } else if (key === 'ATM Base Vols' || key === 'Forward ATM Base Vols') {
      let fits = key === 'ATM Base Vols' ? this.listenerData.atmBaseVols : this.listenerData.forwardAtmBaseVols
      doVolChart(this, key, fits)
    } else if (key.startsWith('Vol Delta = ')) {
      let delta = Number(key.slice(12))
      let fits = this.listenerData.deltaVols
      doVolChart(this, key, fits, delta)
    } else {
      let key = this.selectRef.current.value

      tss = [this.listenerData.timeSeries.underlying[key]]
      yextractors = [d => { return d ? d.px : 0.0 }]
      xextractors = [d => { return new Date(d.time).getTime() }]
      labels = [key]

      yfixed = 2       // display cents for underlying

      let theoKey = key + '-theo'
      let theoTss = this.listenerData.timeSeries.underlying[theoKey]
      if (theoTss) {
        tss.push(theoTss)
        yextractors.push(d => { return d ? d.px : 0.0 })
        xextractors.push(d => { return new Date(d.time).getTime() })
        labels.push(theoKey)
      }
    }

    let colors = []
    labels.forEach(label => {
      let color = 'white'
      if (label in this.colors) color = this.colors[label]
      if (label.endsWith('-theo')) color = 'red'
      colors.push(color)
    })

    this.tsPlot.update(tss, xextractors, yextractors, dextractors, labels, colors, title
      ,xlabel, xfixed, yfixed)
  }

  onSelectChange = () => {
    this.atmVolIndex = -1
    this.atmVolLength = 0
    this.atmBaseVolsSync.xrange = null
    this.atmBaseVolsSync.dx = this.atmBaseVolsSync.dy = 0
    this.tsPlot.yrange = null
    this.tsPlot.setSync(this.sync)
    this.tsPlot.clearYRange()
    this.tsPlot.setXTime(true)
    this.update()
  }

  render = () => {
    let select = <select ref={this.selectRef}
                         onChange={this.onSelectChange}
                         style={{float: 'right'}}/>

    return (
      <div className="guif-div">
        <div style={{display: 'flex', alignItems: 'center'}}>

          <div style={{display: 'inline-block'}}>
            {select}
          </div>

          <div ref={this.myRef}
               style={{margin: 'auto', display: 'inline-block'}}>
          </div>

        </div>
      </div>
    )
  }
}

function genSyncer() {
  let sync =
      { comps: []
      , dx: 0
      , scale: 1
      , xrange: null

      , updateTransform: () => {
          sync.comps.forEach(comp => {
            comp.updateTransform()
          })
        }

      , mouseNotActive: () => {
          // Mouse entered a component ... so it can't be in any other component.  Components
          // can miss a mouseleave message if it comes during a redraw, but the new component
          // will get a mouseenter when the drawing is complete (this is rare, but if you move
          // the mouse between charts it'll happen sooner or later).  Symtom is tooltips in
          // more than one window and/or scale operations (t, b, f) being directed to the wrong
          // window
          sync.comps.forEach(comp => {
            comp.mouseActive = false              // mouse is out
            comp.tooltip.style('display', 'none') // don't wait for next update to turn off tooltip
          })
        }

      , register: comp => {
          sync.comps.push(comp)
        }

      , remove: comp => {
          sync.comps = sync.comps.filter(c => c !== comp)
        }
      }

  return sync
}

class ChartView extends Component {
  constructor(props) {
    super(props)
    this.dataStore = props.dataStore
    this.back = props.back
    this.sync = props.sync
  }

  render() {
    return (
      <div>
        <div className="guif-div" style={{fontSize: '14px', maxWidth: '50%'}}>
          Key commands for y-axis rescaling
          <br/> t &rarr; set top of range to current mouse position
          <br/> b &rarr; set bottom of range to current mouse position
          <br/> f &rarr; set range to full range present in the data
        </div>
        <PNLChart dataStore={this.dataStore} back={this.back} sync={this.sync} type="PnL with slippage"/>
        <PNLChart dataStore={this.dataStore} back={this.back} sync={this.sync} type="underlying"/>
        <PNLChart dataStore={this.dataStore} back={this.back} sync={this.sync} type="delta"/>
        <PNLChart dataStore={this.dataStore} back={this.back} sync={this.sync} type="vega"/>
      </div>
    )
  }
}


class ChartTabs extends Component {
  constructor(props) {
    super(props)
    this.dataStore = props.dataStore
    if (!this.dataStore.syncers) {
      this.dataStore.syncers = {}
      this.dataStore.ldList.forEach(ld => this.dataStore.syncers[ld.back] = genSyncer())
    }
  }

  render = () => {
    return (
      <div className="guif-div">
        <Tabs className="sub-tabs">
          <TabList>
            {this.dataStore.ldList.map(ld => <Tab key={ld.back}>{ld.back}</Tab>)}
          </TabList>

          {this.dataStore.ldList.map(ld => {
            let dataStore = {ldList: [ld], appWide: this.dataStore.appWide}
            return <TabPanel key={ld.back}><ChartView dataStore={dataStore} back={ld.back} sync={this.dataStore.syncers[ld.back]}/></TabPanel>
          })}
        </Tabs>
      </div>
    )
  }
}

PNLChart.propTypes =
  { dataStore: PropTypes.object
  , back: PropTypes.string
  , type: PropTypes.string
  , sync: PropTypes.object
  }

ChartView.propTypes =
  { dataStore: PropTypes.object
  , back: PropTypes.string
  , sync: PropTypes.object
  }

ChartTabs.propTypes =
  { dataStore: PropTypes.object
  }


export {PNLChart, genSyncer, ChartView, ChartTabs}
