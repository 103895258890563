import React, {Component} from 'react'
import PropTypes from 'prop-types'

import ScenarioTable from './ScenarioTable'
import SlippageTable from './SlippageTable'

import {getCookies} from './util.jsx'

import './App.css'

class Risk extends Component {
  constructor(props) {
    super(props)

    this.cookies = getCookies()
    this.dataStore = props.dataStore
    this.state = {backFilter: ''}
  }

  backFilterOnInput = event => {
    this.setState({backFilter: event.target.value})
  }

  render() {
    return (
      <div className="guif-div">
        <div style={{float: 'left', width: 'calc(100% - 50px)', display: 'flex', alignItems: 'center'}}>
          <input className="guif-input" type="text" size="20" placeholder="back"
                 onChange={this.backFilterOnInput}>
          </input>

        </div>
        <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
          <ScenarioTable dataStore={this.dataStore}
                         backFilter={this.state.backFilter}
                         messagename='scenarios'/>
        </div>

        { this.cookies.slippageTable &&
        <div style={{paddingTop: '20px', paddingBottom: '10px'}}>
          <SlippageTable dataStore={this.dataStore}
                         backFilter={this.state.backFilter}
                         messagename='slippage'/>
        </div>
        }
      </div>
    )
  }
}

Risk.propTypes =
  { dataStore: PropTypes.object
  }
export default Risk
