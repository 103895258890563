import React from 'react'
import {UpdatedTable} from './UpdatedTable'

import './App.css'

class SlippageTable extends UpdatedTable {
  constructor(props) {
    super(props)

    let expiryComp = function (valueA, valueB, nodeA, nodeB, isInverted) {
      // Always put Total last
      if (valueA === 0) valueA = 1e50 * (isInverted ? -1 : 1)
      if (valueB === 0) valueB = 1e50 * (isInverted ? -1 : 1)

      return valueA - valueB
    }

    let defaultColWidth = 100
    this.state =
      { defaultColDef: { resizable: true
                       , sortable: true
                       , cellStyle: this.right
                       , width: defaultColWidth
                       }
      , columnDefs: [ { width: defaultColWidth
                      , headerName: 'Expiry'
                      , field: 'expiry'
                      , valueFormatter: this.expiryFormatter('expiry')
                      , comparator: expiryComp
                      }
                    , { width: 80
                      , headerName: 'Back'
                      , field: 'back'
                      , valueFormatter: this.backFormatter('back')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Delta'
                      , field: 'delta'
                      , valueFormatter: this.fixedFormatter('delta', 3)
                      }
                    , { width: defaultColWidth
                      , headerName: 'Gamma'
                      , field: 'gamma'
                      , valueFormatter: this.fixedFormatter('gamma', 3)
                      }
                    , { width: defaultColWidth
                      , headerName: 'Theta'
                      , field: 'theta'
                      , valueFormatter: this.fixedFormatter('theta', 0)
                      }
                    , { width: defaultColWidth
                      , headerName: 'Vega'
                      , field: 'vega'
                      , valueFormatter: this.fixedFormatter('vega', 0)
                      }
                    , { width: defaultColWidth
                      , headerName: 'wVega'
                      , field: 'wVega'
                      , valueFormatter: this.fixedFormatter('wVega', 0)
                      }
                    , { width: defaultColWidth
                      , headerName: 'Adj Vega'
                      , field: 'adjVega'
                      , valueFormatter: this.fixedFormatter('adjVega', 0)
                      }
                    , { width: defaultColWidth
                      , headerName: 'Adj wVega'
                      , field: 'adjWVega'
                      , valueFormatter: this.fixedFormatter('adjWVega', 0)
                      }
                    , { width: defaultColWidth
                      , headerName: 'Rho'
                      , field: 'rho'
                      , valueFormatter: this.fixedFormatter('rho', 0)
                      }
                    , { width: defaultColWidth
                      , headerName: 'Forward'
                      , field: 'forward'
                      , valueFormatter: this.fixedFormatter('forward', 3)
                      }
                    , { width: defaultColWidth
                      , headerName: 'FwdBasis'
                      , field: 'forwardBasis'
                      , valueFormatter: this.fixedFormatter('forwardBasis', 3)
                      }
                    , { width: defaultColWidth
                      , headerName: 'Alpha Prem'
                      , field: 'alphaPremium'
                      , valueFormatter: this.currencyFormatter('alphaPremium')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Adjust Cost'
                      , field: 'adjustmentCost'
                      , valueFormatter: this.currencyFormatter('adjustmentCost')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Instant PnL'
                      , field: 'instantPnl'
                      , valueFormatter: this.currencyFormatter('instantPnl')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Vol Slip'
                      , field: 'volSlippage'
                      , valueFormatter: this.currencyFormatter('volSlippage')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Base Slip'
                      , field: 'basePriceSlippage'
                      , valueFormatter: this.currencyFormatter('basePriceSlippage')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Residual Slip'
                      , field: 'residualSlippage'
                      , valueFormatter: this.currencyFormatter('residualSlippage')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Forward Slip'
                      , field: 'forwardSlippage'
                      , valueFormatter: this.currencyFormatter('forwardSlippage')
                      }
                    , { width: defaultColWidth
                      , headerName: 'Alpha Slip'
                      , field: 'alphaSlippage'
                      , valueFormatter: this.currencyFormatter('alphaSlippage')
                      }
                    , { width: 160
                      , headerName: 'Last Updated'
                      , field: 'time'
                      , valueFormatter: this.timeFormatter('time')
                      }
                    ]
      }
    this.redraw()
    this.gengrid()
  }

  expiryFormatter = colname => {
    return function(params) {
      let expiry = params.data[colname]
      if (expiry !== 0) return expiry
      return 'Total'
    }
  }

  getRowId = (params) => {
    let back = params.data.back
    if (!back) back = ''
    return params.data.expiry + back
  }

  getRowStyle = (params) => {
    if (params.data.expiry === 0) {
      return {backgroundColor: '#44515e'}
    } else {
      return {backgroundColor: '#262c2e'}
    }
  }

  onGridReadyDerived = () => {
    let sortModel = [{colId: 'back', sort: 'asc'},{colId: 'expiry', sort: 'asc'}]
    this.api.applyColumnState({state: sortModel})
  }

  render() {
    if (this.api) this.api.onFilterChanged()
    let gridStyle = { width: this.totalWidth() }

    return (
      <div className="guif-div">
        <div className="ag-theme-balham-dark"
             style={gridStyle}>
          {this.grid}
        </div>
      </div>
    )
  }
}

export default SlippageTable
