import React, {Component} from 'react'
import PropTypes from 'prop-types'

class TradingMode extends Component {
  constructor(props) {
    super(props)
    this.tradingModes = props.tradingModes

    if (this.tradingModes) {
      this.tradingModeMap = {}
      this.tradingModes.forEach(mode => { this.tradingModeMap[mode.back] = mode })
    }

    this.selectValue = {}
  }

  genHealthMap = ld => {
    let health = {}
    let st = ld['service-status-table']
    if (!st) return health

    st.forEach(row => {
      /*eslint-disable*/  // eslint doesn't like non-camel case.  But this is set by our config conventions, so off it goes
      health[row.serviceID] = { gof: row.gof
                              , min_volume: row.minVolume
                              , max_volume: row.maxVolume
                              , max_gamma: row.maxGamma
                              , max_vega: row.maxVega
                              , min_breach_vega: row.minBreachVega
                              , prorata_multiplier: row.prorataMultiplier
                              , prorata_max_volume_percentage: row.prorataMaxVolumePercentage
                              }
      /*eslint-enable*/
    })

    return health
  }

  genSinglesMap = ld => {
    let singles = {}
    let st = ld['service-status-table']
    if (!st) return singles

    st.forEach(row => {
      /*eslint-disable*/  // eslint doesn't like non-camel case.  But this is set by our config conventions, so off it goes
      if (row.singles) {
        singles[row.serviceID] = { hedge_delta_threshold: row.singles.hedgeDeltaThreshold
                                 , hedge_cross_threshold: row.singles.hedgeCrossThreshold
                                 , hedge_time_threshold_seconds: row.singles.hedgeTimeThresholdSeconds
                                 , baseval_expected_width: row.singles.basevalExpectedWidth
                                 , baseval_max_level_volume: row.singles.basevalMaxLevelVolume
                                 , baseval_depth_volume: row.singles.basevalDepthVolume
                                 , max_order_delta: row.singles.maxOrderDelta
                                 , quoter_level_type: row.singles.quoterLevelType
                                 , max_levels_traded: row.singles.maxLevelsTraded
                                 , global_liquidation_max_dve_factor: row.singles.globalLiquidationMaxDveFactor
                                 }
      }
      /*eslint-enable*/
    })

    return singles
  }

  getCurrentParameters = back => {
    let ld = this.ldMap[back.back]
    if (!ld) return {}

    if (!ld.parameters) return {}
    let parameters = JSON.parse(JSON.stringify(ld.parameters))
    if (!parameters) return {}

    parameters.health = this.genHealthMap(ld)
    parameters.singles = this.genSinglesMap(ld)

    return parameters
  }

  checkCurrentParametersConsistentWithMode = (parameters, back, mode) => {
    let neq = 0
    let nneq = 0

    mode.params.forEach(route => {
      let routeParts = route.route.split('/')
      let parametersKey = routeParts[routeParts.length - 1]

      let rparams = parameters[parametersKey]
      if (rparams && (parametersKey === 'health' || parametersKey === 'singles')) {
        rparams = rparams[route.params.serviceID]
      }

      if (rparams) {
        for (let key in route.params) {
          if (key !== 'serviceID') {
            let refval = route.params[key]
            if (typeof refval === 'object') {
              if (refval.comp === 'leq') {
                if (rparams[key] <= refval.val) neq += 1
                else                            nneq += 1
              } else if (refval.comp === 'geq') {
                if (rparams[key] >= refval.val) neq += 1
                else                            nneq += 1
              } else {
                console.log('Warning:  unknown comparison function ' + refval.comp)
              }
            } else {
              if (rparams[key] === refval) neq += 1
              else                         nneq += 1
            }
          }
        }
      }
    })

    return (neq > 0) && (nneq === 0)
  }

  renderSelect = back => {
    let selectBackground = 'buttonface'

    let renderOption = mode => {
      if (mode.isConsistent) {
        let sel = document.getElementById('tradingMode' + back.back)

        if (!this.selectValue[back.back] && sel) {
          selectBackground = 'green'
          this.selectValue[back.back] = mode.name
          sel.value = mode.name

        } else if (this.selectValue[back.back] === mode.name) {
          selectBackground = 'green'
        }
        return <option key={mode.name} style={{background: 'green'}}>{mode.name}</option>
      } else {
        return <option key={mode.name} style={{background: 'buttonface'}}>{mode.name}</option>
      }
    }

    let optionMap = {}
    let options = []
    back.modes.forEach(mode => {
      optionMap[mode.name] = renderOption(mode)
      options.push(optionMap[mode.name])
    })

    let onChange = event => {
      this.selectValue[back.back] = event.target.value
      let option = optionMap[event.target.value]
      if (option.props && option.props.style) {
        event.target.style.background = optionMap[event.target.value].props.style.background
      }
    }

    return (
      <select name="mode" id={'tradingMode' + back.back} style={{width: '120px', background: selectBackground}}
              defaultValue={this.selectValue[back.back]} onChange={onChange}>
        {options}
      </select>
    )
  }

  renderModeCell = mode => {
    let cellStyle = { display: 'table-cell'
                    , width: '100px'
                    , background: mode.isConsistent ? 'green' : '#262C2E'
                    , borderTop: '1px solid #555555'
                    , borderBottom: '1px solid #555555'
                    , borderRight: '1px solid #555555'
                    , verticalAlign: 'middle'
                    }
    if (this.firstModeCell) {
      this.firstModeCell = false
      cellStyle.borderLeft = '1px solid #555555'
    }

    return (
      <div style={cellStyle} key={'modecell' + mode.name}>
        <div style={{display: 'inline-block', fontSize: '11px', verticalAlign: 'middle', paddingTop: '5px', paddingBottom: '5px', textAlign: 'center', width: '100%'}}>{mode.name}</div>
      </div>
    )
  }

  renderTradingMode = back => {
    let parameters = this.getCurrentParameters(back)
    back.modes.forEach(mode => {
      mode.isConsistent = this.checkCurrentParametersConsistentWithMode(parameters, back, mode)
    })

    let onSetClick = () => {
      let sel = document.getElementById('tradingMode' + back.back)

      let ld = this.ldMap[back.back]
      if (ld) ld.POST('/parameters/setmode', {mode: sel.value})
    }

    let ltext = back.back !== 'default' ? back.back : ''
    this.firstModeCell = true

    return (
      <div key={back.back} style={{display: 'table-row', fontSize: '12px'}}>
        <div style={{width:'30px', display: 'table-cell', verticalAlign: 'middle'}}>
          <label style={{fontSize: '16px', textAlign: 'center'}}>{ltext}</label>
        </div>
        <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
          {this.renderSelect(back)}
          <button style={{marginLeft: '15px', width: '90px'}} onClick={onSetClick}>Set {ltext}</button>
        </div>
        <div style={{display: 'table-cell', paddingLeft: '20px', verticalAlign: 'middle'}}>{back.modes.map(this.renderModeCell)}</div>

      </div>
    )
  }


}

TradingMode.propTypes =
  { tradingModes: PropTypes.object
  }

export default TradingMode
